
import { GetAxios, KeyPairNValue } from "../../../Shared";
import { config } from "../../../Environments";
import { Link, useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from "react-redux";
import { FaGooglePlay, FaPlayCircle, FaRegHandshake } from "react-icons/fa";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { FaCcMastercard, FaChevronCircleDown, FaCreditCard, FaEllipsisV } from 'react-icons/fa';
import { BsBank, BsEnvelope, BsFillCreditCardFill } from "react-icons/bs";
import { Card, ListGroup } from "react-bootstrap";
import { GoDotFill } from "react-icons/go";
import { CountrySelect } from "../../../Components";
import axios from "axios";
import { PiLockKeyFill } from "react-icons/pi";
import { setGlobalLoading, setPlan } from "../../../Store";
import { IoChevronDownCircleOutline } from "react-icons/io5";
import { setResumeForEdit, setResumeSettings, setResumeTemplate } from '../../../Store/Slices/ResumeSlice';

export const ResumePlanCheckout = () => {


    const { t: ts } = useTranslation('alert');
    const { t: ct } = useTranslation('common');
    const { t: t } = useTranslation('plan');
    const { i18n } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<ResumePlanModel>({
        planId: 1,
        packageName: "", noOfTemplates: 1, tax: 0, packagePrice: 0,
        totalPrice: 0, companyUserId: 0, email: "", companyName: "",
        paidPrice: 0, paymentMethod: "", packageDetail: [], expiryDate: "",
        purchaseDate: "", billingDetail: "", userName: "",
        cardNumberAsteriks: "", currency: "USD"
    });
    const [searchParams] = useSearchParams();
    const [selectedOption, setSelectedOption] = useState(0);
    const [billing, setBilling] = useState<any>();
   // const [paymentMethods, setPaymentMethods] = useState<any>([]);
    const [showStateZip, setShowStateZip] = useState(false);
    const [activeCardType, setActiveCardType] = useState(true);
    const [activePayPalType, setActivePayPalType] = useState(false);
    const [states, setStates] = useState<KeyPairNValue[]>([]);
    const planschema = Yup.object().shape({
        paymentType: Yup.string().required(ts('account.required') || 'Required'),
        lang: Yup.string().required(ts('account.required') || 'Required'),
        planId: Yup.number().required(ts('account.required') || 'Required'),
        cardNumber: Yup.string().when("paymentType", {
            is: "CC",
            then: Yup.string().required(ts('account.required') || 'Required'),
            otherwise: Yup.string().notRequired(),

        }),
        expiryYear: Yup.string().when("paymentType", {
            is: "CC",
            then: Yup.string().required(ts('account.required') || 'Required'),
            otherwise: Yup.string().notRequired(),

        }),
        expiryMonth: Yup.string().when("paymentType", {
            is: "CC",
            then: Yup.string().required(ts('account.required') || 'Required'),
            otherwise: Yup.string().notRequired(),

        }),
        cVV: Yup.string().when("paymentType", {
            is: "CC",
            then: Yup.string().required(ts('account.required') || 'Required'),
            otherwise: Yup.string().notRequired(),

        }),
        firstName: Yup.string().required(ts('account.required') || 'Required'),
        lastName: Yup.string().required(ts('account.required') || 'Required'),
        address1: Yup.string().required(ts('account.required') || 'Required'),
        address2: Yup.string().required(ts('account.required') || 'Required'),
        city: Yup.string().required(ts('account.required') || 'Required'),
        country: Yup.string().required(ts('account.required') || 'Required'),
        zip: Yup.string().when("country", {
            is: "US",
            then: Yup.string().required(ts('account.required') || 'Required'),
            otherwise: Yup.string().notRequired(),
        }),
        state: Yup.number().when("country", {
            is: "US",
            then: Yup.number().min(1, ts("account.required") || "Required"),
            otherwise: Yup.number().notRequired(),
        }),
    });
    const formik = useFormik({
        initialValues: {
            planId: 0,
            paymentType: "CC",
            total: 0,
            email: "",
            currency: "",
            lang: i18n.language,
            isBackupPayment: false,
            cardType: "Card",
            cardNumber: "",
            nameOnCard: "",
            expiryYear: "",
            expiryMonth: "",
            cVV: "",
            paymentId: 0,
            billingId: 0,
            firstName: "",
            lastName: "",
            address1: "",
            address2: "",
            city: "",
            state: 0,
            zip: "",
            country: "DO",
            status: "UnPaid",
            transactionId: "",
        },
        validationSchema: planschema,
        onSubmit: values => {
            console.log("on submit click");
            console.log(loading)
            if (loading) {
                toast(ts('account.pleaseWait'), { type: 'info' });

            }
            // if (values.billingId == 0 && values.paymentType != 'Cheque') {
            //     toast(ts('company.addBilling'), { type: 'error' });
            //     return;
            // }
            setLoading(true);
            GetAxios().post(config.apiBase + '/api/Resume/ChargeResumePlanPayment', values).then(res => {
                if (res.data.success) {

                    // navigate({
                    //     pathname: '/employee-account/resume-plan-confirmation',
                    //     search: '?' + new URLSearchParams({ id: res.data.data }).toString(),
                    // });
                    //params.push()
                    if (searchParams.has('r') && searchParams.has('t')) {
                        //Set template of that resume 
                        GetAxios(navigate, dispatch).patch(config.apiBase + '/api/Resume/SetTemplate', undefined, {
                            params: {
                                id: Number(searchParams.get('r')),
                                templateId: searchParams.get('t')
                            }
                        }).then(s => {
                            dispatch(setResumeTemplate(searchParams.get('t') || ""));
                            dispatch(setPlan(true));
                            navigate('/resumes/builder/'+Number(searchParams.get('r'))+"/editor/access-information",{ replace: true });
                        });
                    }
                    toast(ts('resume.planSuccess'), { type: 'success' });
                } else {
                    console.warn(res);
                    toast(res.data.message, { type: 'error' });
                }
                setLoading(false);
            }).catch(err => {
                setLoading(false);
                console.error(err);
                toast(ts('error.wentWrong'), { type: 'error' });

            });
        },
    });


    const handleCountryChange = (code: any) => {
        formik.setFieldValue('country', code);
        // Check if selected country is United States (code 'US')
        if (code === 'US') {
            setShowStateZip(true);
        } else {
            setShowStateZip(false);
        }
    };

    useEffect(() => {
        axios.get(config.apiBase + "/api/General/Info").then((res) => {
            if (!formik.values.country) {
                // formik.setFieldValue("country", res?.data?.country || "DO");
                formik.setFieldValue("country", "DO");
            }
        });
      
    }, []);
    const setBillingAddress = () => {
        GetAxios().get(config.apiBase + '/api/Payment/GetDefaultBillingDetail').then(res => {
            if (res.data.success==true && res.data.data!=null) {
                setBilling(res.data.data)
                formik.setFieldValue("billingId", res.data.data.id)
                formik.setFieldValue("firstName", res.data.data.firstName);
                formik.setFieldValue("lastName", res.data.data.lastName);
                formik.setFieldValue("address1", res.data.data.address1);
                formik.setFieldValue("address2", res.data.data.address2);
                formik.setFieldValue("city", res.data.data.city);
                formik.setFieldValue("country", res.data.data.country);
                dispatch(setGlobalLoading(false));
            }
        });
    }
    const handlePaymentMethodChange = (e: any) => {
        setSelectedOption(e.target.value);
        console.log("PaymentId" + e.target.value)
        formik.setFieldValue("paymentId", e.target.value)
        formik.setFieldValue("paymentType", "Card");
    }

    const handlePaymentTypeChange = (e: any) => {
        setSelectedOption(0);
        console.log("paymentType" + e.target.value)
        formik.setFieldValue("paymentType", e.target.value)
        formik.setFieldValue("paymentId", 0);
    }
    const handleCardTypeChange = (e: any) => {
        setActiveCardType(true);
        setActivePayPalType(false);
        setSelectedOption(0);
        console.log("paymentType" + "CC")
        formik.setFieldValue("paymentType", "CC");
        formik.setFieldValue("paymentId", 0);
    }
    const handleChequeTypeChange = (e: any) => {
        setActiveCardType(false);
        setActivePayPalType(true);
        setSelectedOption(0);
        console.log("paymentType" + "PayPal")
        formik.setFieldValue("paymentType", "PayPal");
        formik.setFieldValue("paymentId", 0);
    }

    const loadData = () => {
        if (searchParams.has('rId')) {
            formik.setFieldValue("planId", Number(searchParams.get('rId')));
            dispatch(setGlobalLoading(true));
            GetAxios().get(config.apiBase + '/api/Resume/GetResumePlan?planId=' + searchParams.get('rId') + '&lang=' + i18n.language).then(res => {
                if (res.data.success) {

                    setData(res.data.data)
                    dispatch(setGlobalLoading(false));
                }
                else {
                    toast(res.data.message, { type: 'error' });
                    dispatch(setGlobalLoading(false));
                    navigate("/");
                }
            });
        }
        dispatch(setGlobalLoading(true));
        setBillingAddress();
        // GetAxios().get(config.apiBase + '/api/Payment/GetPaymentMethods').then(res => {
        //     if (res.data.success) {
        //         setPaymentMethods(res.data.data)

        //         dispatch(setGlobalLoading(false));
        //     }
        // });
    }
    // const convertPrice = async () => {
    //     // fetch the selected from currency rates
    //     const response = await fetch(
    //       `https://api.exchangeratesapi.io/latest?base=USD`
    //     ).then((response) => response.json());
    //     const CurrencyRate = response.data.rates["DOP"];
    //     setData({...data, packagePrice: data.packagePrice * CurrencyRate});
    //     setData({...data, totalPrice: data.totalPrice * CurrencyRate});
    //   };
    React.useMemo(() => {

        loadData();

    }, [i18n.language]);

    const [currency, setCurrency] = useState<string>("USD");
    const [exchangeRate, setExchangeRate] = useState<number>(1);
    React.useEffect(() => {
        //get user currency if is not usd then find exchange rate
        GetAxios().get(config.apiBase + '/api/General/GetCurrency')
            .then(res => {
                setCurrency(res.data.data);
                if (res.data.data != "USD") {
                    GetAxios().get(config.apiBase + '/api/General/GetExchangeRate?baseCurrency=USD&targetCurrency=DOP')
                        .then(res => {
                            if (res.data.success) {
                                setExchangeRate(res.data.data);
                            }
                        })
                }
            })
    }, []);
    const FormatCurrency = (price: number, currency: string) => {
        let neg = false;
        if (price < 0) {
            neg = true;
            price = Math.abs(price);
        }
        return currency + price.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString();
    }
    useEffect(() => {
        GetAxios()
            .get(config.apiBase + "/api/General/GetListBySystemType?lang=" + i18n.language + "&type=USStates")
            .then((res) => {
                setStates(res.data);
            });

    }, []);
    return (
        <>
            <div className='container py-4'>
                <div className='card p-3'>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <div>
                                <h2 className='fw-700 mb-2'> {t('common.enterPaymentDetail')} </h2>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex align-items-center justify-content-end gap-1">
                                <PiLockKeyFill size={50} />
                                <div>
                                    <p className="fw-600 text-center">{t('common.securePayment')}</p>
                                    <p className="fw-500 bg-dark p-2  text-white rounded-3">{t('common.poweredBy')} <span className="fw-800">stripe</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6 mb-2'>
                            <div className='billing shadow border border-1 p-4 mb-3 rounded-3'>
                                <div>
                                    <p className="fs-20 fw-700 mb-3">  {formik.values.paymentType == "CC" ? t("common.paywithCard") : t("common.payByCheque")} </p>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <div className='form-float'>
                                            <div className="form-floating fw-500 cursor-pointer" onClick={handleCardTypeChange}>
                                                <div className={`rounded-6 border border-2 ${activeCardType ? 'active bg-info-subtle' : 'bg-body-tertiary'}`}>
                                                    <img src="/images/content/credit-card-logo.webp" alt="stars-list-image" className="img-fluid ps-2" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='form-float'>
                                            <div className='form-floating fw-500 cursor-pointer' onClick={handleChequeTypeChange}>
                                                <div className={`rounded-6 border border-2 ${activePayPalType ? 'active bg-info-subtle' : 'bg-body-tertiary'}`}>
                                                    <img src="/images/content/paypal-logo.webp" alt="stars-list-image" className="img-fluid" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {formik.values.paymentType == "CC" || formik.values.paymentType == "PayPal" || selectedOption != 0 ?
                                        <>
                                            <div className="col-md-12 mb-3">
                                                <div className="input-group">
                                                    <input type="text" className="form-control key-weight" autoComplete="cc-number" name='cardNumber' onChange={(e) => {
                                                        const reg = /^[0-9\b]+$/;
                                                        if (e.target.value === '' || reg.test(e.target.value)) {
                                                            formik.setFieldValue("cardNumber", e.target.value);
                                                        }
                                                    }} maxLength={16} value={formik.values.cardNumber} placeholder="1234 1234 1234 1234" aria-describedby="cardNumber" />
                                                    <span className="input-group-text group-round w-45">
                                                        <img src="/images/content/card-number-icons.webp" alt="card-number-img" className="img-fluid" />
                                                    </span>
                                                </div>
                                                {formik.errors.cardNumber && formik.touched.cardNumber ? <small className='text-danger'>{formik.errors.cardNumber}</small> : ""}

                                            </div>
                                            <div className="col-md-4 mb-3 pe-lg-0 pe-md-0">
                                                <div className='line-height-sta'>
                                                    <label className="form-label key-weight" htmlFor="expiryYear"> {t('common.month')}: </label>
                                                </div>
                                                <select className='form-select' name="expiryMonth" autoComplete="cc-exp-month" are-label="ExpiryMonth" onChange={formik.handleChange} value={formik.values.expiryMonth}>
                                                    <option key={'month+___' + 1} value=""> {t('common.select')} </option>
                                                    <option key={'month+___' + 2} value="01">01</option>
                                                    <option key={'month+___' + 3} value="02">02</option>
                                                    <option key={'month+___' + 4} value="03">03</option>
                                                    <option key={'month+___' + 5} value="04">04</option>
                                                    <option key={'month+___' + 6} value="05">05</option>
                                                    <option key={'month+___' + 7} value="06">06</option>
                                                    <option key={'month+___' + 8} value="07">07</option>
                                                    <option key={'month+___' + 9} value="08">08</option>
                                                    <option key={'month+___' + 10} value="09">09</option>
                                                    <option key={'month+___' + 11} value="10">10</option>
                                                    <option key={'month+___' + 12} value="11">11</option>
                                                    <option key={'month+___' + 13} value="12">12</option>
                                                </select>
                                                {formik.errors.expiryMonth && formik.touched.expiryMonth ? <small className='text-danger'>{formik.errors.expiryMonth}</small> : ""}
                                            </div>
                                            <div className="col-md-4 mb-3 pe-lg-0 pe-md-0">
                                                <div className='line-height-sta'>
                                                    <label className="form-label key-weight" htmlFor="expiryYear">  {t('common.expiryYear')}: </label>
                                                </div>
                                                <select className='form-select' name="expiryYear" autoComplete="cc-exp-year" onChange={formik.handleChange} are-label="ExpiryYear" value={formik.values.expiryYear} >
                                                    <option key={'years+___' + 0} value=""> {t('common.select')}  </option>
                                                   
                                                    <option key={'years+___' + 2} value="2024">2024</option>
                                                    <option key={'years+___' + 3} value="2025">2025</option>
                                                    <option key={'years+___' + 4} value="2026">2026</option>
                                                    <option key={'years+___' + 5} value="2027">2027</option>
                                                    <option key={'years+___' + 6} value="2028">2028</option>
                                                    <option key={'years+___' + 7} value="2029">2029</option>
                                                    <option key={'years+___' + 8} value="2030">2030</option>
                                                    <option key={'years+___' + 9} value="2031">2031</option>
                                                    <option key={'years+___' + 10} value="2032">2032</option>
                                                    <option key={'years+___' + 11} value="2033">2033</option>
                                                </select>
                                                {formik.errors.expiryYear && formik.touched.expiryYear ? <small className='text-danger'>{formik.errors.expiryYear}</small> : ""}
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <div className='line-height-sta'>
                                                    <label className="form-label key-weight" htmlFor="cvv">  {t('common.cvv')} </label>
                                                </div>
                                                <div className="input-group rounded-6">
                                                    <input type="text" autoComplete="cc-csc" onChange={(e) => {
                                                        const reg = /^[0-9\b]+$/;
                                                        if (e.target.value === '' || reg.test(e.target.value)) {
                                                            formik.setFieldValue("cVV", e.target.value);
                                                        }
                                                    }}
                                                        maxLength={3} value={formik.values.cVV} className="form-control key-weight" name='cVV' placeholder="CVV" aria-describedby="cVV" />
                                                    <span className="input-group-text group-round"> <FaCreditCard /></span>
                                                </div>
                                                {formik.errors.cVV && formik.touched.cVV ? <small className='text-danger'>{formik.errors.cVV}</small> : ""}
                                            </div>
                                        </> : null
                                    }
                                    {/* {formik.values.paymentType != "" && formik.values.paymentType == "Cheque" ?
                                        <>
                                            <div className="col-md-12 mb-3">
                                                <p className='fw-500 text-gray mb-0'>
                                                    {t('common.bankNote')}
                                                </p>
                                            </div>
                                        </>
                                        : null} */}
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-12 mb-2">
                                        <div>
                                            <p className="fs-20 fw-700 mb-3"> {t('common.billingAddress')} </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <div className="line-height-sta">
                                            <label className="form-label key-weight" htmlFor="fullName">  {t('common.fullname')}</label>
                                        </div>
                                        <input type="text" name="firstName" placeholder={t('common.firstLast').toString()}
                                            className="form-control key-weight rounded-6" value={formik.values.firstName} onChange={formik.handleChange} />
                                    </div>
                                    <div className="col-md-6 align-content-end mb-2">
                                        <div>
                                            <input type="text" name="lastName" placeholder={t('common.firstLast').toString()}
                                                className="form-control key-weight rounded-6" value={formik.values.lastName} onChange={formik.handleChange} />
                                        </div>
                                    </div>

                                    <div className="col-md-12 col-12 mb-4">
                                        <label className="form-label key-weight" htmlFor="country">{t('common.countryRegion')}</label>
                                        <CountrySelect
                                            value={formik.values.country}
                                            onChange={handleCountryChange}
                                        />
                                        {formik.errors.country && formik.touched.country && (
                                            <small className="text-danger d-block">
                                                {formik.errors.country}
                                            </small>
                                        )}
                                    </div>
                                    <div className="col-md-12 col-12 mb-4">
                                        <div className="line-height-sta">
                                            {/* <label className="form-label key-weight" htmlFor="streetAddress"> {t('common.addressLine1')}</label> */}
                                            <input type="text" name="address1" value={formik.values.address1} onChange={formik.handleChange} placeholder={t('common.streetAddress').toString()} className="form-control key-weight rounded-6" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <div className="line-height-sta">
                                            {/* <label className="form-label key-weight" htmlFor="streetAddress"> {t('common.addressLine2')}</label> */}
                                            <input type="text" name="address2" value={formik.values.address2} onChange={formik.handleChange} placeholder={t('common.streetAddress').toString()} className="form-control key-weight rounded-6" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <div className="line-height-sta">
                                            {/* <label className="form-label key-weight" htmlFor="streetAddress2"> {t('common.city')}</label> */}
                                            <input type="text" name="city" value={formik.values.city} onChange={formik.handleChange} placeholder={t('common.city').toString()} className="form-control key-weight rounded-6" />
                                        </div>
                                    </div>
                                    {showStateZip && (
                                        <>
                                            <div className='col-md-6 mb-2'>
                                                <div className='line-height-sta'>
                                                    <label className="form-label key-weight" htmlFor="nameCard"> {ct('state')} </label>
                                                </div>
                                                <select
                                                  name="state"
                                                    onChange={(e) =>
                                                        formik.setFieldValue(
                                                            "state",
                                                            Number(e.target.value)
                                                        )
                                                    }
                                                    value={formik.values.state}
                                                    className="form-select form-control "
                                                >
                                                    <option value="0">{ct("selectState")}</option>
                                                    {states.map((v, i) => (
                                                        <option key={"%__" + i} value={v.key}>
                                                            {v.value}
                                                        </option>
                                                    ))}
                                                </select>
                                                {/* <input type="text" name="state" onChange={formik.handleChange} value={formik.values.state} className="form-control key-weight rounded-6" /> */}
                                                {formik.errors.state && formik.touched.state ? <small className='text-danger'>{formik.errors.state}</small> : ""}
                                            </div>
                                            <div className='col-md-6 mb-2'>
                                                <div className='line-height-sta'>
                                                    <label className="form-label key-weight" htmlFor="cardNumber"> {ct('zipCode')} </label>
                                                </div>
                                                <input type="text" name="zip" onChange={formik.handleChange} value={formik.values.zip} className="form-control key-weight rounded-6" />
                                                {formik.errors.zip && formik.touched.zip ? <small className='text-danger'>{formik.errors.zip}</small> : ""}
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className="d-grid gap-2 mt-4">
                                    <button disabled={loading} type="submit" onClick={formik.handleSubmit as any
                                    } className="border-0 rounded-3 fs-18 p-btn btn-contine text-white fw-600">
                                        {t('common.getMyResume')}
                                    </button>

                                </div>
                                <div className='mt-3 mb-3 px-4'>
                                    <p className='fw-500 text-gray mb-0 fs-14'>
                                        {t('common.byClicking')} "{t('common.getMyResume')}," {t('common.youacceptour')} <Link to="/terms-of-use">{t('common.termsofuse')}</Link> {t('common.and')}
                                        <Link to={"/privacy-policy"}> {t('common.privacy')}</Link>, {t('common.cardforPayment')}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="shadow">
                                <Card className="border-bottom-0">
                                    <ListGroup variant="flush" className="list-group-border-none p-3">
                                        <ListGroup.Item>
                                            <div>
                                                <p className="form-check-label plan-label-radio">
                                                    {t('common.reviewYourOrder')}
                                                </p>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-start gap-2 fs-18 fw-700">
                                                <p>Plan:</p>
                                                <p>{data.packageName}</p>
                                            </div>
                                        </ListGroup.Item>
                                        <div className="rounded-2">
                                            {(data.packageDetail || []).map((item: string, index: number) => (
                                                <ListGroup.Item className="mb-2" key={index}>
                                                    <div className="d-flex align-items-center gap-3">
                                                        <GoDotFill size={14} className="text-black-50" />
                                                        <div>
                                                            <p className="mb-0 fw-600 text-body-secondary lh-sm">
                                                                {item}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </ListGroup.Item>
                                            ))}

                                            {/* <ListGroup.Item className="mb-2">
                                                <div className="d-flex align-items-center gap-3">
                                                    <GoDotFill size={14} className="text-black-50" />
                                                    <div>
                                                        <p className="mb-0 fw-600 text-body-secondary">
                                                            45 days full access to all features
                                                        </p>
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="mb-2">
                                                <div className="d-flex align-items-center gap-3">
                                                    <GoDotFill size={14} className="text-black-50" />
                                                    <div>
                                                        <p className="mb-0 fw-600 text-body-secondary">
                                                            Return and edit at anytime
                                                        </p>
                                                    </div>
                                                </div>
                                            </ListGroup.Item> */}
                                        </div>
                                    </ListGroup>
                                </Card>
                                <div className='py-4 px-5 bg-light-blue'>
                                    <div className='detail-order'>
                                        <div className='d-flex justify-content-between align-items-center mb-2 fs-18'>
                                            <div>
                                                <p className='fw-500 mb-0'>  {t('common.subTotal')} </p>
                                            </div>
                                            <div>
                                                <p className='fw-500 mb-0'> {currency == "USD" ? FormatCurrency(data?.packagePrice, "$") : FormatCurrency(data?.packagePrice * exchangeRate, "RD$")} </p>
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center fs-18'>
                                            <div>
                                                <p className='fw-500 mb-0'> {t('common.saleTax')}  10%  </p>
                                            </div>
                                            <div>
                                                <p className='fw-500 mb-0'> {currency == "USD" ? FormatCurrency(data?.tax, "$") : FormatCurrency(data?.tax * exchangeRate, "RD$")} </p>
                                            </div>
                                        </div>
                                        <div className='border-1 border-black my-2'></div>
                                        <div className='d-flex justify-content-between align-items-center fs-22'>
                                            <div>
                                                <p className='mb-0 fw-700'> Total({data?.currency}) </p>
                                            </div>
                                            <div>
                                                <p className='mb-0 fw-700'> {currency == "USD" ? FormatCurrency(data?.totalPrice, "$") : FormatCurrency(data?.totalPrice * exchangeRate, "RD$")} </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-4 my-2">
                                <div className="text-center">
                                    <p className='align-items-center d-flex fs-16 fw-700 justify-content-center mb-2'>
                                        <IoChevronDownCircleOutline size={24} /> {t('common.satifyGuanteed')}
                                    </p>
                                    <p className="fw-500"> {t('common.unhappyDesc')} </p>
                                </div>
                            </div>
                            <div className="p-2 bg-light-gray rounded-4 mb-4">
                                <p className='align-items-center d-flex justify-content-center flex-wrap'>
                                    <span className="fw-700 fs-22"> {t('common.excellent')} </span>
                                    <img src="/images/content/excellent-stars.webp" alt="stars-list-image" className="img-fluid mx-2" />
                                    <span className="fw-600 fs-20">Trustpilot</span>
                                </p>
                            </div>
                            <div className="p-2 bg-light-gray rounded-4 m-2">
                                <div className='align-items-center d-flex justify-content-center gap-2'>
                                    <img src="/images/content/stripe_icon.png" alt="stripe-icon" className="img-fluid mx-2" />
                                    <span className="fw-700 fs-16"> {t('common.safesecure')} Stripe</span>
                                    <PiLockKeyFill size={28} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


