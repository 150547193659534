import React, { useState } from 'react';
import moment from 'moment';
import { DefaultProfileImage, SelectableSectionClicked } from '../SharedData';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IoDiamondOutline } from 'react-icons/io5';
import { FaPhoneAlt } from 'react-icons/fa';
import { MdOutlineAlternateEmail } from 'react-icons/md';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { ImLocation2 } from 'react-icons/im';
import { FaLink } from 'react-icons/fa';
import { TfiLocationPin } from 'react-icons/tfi';
import { LiaAwardSolid } from 'react-icons/lia';
import { VscGlobe } from 'react-icons/vsc';
import { BiSolidCity } from 'react-icons/bi';
import { GoHomeFill } from 'react-icons/go';

export const Monochrome = (props: TemplateComponentProps) => {
   const { t } = useTranslation('resume')

   const {
      navigate,
      contactInformation,
      image,
      imageData,
      objectives,
      experiences,
      educations,
      languages,
      skills,
      achievements,
      certificates,
      customSections,
      honours,
      projects,
      publications,
      references,
      additionalSettings,
      stepsInformation,
      onNavigatedToSection,
   } = props

   return (
      <>
         <WrapperDiv additionalSettings={additionalSettings}>
            <div className='template'>
               <div className='preview-template'>
                  <div id='template-full' className='shadow-lg'>
                     <div className='template-main'>
                        <div className='temp-doublecolumn-header'>
                           <section className='name-sec'>
                              {(contactInformation.firstName || contactInformation.lastName || contactInformation.occupation) && (
                                 <div className='template-prague-name text-uppercase text-start'>
                                    <div
                                       className='selectable-section'
                                       onClick={(_) => SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)}
                                    >
                                       <h1 className='fw-700 word-wrap fs-22 mb-1'>
                                          <span>{contactInformation.firstName}</span> <span>{contactInformation.lastName}</span>
                                       </h1>
                                       <p className='fw-700 fs-15 text-monochrome'>{contactInformation.occupation}</p>
                                    </div>
                                 </div>
                              )}

                              {Object.values(contactInformation).filter((s) => s).length > 0 && (
                                 <div
                                    className='contact-info-sec selectable-section'
                                    onClick={(_) => SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)}
                                 >
                                    <div className='d-flex flex-wrap align-items-center justify-content-start gap-3 mt-1'>
                                       <div className='d-flex align-items-center'>
                                          <FaPhoneAlt className='me-2 text-monochrome' size={15} />
                                          <p className='fw-600 fs-14'>{contactInformation.phone}</p>
                                       </div>
                                       <div className='d-flex align-items-center'>
                                          <MdOutlineAlternateEmail className='me-2 text-monochrome' size={15} />
                                          <p className='fw-600 fs-14'>{contactInformation.email}</p>
                                       </div>
                                       <div className='d-flex align-items-center'>
                                          <FaRegCalendarAlt className='me-2 text-monochrome' size={15} />
                                          <p className='fw-600 fs-14'>{moment(contactInformation.dob).format('MM/DD/YYYY')}</p>
                                       </div>
                                       <div className='d-flex align-items-center'>
                                          <VscGlobe className='me-2 text-monochrome' size={15} />
                                          <p className='fw-600 fs-14'>{contactInformation.nationality}</p>
                                       </div>
                                       <div className='d-flex align-items-center'>
                                          <FaLink className='me-2 text-monochrome' size={15} />
                                          <p className='fw-600 fs-14'>{contactInformation.linkedInLink}</p>
                                       </div>
                                       <div className='d-flex align-items-center'>
                                          <GoHomeFill className='me-2 text-monochrome' size={15} />
                                          <p className='fw-600 fs-14 text-nowrap'>{contactInformation.address}</p>
                                       </div>
                                       <div className='d-flex align-items-center'>
                                          <ImLocation2 className='me-2 text-monochrome' size={15} />
                                          <p className='fw-600 fs-14 text-nowrap'>{contactInformation.address2}</p>
                                       </div>
                                       <div className='d-flex align-items-center'>
                                          <BiSolidCity className='me-2 text-monochrome' size={15} />
                                          <p className='fw-600 fs-14 text-nowrap'>{contactInformation.city}</p>
                                       </div>
                                    </div>
                                 </div>
                              )}
                           </section>
                        </div>
                        <div className='creative-innersec pt-0'>
                           <div className='row'>
                              <div className='col-12'>
                                 {stepsInformation.findIndex((x) => x.title == 'Objective') > -1 && objectives && (
                                    <section className='objective-sec'>
                                       <div className='mt-0'>
                                          <p className='text-uppercase monochrome-title fw-700 mb-16'>{t('Objective')}</p>
                                          <div
                                             className='selectable-section'
                                             onClick={(_) => SelectableSectionClicked('objective', navigate, onNavigatedToSection)}
                                          >
                                             <p
                                                className='sec-value py-2 editor-list-margin-left creative-border-bottom'
                                                dangerouslySetInnerHTML={{ __html: objectives }}
                                             ></p>
                                          </div>
                                       </div>
                                    </section>
                                 )}

                                 {stepsInformation.findIndex((x) => x.title == 'Experiences') > -1 && experiences.length > 0 ? (
                                    <section className='experience-sec mt-2'>
                                       <p className='text-uppercase monochrome-title fw-700 mb-16'>{t('Experiences')}</p>
                                       <div
                                          className='selectable-section'
                                          onClick={(_) => SelectableSectionClicked('work-experience', navigate, onNavigatedToSection)}
                                       >
                                          <div className='row'>
                                             <div className='col-12'>
                                                {experiences.map((e, i) => (
                                                   <div key={'exp__' + i} className='exper-card creative-border-bottom mb-2'>
                                                      {e.company && <p className='fs-14'>{e.company}</p>}
                                                      <p className='fw-700 text-monochrome fs-16'>{e.title}</p>
                                                      <div className='d-flex gap-4'>
                                                         <div className='d-flex align-items-center fs-14'>
                                                            <FaRegCalendarAlt className='me-2' size={15} />
                                                            <p className=''>
                                                               {e.startDate || e.endDate ? (
                                                                  <>
                                                                     {e.startDate ? (
                                                                        <span>
                                                                           {new Date(e.startDate).toLocaleString('default', {
                                                                              month: 'short',
                                                                           })}{' '}
                                                                           {new Date(e.startDate).getFullYear()}
                                                                        </span>
                                                                     ) : (
                                                                        <></>
                                                                     )}
                                                                     {e.endDate && !e.currentlyWorking ? (
                                                                        <span className='barline'>
                                                                           {new Date(e.endDate).toLocaleString('default', {
                                                                              month: 'short',
                                                                           })}{' '}
                                                                           {new Date(e.endDate).getFullYear()}
                                                                        </span>
                                                                     ) : (
                                                                        <></>
                                                                     )}

                                                                     {e.currentlyWorking ? (
                                                                        <em className='barline'>{t('present')} </em>
                                                                     ) : (
                                                                        <></>
                                                                     )}
                                                                  </>
                                                               ) : (
                                                                  <></>
                                                               )}
                                                            </p>
                                                         </div>
                                                         <div className='d-flex align-items-center fs-14'>
                                                            <TfiLocationPin className='me-2' size={15} />
                                                            {e.location && <p>{e.location}</p>}
                                                         </div>
                                                      </div>
                                                      <div className=''>
                                                         {e.description && (
                                                            <p
                                                               className='sec-value py-2 editor-list-margin-left'
                                                               dangerouslySetInnerHTML={{ __html: e.description }}
                                                            ></p>
                                                         )}
                                                      </div>
                                                   </div>
                                                ))}
                                             </div>
                                          </div>
                                       </div>
                                    </section>
                                 ) : (
                                    <></>
                                 )}

                                 {stepsInformation.findIndex((x) => x.title == 'Education') > -1 && educations.length > 0 ? (
                                    <section className='education-sec mt-2'>
                                       <p className='text-uppercase monochrome-title fw-700 mb-16'>{t('Education')}</p>
                                       <div
                                          className='selectable-section'
                                          onClick={(_) => SelectableSectionClicked('education', navigate, onNavigatedToSection)}
                                       >
                                          <div className='row'>
                                             <div className='col-12'>
                                                {educations.map((e, i) => (
                                                   <div key={'edu__' + i} className='exper-card creative-border-bottom mb-2'>
                                                      {e.institute && <p className='fs-14'>{e.institute}</p>}
                                                      <p className='fw-700 text-monochrome fs-16'>{e.degree}</p>
                                                      <div className='d-flex gap-4'>
                                                         <div className='d-flex align-items-center fs-14'>
                                                            <FaRegCalendarAlt className='me-2' size={15} />
                                                            <p className=''>
                                                               {e.startDate || e.completeDate ? (
                                                                  <>
                                                                     {e.startDate ? (
                                                                        <span>
                                                                           {new Date(e.startDate).toLocaleString('default', {
                                                                              month: 'short',
                                                                           })}{' '}
                                                                           {new Date(e.startDate).getFullYear()}
                                                                        </span>
                                                                     ) : (
                                                                        <></>
                                                                     )}
                                                                     {e.completeDate && !e.currentlyStudying ? (
                                                                        <span className='barline'>
                                                                           {new Date(e.completeDate).toLocaleString('default', {
                                                                              month: 'short',
                                                                           })}{' '}
                                                                           {new Date(e.completeDate).getFullYear()}
                                                                        </span>
                                                                     ) : (
                                                                        <></>
                                                                     )}

                                                                     {e.currentlyStudying ? (
                                                                        <em className='barline'>{t('present')} </em>
                                                                     ) : (
                                                                        <></>
                                                                     )}
                                                                  </>
                                                               ) : (
                                                                  <></>
                                                               )}
                                                            </p>
                                                         </div>
                                                         <div className='d-flex align-items-center fs-14'>
                                                            <TfiLocationPin className='me-2' size={15} />
                                                            {e.location && <p>{e.location}</p>}
                                                         </div>
                                                      </div>
                                                      <div className=''>
                                                         {e.description && (
                                                            <p
                                                               className='sec-value py-2 editor-list-margin-left'
                                                               dangerouslySetInnerHTML={{ __html: e.description }}
                                                            ></p>
                                                         )}
                                                      </div>
                                                   </div>
                                                ))}
                                             </div>
                                          </div>
                                       </div>
                                    </section>
                                 ) : (
                                    <></>
                                 )}

                                 {stepsInformation.findIndex((x) => x.title == 'Skills') > -1 && (
                                    <section className='skills-sec'>
                                       <div className='mt-2'>
                                          <p className='text-uppercase monochrome-title fw-700 mb-16'>{t('Skills')}</p>
                                          <div
                                             className='lh-base selectable-section'
                                             onClick={(_) => SelectableSectionClicked('skills', navigate, onNavigatedToSection)}
                                          >
                                             <ul className='list-unstyled d-flex align-items-center flex-wrap fs-13 creative-border-bottom pt-2 pb-3'>
                                                {skills.length > 0 &&
                                                   skills.map((s, i) => (
                                                      <li
                                                         key={'skill_' + s.name + s.id + i}
                                                         className='pb-1 me-4 border-bottom border-1 border-dark px-2 fw-600'
                                                      >
                                                         {s.name}
                                                      </li>
                                                   ))}
                                             </ul>
                                          </div>
                                       </div>
                                    </section>
                                 )}

                                 {stepsInformation.findIndex((x) => x.title == 'Languages') > -1 && (
                                    <section className='languages-sec'>
                                       <div className='mt-2'>
                                          <p className='text-uppercase monochrome-title fw-700 mb-16'>{t('Languages')}</p>
                                          <div
                                             className='selectable-section'
                                             onClick={(_) => SelectableSectionClicked('languages', navigate, onNavigatedToSection)}
                                          >
                                             <div className='d-grid display-grid-custom custom'>
                                                {languages.length > 0 &&
                                                   languages.map((s, i) => (
                                                      <div
                                                         key={'languages_' + s.name + s.id + i}
                                                         className='lang-card fs-12 creative-border-bottom py-2'
                                                      >
                                                         {s.name && <p className='fw-600 fs-14 text-monochrome'>{s.name}</p>}
                                                         <p className='mb-1'>
                                                            {s.level && <span>{s.level}</span>}{' '}
                                                            {s.course && <span className='barline'>{s.course}</span>}
                                                         </p>
                                                      </div>
                                                   ))}
                                             </div>
                                          </div>
                                       </div>
                                    </section>
                                 )}

                                 {stepsInformation.findIndex((x) => x.title == 'Projects') > -1 && projects.length > 0 && (
                                    <section className='Project-sec'>
                                       <div className='mt-2'>
                                          <p className='text-uppercase monochrome-title fw-700 mb-16'>{t('Projects')}</p>
                                          <div
                                             className='selectable-section'
                                             onClick={(_) => SelectableSectionClicked('projects', navigate, onNavigatedToSection)}
                                          >
                                             {projects.map((v, i) => (
                                                <p
                                                   className='sec-value py-2 editor-list-margin-left creative-border-bottom'
                                                   key={'projects__' + i}
                                                   dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                                ></p>
                                             ))}
                                          </div>
                                       </div>
                                    </section>
                                 )}

                                 {stepsInformation.findIndex((x) => x.title == 'Publications') > -1 && publications.length > 0 && (
                                    <section className='Publication-sec'>
                                       <div className='mt-2'>
                                          <p className='text-uppercase monochrome-title fw-700 mb-16'>{t('Publications')}</p>
                                          <div
                                             className='selectable-section'
                                             onClick={(_) => SelectableSectionClicked('publications', navigate, onNavigatedToSection)}
                                          >
                                             {publications.map((v, i) => (
                                                <p
                                                   className='sec-value py-2 editor-list-margin-left creative-border-bottom'
                                                   key={'publication__' + i}
                                                   dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                                ></p>
                                             ))}
                                          </div>
                                       </div>
                                    </section>
                                 )}

                                 {stepsInformation.findIndex((x) => x.title == 'Achievements') > -1 && achievements.length > 0 && (
                                    <section className='Achievements-sec'>
                                       <div className='mt-2'>
                                          <p className='text-uppercase monochrome-title fw-700 mb-16'>{t('Achievements')}</p>
                                          <div className='row'>
                                             <div className='col-12'>
                                                <div
                                                   className='selectable-section'
                                                   onClick={(_) => SelectableSectionClicked('achievements', navigate, onNavigatedToSection)}
                                                >
                                                   {achievements.map((v, i) => (
                                                      <div
                                                         key={'achievements__' + i}
                                                         className='d-flex align-items-start justify-content-start py-2 creative-border-bottom'
                                                      >
                                                         <span>
                                                            <IoDiamondOutline className='text-monochrome me-2' size={22} />
                                                         </span>
                                                         <div className='detail-achivements'>
                                                            <p
                                                               className='sec-value editor-list-margin-left'
                                                               dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                                            ></p>
                                                         </div>
                                                      </div>
                                                   ))}
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </section>
                                 )}

                                 {stepsInformation.findIndex((x) => x.title == 'Certifications & Courses') > -1 &&
                                    certificates.length > 0 && (
                                       <section className='Certification-sec'>
                                          <div className='mt-2'>
                                             <p className='text-uppercase monochrome-title fw-700 mb-16'>{t('Certifications & Courses')}</p>
                                             <div
                                                className='selectable-section'
                                                onClick={(_) =>
                                                   SelectableSectionClicked('certifications-courses', navigate, onNavigatedToSection)
                                                }
                                             >
                                                {certificates.map((v, i) => (
                                                   <p
                                                      className='sec-value py-2 editor-list-margin-left creative-border-bottom'
                                                      key={'certificates__' + i}
                                                      dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                                   ></p>
                                                ))}
                                             </div>
                                          </div>
                                       </section>
                                    )}

                                 {stepsInformation.findIndex((x) => x.title == 'Honors & Awards') > -1 && honours.length > 0 && (
                                    <section className='Honors-Award-sec'>
                                       <div className='mt-2'>
                                          <p className='text-uppercase monochrome-title fw-700 mb-16'>{t('Honors & Awards')}</p>
                                          <div className='row'>
                                             <div className='col-12'>
                                                <div
                                                   className='selectable-section'
                                                   onClick={(_) =>
                                                      SelectableSectionClicked('honors-awards', navigate, onNavigatedToSection)
                                                   }
                                                >
                                                   {honours.map((v, i) => (
                                                      <div
                                                         key={'honour__' + i}
                                                         className='d-flex align-items-start justify-content-start py-2 creative-border-bottom'
                                                      >
                                                         <span>
                                                            <LiaAwardSolid className='text-monochrome me-2' size={22} />
                                                         </span>
                                                         <div className='detail-achivements'>
                                                            <p
                                                               className='sec-value editor-list-margin-left'
                                                               dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                                            ></p>
                                                         </div>
                                                      </div>
                                                   ))}
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </section>
                                 )}

                                 {stepsInformation.findIndex((x) => x.title == 'References') > -1 && references.length > 0 && (
                                    <section className='References-sec-creative'>
                                       <div className='mt-2'>
                                          <p className='text-uppercase monochrome-title fw-700 mb-16'>{t('References')}</p>
                                          <div
                                             className='selectable-section'
                                             onClick={(_) => SelectableSectionClicked('references', navigate, onNavigatedToSection)}
                                          >
                                             <div className='d-grid display-grid-custom'>
                                                {references.map((v, i) => (
                                                   <div key={'refer__' + i} className='creative-border-bottom py-2'>
                                                      <div className='lang-card fs-12'>
                                                         <p className='fs-15 text-monochrome fw-600'>{v.name}</p>
                                                         <p className='fw-500'>{v.city}</p>
                                                         <p className='fw-500'>{v.tel}</p>
                                                      </div>
                                                   </div>
                                                ))}
                                             </div>
                                          </div>
                                       </div>
                                    </section>
                                 )}
                                 <section className='Custom-sec'>
                                    <div className='mt-2'>
                                       <p className='text-uppercase monochrome-title fw-700 mb-16'></p>
                                       <div className='row'>
                                          <div className='col-12'>
                                             <div
                                                className='selectable-section'
                                                onClick={(_) => SelectableSectionClicked('custom-sections', navigate, onNavigatedToSection)}
                                             >
                                                {stepsInformation.findIndex((x) => x.title == 'Custom sections') > -1 &&
                                                   customSections.map((v, i) => (
                                                      <div
                                                         key={'custom__' + i}
                                                         className='d-flex align-items-start justify-content-start py-2 creative-border-bottom'
                                                      >
                                                         <span className='diamond-icon-polished me-2 d-flex align-items-center justify-content-center'>
                                                            <IoDiamondOutline className='text-monochrome' size={22} />
                                                         </span>
                                                         <div className='detail-custom-sec mb-1'>
                                                            <span className='text-monochrome fw-600 fs-14'>{v.title}</span>
                                                            <p
                                                               className='sec-value editor-list-margin-left'
                                                               dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                                            ></p>
                                                         </div>
                                                      </div>
                                                   ))}
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </section>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </WrapperDiv>
      </>
   )
}

const WrapperDiv = styled.div`
   & .sec-value,
   .sec-value * {
      line-height: ${(props: { additionalSettings: AdditionalSettings }) =>
      !!props?.additionalSettings?.lineHeight && props?.additionalSettings?.lineHeight};
      font-size: ${(props: { additionalSettings: AdditionalSettings }) =>
      !!props?.additionalSettings?.fontSize && props?.additionalSettings?.fontSize + ' !important'};
   }

   & * {
      font-family: ${(props: { additionalSettings: AdditionalSettings }) =>
      !!props?.additionalSettings?.fontFamily && props?.additionalSettings?.fontFamily === 'Classic'
         ? 'Noto Serif, serif;'
         : props?.additionalSettings?.fontFamily === 'Modern'
            ? 'Rubik, sans-serif;'
            : props?.additionalSettings?.fontFamily === 'Elegant'
               ? 'Libre Baskerville, serif;'
               : props?.additionalSettings?.fontFamily === 'Tech'
                  ? 'Roboto Mono, monospace;'
                  : props?.additionalSettings?.fontFamily === 'Professional'
                     ? 'Bitter, serif;'
                     : 'default;'};
   }
`
