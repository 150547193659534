import React, { useState } from 'react';
import moment from 'moment';
import { DefaultProfileImage, SelectableSectionClicked } from '../SharedData';
import styled from "styled-components";
import { useTranslation } from 'react-i18next';

export const NewYork = (props: TemplateComponentProps) => {

    const { t } = useTranslation('resume');
    const { t: ct } = useTranslation('common');

    const {
        navigate,
        contactInformation,
        image,
        imageData,
        objectives,
        experiences,
        educations,
        languages,
        skills,
        achievements,
        certificates,
        customSections,
        honours,
        projects,
        publications,
        references,
        additionalSettings,
        stepsInformation,
        onNavigatedToSection
    } = props;

    return (
        <>
            <WrapperDiv additionalSettings={additionalSettings}>
                <div className=' p-0'>
                    <div className='template'>
                        <div className='preview-template'>
                            <div id="template-full">
                                <div className='template-main zoom-mobile-resume'>
                                    <div className='template_contentss d-flex'>
                                        <div className='sidebar-template-newyork'>
                                            {
                                                Object.values(contactInformation).filter(s => s).length > 0 &&
                                                <section className='contact-sec contact-information selectable-section' onClick={_ => SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)}>
                                                    <p className='text-uppercase title-objective mb-16 fw-600 text-white border-gray'> {t('Contact Information')} </p>
                                                    <div className='template-head'>
                                                        {
                                                            contactInformation.email &&
                                                            <div className='my-3'>
                                                                <p className='fw-600'> {t('email')}: </p>
                                                                <p className='fw-500'>{contactInformation.email}</p>
                                                            </div>
                                                        }
                                                        {
                                                            contactInformation.address &&
                                                            <div className='my-3'>
                                                                <p className='fw-600'> {t('address')} 1: </p>
                                                                <p className='fw-500'>{contactInformation.address}</p>
                                                            </div>
                                                        }
                                                        {
                                                            contactInformation.address2 &&
                                                            <div className='my-3'>
                                                                <p className='fw-600'> {t('address')} 2: </p>
                                                                <p className='fw-500'>{contactInformation.address2}</p>
                                                            </div>
                                                        }
                                                        {
                                                            contactInformation.city &&
                                                            <div className='my-3'>
                                                                <p className='fw-600'> {ct('city')}: </p>
                                                                <p className='fw-500'>{contactInformation.city}</p>
                                                            </div>
                                                        }
                                                        {
                                                            contactInformation.dob &&
                                                            <div className='my-3'>
                                                                <p className='fw-600'> {t('resDateBirth')}: </p>
                                                                <p className='fw-500'>{moment(contactInformation.dob).format('MM/DD/YYYY')}</p>
                                                            </div>
                                                        }
                                                        {
                                                            contactInformation.phone &&
                                                            <div className='my-3'>
                                                                <p className='fw-600'>{t('phone')}:</p>
                                                                <p className='fw-500'>{contactInformation.phone}</p>
                                                            </div>
                                                        }
                                                        {
                                                            contactInformation.nationality &&
                                                            <div className='my-3'>
                                                                <p className='fw-600'> {t('nationality')}:</p>
                                                                <p className='fw-500'>{contactInformation.nationality}</p>
                                                            </div>
                                                        }
                                                        {
                                                            contactInformation.linkedInLink &&
                                                            <div className='my-3'>
                                                                <p className='fw-600'>{t('link')}:</p>
                                                                <p className='fw-500'>{contactInformation.linkedInLink}</p>
                                                            </div>
                                                        }
                                                    </div>
                                                </section>
                                            }
                                            {
                                                stepsInformation.findIndex(x => x.title == 'Skills') > -1 &&
                                                <div className='skill-sec selectable-section' onClick={_ => SelectableSectionClicked('skills', navigate, onNavigatedToSection)}>
                                                    <p className='text-uppercase title-objective mb-16 fw-600 text-white pt-3 border-gray'> {t('Skills')} </p>
                                                    <div className='template-head'>
                                                        <ul className='list-unstyled mb-7'>
                                                            {
                                                                skills.length > 0 &&
                                                                skills.map((s, i) =>
                                                                    <li className='mb-1' key={'skill_' + s.name + s.id + i}>
                                                                        <p className='fw-500'>{s.name}</p>
                                                                    </li>
                                                                )
                                                            }
                                                        </ul>
                                                    </div>
                                                    <p className='text-uppercase title-objective mb-16 fw-600 text-white pt-3 border-gray'> {t('Languages')} </p>
                                                    <div className='template-head'>
                                                        {
                                                            languages.length > 0 &&
                                                            languages.map((s, i) =>
                                                                <div className='my-3' key={'languages_' + s.name + s.id}>
                                                                    {
                                                                        s.name &&
                                                                        <p className='fw-600'>{s.name}</p>
                                                                    }
                                                                    {
                                                                        s.level &&
                                                                        <p className='m-right text-white'>{s.level}</p>
                                                                    }
                                                                    {
                                                                        s.course &&
                                                                        <p className='m-right text-white'>{s.course}</p>
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className='main-content-template' style={{ width: '700px' }}>
                                            {
                                                (contactInformation.firstName || contactInformation.lastName || contactInformation.occupation) &&
                                                <section className='template-classic-header bg-white mb-0'>
                                                    <div className='d-flex justify-content-between border-bottoms'>

                                                        <div className='head-two mb-3'>
                                                            <div onClick={_ => SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)}>
                                                                <div className='fs- selectable-section'>
                                                                    <span className='mb-0'>{contactInformation.firstName} {contactInformation.lastName}</span>
                                                                </div>
                                                                <p className='selectable-section'>{contactInformation.occupation}</p>
                                                            </div>
                                                            {
                                                                stepsInformation.findIndex(x => x.title == 'Objective') > -1 && objectives &&
                                                                <div className='selectable-section mt-1 obj-list-margin-left' onClick={_ => SelectableSectionClicked('objective', navigate, onNavigatedToSection)}>
                                                                    <p className='sec-value' dangerouslySetInnerHTML={{ __html: objectives }}></p>
                                                                </div>
                                                            }
                                                        </div>
                                                        {/* {
                                                    image &&
                                                        <div className='description mt-2' onClick={_ => SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)}>
                                                            <img src={image}
                                                                className={imageData.isRounded ? "rounded-img mb-2 selectable-section amsterdam-img" : 'simple-img mb-2 selectable-section amsterdam-img'}
                                                                alt="newyork profile image"
                                                                onError={e => {
                                                                if (e.target instanceof HTMLImageElement) {
                                                                    e.target.src = DefaultProfileImage;
                                                                }
                                                                }} />
                                                        </div>
                                                    } */}
                                                    </div>
                                                </section>
                                            }
                                            {
                                                stepsInformation.findIndex(x => x.title == 'Experiences') > -1 && experiences.length > 0 ?
                                                    <section className='experience-sec padding-side border-bottoms selectable-section' onClick={_ => SelectableSectionClicked('work-experience', navigate, onNavigatedToSection)}>
                                                        <div className='mt-3 mb-16'>
                                                            <p className='text-uppercase title-objective mb-16 fw-600'> {t('Experiences')} </p>
                                                            {
                                                                experiences.map((e, i) =>
                                                                    <div className='contact-information d-flex' key={'exp_' + i}>
                                                                        <div className='widht-80px m-right border-end'>
                                                                            {
                                                                                e.location ?
                                                                                    <p className='fw-600'>{e.location}</p> : <></>
                                                                            }
                                                                            {
                                                                                (e.startDate || e.endDate) ?
                                                                                    <p className='widht-80px m-right'>
                                                                                        {
                                                                                            e.startDate ?
                                                                                                <span>{new Date(e.startDate).toLocaleString('default', { month: 'short' })} {new Date(e.startDate).getFullYear()}</span> : <></>
                                                                                        }
                                                                                        {
                                                                                            (e.endDate && !e.currentlyWorking) ?
                                                                                                <span> - {new Date(e.endDate).toLocaleString('default', { month: 'short' })} {new Date(e.endDate).getFullYear()}</span> : <></>
                                                                                        }
                                                                                        {
                                                                                            e.currentlyWorking ?
                                                                                                <em> - {t('present')} </em> : <></>
                                                                                        }
                                                                                    </p> : <></>
                                                                            }

                                                                        </div>
                                                                        <div className='header-info'>
                                                                            <div className='d-flex'>
                                                                                <div className='border-vertical position-relative'></div>
                                                                                <div>
                                                                                    {
                                                                                        e.title &&
                                                                                        <p className='sec-subtitle me-2 font-w700 fs-15'>{e.title}</p>
                                                                                    }
                                                                                    {
                                                                                        e.company &&
                                                                                        <p className='sec-value'>{e.company}</p>
                                                                                    }
                                                                                    {
                                                                                        e.description &&
                                                                                        <p className='mt-1 sec-value editor-list-margin-left' dangerouslySetInnerHTML={{ __html: e.description }}></p>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </section> : <></>
                                            }
                                            {
                                                stepsInformation.findIndex(x => x.title == 'Education') > -1 && educations.length > 0 &&
                                                <section className='education-sec padding-side border-bottoms selectable-section' onClick={_ => SelectableSectionClicked('education', navigate, onNavigatedToSection)}>
                                                    <div className='mt-3 mb-16'>
                                                        <p className='text-uppercase title-objective mb-16 fw-600'> {t('Education')} </p>
                                                        {
                                                            educations.map((s, i) =>
                                                                <div className='contact-information d-flex' key={'edu_' + i}>
                                                                    <div className='widht-80px m-right border-end'>
                                                                        {
                                                                            s.location &&
                                                                            <p className='fw-600'>{s.location}</p>
                                                                        }
                                                                        {
                                                                            (s.startDate || s.completeDate) &&
                                                                            <p className='widht-80px m-right'>
                                                                                {
                                                                                    s.startDate &&
                                                                                    <span>{new Date(s.startDate).toLocaleString('default', { month: 'short' })} {new Date(s.startDate).getFullYear()}</span>
                                                                                }

                                                                                {
                                                                                    (s.completeDate && !s.currentlyStudying) &&
                                                                                    <span> - {new Date(s.completeDate).toLocaleString('default', { month: 'short' })} {new Date(s.completeDate).getFullYear()}</span>
                                                                                }
                                                                                {
                                                                                    (s.currentlyStudying) &&
                                                                                    <span> - {t('present')} </span>
                                                                                }
                                                                            </p>
                                                                        }
                                                                    </div>
                                                                    <div className='header-info'>
                                                                        <div className='d-flex'>
                                                                            <div className='border-vertical position-relative'></div>
                                                                            <div>
                                                                                {
                                                                                    s.degree &&
                                                                                    <p className='sec-subtitle me-2 font-w700 fs-15'>{s.degree}</p>
                                                                                }
                                                                                {
                                                                                    s.institute &&
                                                                                    <p className='sec-value'>{s.institute}</p>
                                                                                }
                                                                                {
                                                                                    s.description &&
                                                                                    <p className='mt-1 sec-value editor-list-margin-left' dangerouslySetInnerHTML={{ __html: s.description }}></p>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                    </div>
                                                </section>
                                            }
                                            {
                                                stepsInformation.findIndex(x => x.title == 'Projects') > -1 && projects.length > 0 &&
                                                <section className='projects-sec padding-side selectable-section' onClick={_ => SelectableSectionClicked('projects', navigate, onNavigatedToSection)}>
                                                    <div className='mt-3 mb-16 border-bottoms'>
                                                        <p className='text-capitalize title-objective mb-16 fw-600'> {t('Projects')} </p>
                                                        <div className='mt-2 ps-3'>
                                                            {
                                                                projects.map((v, i) =>
                                                                    <div key={'projects__' + i}>
                                                                        <p className='sec-value my-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </section>
                                            }
                                            {
                                                stepsInformation.findIndex(x => x.title == 'Achievements') > -1 && achievements.length > 0 &&
                                                <section className='Achievements-sec padding-side selectable-section' onClick={_ => SelectableSectionClicked('achievements', navigate, onNavigatedToSection)}>
                                                    <div className='mt-3 mb-16 border-bottoms'>
                                                        <p className='text-capitalize title-objective mb-16 fw-600'> {t('Achievements')} </p>
                                                        <div className='mt-2 ps-3'>
                                                            {
                                                                achievements.map((v, i) =>
                                                                    <div key={'achievements__' + i}>
                                                                        <p className='sec-value my-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </section>
                                            }
                                            {
                                                stepsInformation.findIndex(x => x.title == 'Certifications & Courses') > -1 && certificates.length > 0 &&
                                                <section className='certificates-courses-sec padding-side selectable-section' onClick={_ => SelectableSectionClicked('certifications-courses', navigate, onNavigatedToSection)}>
                                                    <div className='mt-3 mb-16 border-bottoms'>
                                                        <p className='text-capitalize title-objective mb-16 fw-600'> {t('Certifications & Courses')} </p>
                                                        <div className='mt-2 ps-3'>
                                                            {
                                                                certificates.map((v, i) =>
                                                                    <div key={'certificates__' + i}>
                                                                        <p className='sec-value my-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </section>
                                            }
                                            {
                                                stepsInformation.findIndex(x => x.title == 'Honors & Awards') > -1 && honours.length > 0 &&
                                                <section className='honours-awards-sec padding-side selectable-section' onClick={_ => SelectableSectionClicked('honors-awards', navigate, onNavigatedToSection)}>
                                                    <div className='mt-3 mb-16 border-bottoms'>
                                                        <p className='text-capitalize title-objective mb-16 fw-600'> {t('Honors & Awards')} </p>
                                                        <div className='mt-2 ps-3'>
                                                            {
                                                                honours.map((v, i) =>
                                                                    <div key={'honour__' + i}>
                                                                        <p className='sec-value my-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </section>
                                            }
                                            {
                                                stepsInformation.findIndex(x => x.title == 'Publications') > -1 && publications.length > 0 &&
                                                <section className='publication-sec padding-side selectable-section' onClick={_ => SelectableSectionClicked('publications', navigate, onNavigatedToSection)}>
                                                    <div className='mt-3 mb-16 border-bottoms'>
                                                        <p className='text-capitalize title-objective mb-16 fw-600'> {t('Publications')} </p>
                                                        <div className='mt-2 ps-3'>
                                                            {
                                                                publications.map((v, i) =>
                                                                    <div key={'publication__' + i}>
                                                                        <p className='sec-value my-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </section>
                                            }
                                            {
                                                stepsInformation.findIndex(x => x.title == 'References') > -1 && references.length > 0 &&
                                                <section className='reference-sec padding-side selectable-section' onClick={_ => SelectableSectionClicked('references', navigate, onNavigatedToSection)}>
                                                    <div className='mt-3 mb-16 border-bottoms'>
                                                        <p className='text-capitalize title-objective mb-16 fw-600'> {t('References')} </p>
                                                        {
                                                            references.map((v, i) =>
                                                                <div key={'refer__' + i} className='position-relative mb-16 mt-2 ps-3'>
                                                                    <p className='sec-value quote3-img editor-list-margin-left'>{v.name}</p>
                                                                    <p className='sec-value quote3-img editor-list-margin-left'>{v.city} {(v.city != '' && v.tel != '') ? "|" : ""} {v.tel}</p>
                                                                    {/* <p className='sec-value quote3-img editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p> */}
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </section>
                                            }
                                            {
                                                stepsInformation.findIndex(x => x.title == 'Custom sections') > -1 && customSections.map((v, i) =>
                                                    <section key={'custom_' + i} className='driving-sec padding-side selectable-section' onClick={_ => SelectableSectionClicked('custom-sections', navigate, onNavigatedToSection)}>
                                                        <div className='mt-3 mb-16 border-bottoms'>
                                                            <p className='text-capitalize title-objective mb-16 fw-600'>{v.title}</p>
                                                            <div className='mt-2 ps-3'>
                                                                <p className='sec-value my-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                                            </div>
                                                        </div>
                                                    </section>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </WrapperDiv>
        </>
    )
}

const WrapperDiv = styled.div`
& .sidebar-template-newyork, .border-vertical:before{
    background-color:${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.color && props?.additionalSettings?.color};
}
& .sec-subtitle, .quote3-img:before{
    color:${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.color && props?.additionalSettings?.color};
}
& .sec-value , .sec-value *{
    line-height:${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.lineHeight && props?.additionalSettings?.lineHeight};
    font-size:${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.fontSize && props?.additionalSettings?.fontSize + " !important"};
   
}

& * {
    font-family:${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.fontFamily
        &&
        props?.additionalSettings?.fontFamily === "Classic" ? "Noto Serif, serif;" :
        props?.additionalSettings?.fontFamily === "Modern" ? "Rubik, sans-serif;" :
            props?.additionalSettings?.fontFamily === "Elegant" ? "Libre Baskerville, serif;" :
                props?.additionalSettings?.fontFamily === "Tech" ? "Roboto Mono, monospace;" :
                    props?.additionalSettings?.fontFamily === "Professional" ? "Bitter, serif;" : "default;"}
}
`
