import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import Tooltip, { TooltipProps } from 'react-bootstrap/Tooltip';
import { FaFillDrip, FaFont, FaMinus, FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { config } from '../../../Environments';
import { GetAxios, useDebouncedEffect, useNonInitialEffect } from '../../../Shared';
import { setGlobalLoading } from '../../../Store';
import { setResumeForEdit, setResumeSettings, setResumeTemplate } from '../../../Store/Slices/ResumeSlice';
import { TemplateList } from '../../../TemplateImageJSON';
import { ResumePreviewMain } from '../Builder/Editor/Preview/ResumePreviewMain';
import { TemplateSlider } from './TemplateSlider';
import { useTranslation } from 'react-i18next';

export const StylesFullScreenModal = (props: { show: boolean, onCloseClick: () => void }) => {

  const { onCloseClick, show } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { resumeId } = useParams();

  const resume = useSelector((state: AppStore) => state.resume);

  const templateSettings = TemplateList.find(s => s.id === resume.templateId);

  const defaultColor = templateSettings?.defaultColor.color || '';

  const [title, setTitle] = useState(resume.title || '');

  React.useEffect(() => {
    setTitle(resume.title);
  }, [resume]);


  useDebouncedEffect(() => {
    if (title) {
      dispatch(setResumeForEdit({ title: title }));
      GetAxios(navigate, dispatch).patch(config.apiBase + '/api/Resume/UpdateTitle', undefined, {
        params: {
          resumeId,
          title: title
        }
      });
    }
  }, [title], 1000);

  useNonInitialEffect(() => {
    dispatch(setGlobalLoading(true));
    const formData = new FormData();
    formData.append("data", JSON.stringify(resume.additionalSettings));
    GetAxios(navigate, dispatch).patch(config.apiBase + '/api/Resume/SaveAdditionalSettings', formData, {
      params: {
        resumeId
      }
    }).then(s => {
      dispatch(setGlobalLoading(false));
    })
  }, [resume.additionalSettings]);

  // Define the onTemplateClick function outside of the TemplateSlider component
  const handleTemplateClick = (e: Step1TemplateSelection) => {
    console.log("choose template from full screen");
    if (e.isFree === false) {
      GetAxios().get(config.apiBase + '/api/Resume/CheckResumePlanExist?planId=' + e.planId).then(res => {
        if (!res.data.data) {
          dispatch(setGlobalLoading(false));
          //   if (e.planId === 2) {
          //   navigate(`/employee-account/${e.id}/resume-gold-plan`);
          //  } else {
          navigate(`/employee-account/${e.id}/resume-plan?t=`+e.id+"&r="+resumeId);
          // }
        } else {
          GetAxios(navigate, dispatch).patch(config.apiBase + '/api/Resume/SetTemplate', undefined, {
            params: {
              id: resumeId,
              templateId: e.id
            }
          }).then(s => {
            dispatch(setResumeTemplate(e.id));
            dispatch(setGlobalLoading(false));
          });
        }
      });
    } else {
      GetAxios(navigate, dispatch).patch(config.apiBase + '/api/Resume/SetTemplate', undefined, {
        params: {
          id: resumeId,
          templateId: e.id
        }
      }).then(s => {
        dispatch(setResumeTemplate(e.id));
        dispatch(setResumeSettings({ color: defaultColor }))
        dispatch(setGlobalLoading(false));
      });
    }
  };

  useEffect(() => {
    dispatch(setResumeSettings({ color: defaultColor }))
  }, [templateSettings?.id]);
  return (
    <div className='zindex-fullscreenmdl'>
      <Modal
        show={show}
        fullscreen={true}
        onHide={onCloseClick}
        dialogClassName="modal-101w-styles"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton className='pt-32px'>
          <div className='resume-preview-options'>
            <div className='d-flex mobile-topbar-option gap-22'>
              <TemplateSlider onTemplateClick={(e) => handleTemplateClick(e)} />
              {
                (templateSettings?.colors.length || 0) > 1 &&
                <ColorDropdown colors={templateSettings?.colors.map(s => s.color) || []}
                  selectedColor={resume.additionalSettings.color || templateSettings?.colors[0].color || ''}
                  onSelectionChange={e => dispatch(setResumeSettings({ color: e }))} />
              }
            </div>
            <div className='d-flex mobile-topbar-option gap-22'>
              <FontDropdown fonts={templateSettings?.fontFamily || []} selectedFont={resume.additionalSettings.fontFamily} onSelectionChange={s => dispatch(setResumeSettings({ fontFamily: s }))} />

              <ToggleButtonFont sizes={templateSettings?.fontSize || []} selectedFontSize={resume.additionalSettings.fontSize} onSelectionChange={s => dispatch(setResumeSettings({ fontSize: s }))} />

              <FontSizeBtn selectedlineHeight={resume.additionalSettings.lineHeight || 1.0} onSelectionChange={s => dispatch(setResumeSettings({ lineHeight: s }))} />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className='mx-auto full-screen-modal-design'>
          <div className='template'>
            <ResumePreviewMain onNavigatedToSection={onCloseClick} />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}


export const FontDropdown = (props: {
  fonts: string[],
  selectedFont: string,
  onSelectionChange: (e: FontFamily) => void
}) => {
  const { fonts, onSelectionChange, selectedFont } = props;
  const { t } = useTranslation('resume');
  return (
    <div className='d-flex'>
      <div>
        <OverlayTrigger
          overlay={<Tooltip>{t('resumeStyleFullMdl.font')}</Tooltip>}
          delay={{ show: 250, hide: 400 }}
          placement="top">
          {({ ref, ...triggerHandler }) => (
            <span className="cursor-pointer" {...triggerHandler} ref={ref}><FaFont className='me-2 mt-1' size={20} /></span>
          )}
        </OverlayTrigger>
      </div>
      <div>
        <select
          className="form-select form-control key-weight m-font-dropdown" value={selectedFont}
          onChange={e => onSelectionChange(e.target.value as FontFamily)}>
          {
            fonts.map((v, i) =>
              <option key={v} className="m-font-options" value={v}>{v}</option>
            )
          }
        </select>
      </div>
    </div>
  )
}


export const ToggleButtonFont = (props: {
  sizes: FontSize[],
  selectedFontSize: FontSize,
  onSelectionChange: (s: FontSize) => void
}) => {
  const { sizes, selectedFontSize, onSelectionChange } = props;
  const { t } = useTranslation('resume');
  return (
    <div className='d-flex'>
      <div>
        <OverlayTrigger
          overlay={<Tooltip>{t('resumeStyleFullMdl.size')}</Tooltip>}
          delay={{ show: 250, hide: 400 }}
          placement="top">
          {({ ref, ...triggerHandler }) => (
            <span className="cursor-pointer" {...triggerHandler} ref={ref}><FaFont className='me-2 mt-1' size={20} /></span>
          )}
        </OverlayTrigger>
      </div>
      <div>
        <ToggleButtonGroup type="radio" className='toggle-btn-size' name="fontSizes" value={selectedFontSize} onChange={(e) => onSelectionChange(e)}>
          {
            sizes.map((v, i) =>
              <ToggleButton key={v + i}
                variant={'dark'}
                value={v}
                className={v}
                id={v + i}>
                Aa
              </ToggleButton>
            )
          }
        </ToggleButtonGroup>
      </div>
    </div>
  )
}

export const FontSizeBtn = (props: {
  selectedlineHeight: lineHeightTypeNum,
  onSelectionChange: (s: lineHeightTypeNum) => void
}) => {

  const { selectedlineHeight, onSelectionChange } = props;
  const { t } = useTranslation('resume');

  const lineHeights: lineHeightTypeNum[] = [1.0, 1.15, 1.5, 2.0, 2.5];
  // const [step, setStep] = useState(lineheight || lineHeights[0]);
  // React.useEffect(() => {
  //   setStep(lineheight)
  // }, [lineheight]);
  const setStep = (step: lineHeightTypeNum) => {
    // let newStepNum: lineHeightTypeNum = step;
    onSelectionChange(step)
  };
  return (
    <>
      <div className='d-flex' style={{ width: '160px' }}>
        <div>
          <OverlayTrigger
            overlay={<Tooltip>{t('resumeStyleFullMdl.lineSpacing')}</Tooltip>}
            delay={{ show: 250, hide: 400 }}
            placement="top">
            {({ ref, ...triggerHandler }) => (
              <span className="cursor-pointer" {...triggerHandler} ref={ref}><FaFont className='me-2 mt-1' size={20} /></span>
            )}
          </OverlayTrigger>
        </div>
        <div>
          <div className="input-group input-bg-plus">
            <div className="input-group-prepend">
              <button className="btn border-0 no-bg" type="button" onClick={_ => {
                if (selectedlineHeight > 1.0) {
                  setStep(lineHeights[lineHeights.indexOf(selectedlineHeight) - 1]);
                }
              }}>
                <FaMinus size={12} />
              </button>
            </div>
            <span className='p-1'>{selectedlineHeight.toFixed(2)}</span>
            <div className="input-group-prepend">
              <button className="btn border-0 no-bg" type="button" onClick={_ => {
                if (selectedlineHeight < 2.5) {
                  setStep(lineHeights[lineHeights.indexOf(selectedlineHeight) + 1]);
                }
              }}>
                <FaPlus size={12} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export const ColorDropdown = (props: {
  colors: string[],
  selectedColor: string,
  onSelectionChange: (e: string) => void
}) => {
  const { colors, selectedColor, onSelectionChange } = props;
  const { t } = useTranslation('resume');
  return (
    <div className='d-flex'>
      <div>
        <OverlayTrigger
          overlay={<Tooltip> {t('resumeStyleFullMdl.color')} </Tooltip>}
          delay={{ show: 250, hide: 400 }}
          placement="top">
          {({ ref, ...triggerHandler }) => (
            <span className="cursor-pointer" {...triggerHandler} ref={ref}><FaFillDrip className='me-3 mt-1' size={20} /></span>
          )}
        </OverlayTrigger>

      </div>
      <div>
        <Dropdown className="color-dropdowns">
          <Dropdown.Toggle className='d-inline-flex bg-color-dropdown'>
            <div className='color-list mt-1'>
              <span className='color1' style={{ backgroundColor: selectedColor }} ></span>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu variant='dark'>
            {
              colors.map((v, i) =>
                <Dropdown.Item key={v + i} onClick={_ => onSelectionChange(v)}>
                  <div className='color-list'>
                    <span className='color1' style={{ backgroundColor: v }}></span>
                  </div>
                </Dropdown.Item>
              )
            }
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  )
}
