import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { FaInstagram, FaLinkedinIn, FaFacebookF, FaArrowUp } from "react-icons/fa";
import { useSelector } from 'react-redux';
import { UserRole } from '../Shared';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation('footer');

  const [showButton, setShowButton] = useState(false);
  const auth = useSelector((state: AppStore) => state.auth);
  const userRole = UserRole(auth?.raw?.access_token);
  useEffect(() => {

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 270) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const currentYear = new Date().getFullYear();

  // const auth = useSelector((state: AppStore) => state.auth);
  return (
    <>
      <div>
        {showButton && (
          <button onClick={scrollToTop} className="back-to-top">
            <FaArrowUp size={24} />
          </button>
        )}
      </div>
      <div className="container-fluid footer-bg">
        {/* <footer className="d-flex flex-wrap justify-content-between align-items-center container copyright">
          <div className="col-md-6 d-flex align-items-center">
            <span className="">
              Copyright &copy; 2022{" "}
              <a
                className="text-decoration-none foot-color"
                href="https://www.Curriculosi.com/"
              >
                Maestro Media
              </a>{" "}
              All Rights Reserved.{" "}
              <Link className="text-decoration-none foot-color" to="/privacy-policy">
                Privacy Policy
              </Link>
            </span>
          </div>

          <ul className="nav col-md-6 justify-content-end list-unstyled d-flex">
            <li className="ms-3">
              <a className="text-muted pointer-event" >
                <FaInstagram />
              </a>
            </li>
            <li className="ms-3">
              <a className="text-muted pointer-event" >
                <FaFacebookF />
              </a>
            </li>
            <li className="ms-3">
              <a className="text-muted pointer-event" >
                <FaLinkedinIn />
              </a>
            </li>
    
          </ul>
          <div>
          
          </div>
        </footer> */}
        <footer className="container py-40-footer">
          <div className="row">
            <div className="col-md-5">
              <div className="footer-1">
                <div className="mb-0 w-25 mobile-footer-menu-none">
                  <Link to="/" className="d-flex flex-column text-decoration-none">
                    <img
                      src="/images/curriculosi_footer-icon.png"
                      className="footer-logo"
                      alt="Curriculosi-Footer-Logo"
                    />
                  </Link>
                </div>
                {/* Footer menu mobile version*/}
                <div className="mobile-footer desktop-footer-menu-none">
                  <ul className="d-flex flex-wrap fs-small gap-4 justify-content-center list-unstyled menu-ul-list mb-1">
                    <li>
                      <Link to="/cookies-policy" className="text-nowrap">{t('legalMenu.cookiesPolicy')}</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy" className="text-nowrap">{t('legalMenu.privacyPolicy')}</Link>
                    </li>
                    <li>
                      <Link to="/terms-of-use" className="text-nowrap">{t('legalMenu.termsUse')}</Link>
                    </li>
                    <li>
                      <Link to="/complaints" className="text-nowrap">{t('legalMenu.complaints')}</Link>
                    </li>
                    <li>
                      <Link to="/contact" className="text-nowrap  contact-link-show"
                      >{t('bonjanMenu.contactUs')}</Link>
                    </li>
                  </ul>
                </div>
                {/* Footer menu mobile version*/}
                {/* <div className="footer-description fw-500 w-75 mb-2 w-mob-desc-footer">
                  <p className="fs-15">{t('description')}</p>
                </div> */}
                <div className="d-flex justify-content-start social-icon-footer align-items-center mb-mob-j03 pt-xl-2 pt-lg-2 pt-md-2 pt-sm-2">
                  <div className="">
                    <ul className="nav justify-content-start list-unstyled d-flex gap-3 py-4">
                      {/* <li className="social-links-padd">
                        <a href="https://www.linkedin.com/company/bonjanjob/mycompany/?viewAsMember=true" target='_blank' className="pointer-event">
                          <FaLinkedinIn />
                        </a>
                      </li> */}
                      <li className="social-links-padd">
                        <Link to={'https://www.instagram.com/bonjanjob/'} target="_blank" className="pointer-event">
                          <FaInstagram />
                        </Link>
                      </li>
                      <li className="social-links-padd">
                        <Link to={'https://www.instagram.com/bonjanjob/'} target="_blank" className="pointer-event">
                          <FaFacebookF />
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* <div className="d-flex align-items-center">
                      <p className="fs-16 m-0 fs-small">{t('currencyChoice')}</p>
                      <div className="ms-3">
                        <select name="currencyofchoice" className='form-select fw-12 form-select-sm fw-600'>

                          <option key={"c_" + 1} value="1">USD</option>
                          <option key={"c_" + 2} value="2">HTG</option>
                        </select>
                      </div>
                    </div> */}
                </div>

                <div className="copyright-text desktop-copyright pt-3 pb-2">
                  <div className="fs-small">
                    <span className="">
                      <span className="text-decoration-none foot-color">
                        {t('rdYesCom')}
                      </span> &copy; {currentYear} <br />
                      {t('allRight')}
                    </span>
                  </div>
                </div>

                <div className="copyright-text d-flex align-items-center mobile-copyright">
                  <div className="me-3">
                    <img src="/images/maestroicon.png" className="" alt="Maestro Icon" />
                  </div>
                  <div className="fs-small">
                    <span className="">
                      <a
                        className="text-decoration-none foot-color"
                        href="https://bonjanjob.com/"
                      >
                        Maestro Medias
                      </a> &copy; {currentYear} <br />
                      {t('allRightMobile')}
                    </span>
                  </div>
                </div>

              </div>
            </div>
            <div className="col-md-7">
              <div className="container ps-lg-5 ps-md-5">
                <div className="row gx-5 justify-content-between">
                  <div className="col-lg-4 col-md-6 mobile-footer-menu-none">
                    <div className="footer-2">
                      {
                        userRole != 'Employer' &&
                        <>
                          <h5 className="fw-700 mb-3 fs-medium">{t('jobSeekerMenu.jobSeekers')}</h5>
                          <ul className="list-unstyled menu-ul-list fs-small">
                            <li className="mb-2">
                              <Link to="/candidate/register">{t('jobSeekerMenu.jobSeekersRegister')}</Link>
                            </li>
                            <li className="mb-2">
                              <Link to="/resumes">{t('jobSeekerMenu.buildYourResume')}</Link>
                            </li>
                          </ul>
                        </>
                      }
                      {
                        userRole == 'Employer' &&
                        <>
                          <h5 className="fw-700 mb-3 fs-medium">{t('employerMenu.employer')}</h5>
                          <ul className="list-unstyled menu-ul-list fs-small">
                            <li className="mb-2">
                              <Link to="/job/jobregistration">{t('employerMenu.postJob')}</Link>
                            </li>
                            <li className="mb-2">
                              <Link to="/announce/registration">{t('employerMenu.postSeminar')}</Link>
                            </li>
                            <li className="mb-2">
                              <Link to="/tender/registration" >{t('employerMenu.postTender')}</Link>

                            </li>
                          </ul>
                        </>
                      }
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 mobile-footer-menu-none">
                    <div className="footer-3 ms-lg-4 ms-md-4">
                      <h5 className="fw-700 mb-3 fs-medium"> Curriculosi </h5>
                      <ul className="list-unstyled menu-ul-list fs-small">
                        <li className="mb-2">
                          <Link to="/contact">{t('bonjanMenu.contactUs')}</Link>
                        </li>
                        <li className="mb-2">
                          <Link to="/send-feedback">{t('bonjanMenu.sendFeedback')}</Link>
                        </li>
                      </ul>
                    </div>
                    {/* {
                      userRole == '' &&
                      <div className="job-seeker pt-2 ms-lg-4 ms-md-4">
                        <h5 className="fw-700 my-3 fs-medium">{t('employerMenu.employer')}</h5>
                        <ul className="list-unstyled menu-ul-list fs-small">
                          <li className="mb-0">
                            <Link to="/job/jobregistration" >{t('employerMenu.postJob')}</Link>
                          </li>
                        </ul>
                      </div>
                    } */}
                  </div>

                  <div className="col-lg-4 col-md-6 mobile-footer-menu-none">
                    <div className="footer-4 ms-lg-5 ms-md-0">
                      <h5 className="fw-700 mb-3 fs-medium">{t('legalMenu.legal')}</h5>
                      <ul className="list-unstyled menu-ul-list fs-small">
                        <li className="mb-2">
                          <Link to="/cookies-policy" className="text-nowrap">{t('legalMenu.cookiesPolicy')}</Link>
                        </li>
                        <li className="mb-2">
                          <Link to="/privacy-policy" className="text-nowrap">{t('legalMenu.privacyPolicy')}</Link>
                        </li>
                        <li className="mb-2">
                          <Link to="/terms-of-use" className="text-nowrap">{t('legalMenu.termsUse')}</Link>
                        </li>
                        <li className="mb-2">
                          <Link to="/complaints" className="text-nowrap">{t('legalMenu.complaints')}</Link>
                        </li>
                        <li className="mb-2">
                          <Link to="/contact" className="text-nowrap  contact-link-show"
                          >{t('bonjanMenu.contactUs')}</Link>
                        </li>
                      </ul>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </footer>
      </div>
    </>
  );
}
export default Footer;
