import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Autoplay, Pagination } from "swiper";
import Button from 'react-bootstrap/Button';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { setShowModel } from "../../Store";
import { useNavigate, useParams } from 'react-router-dom';
import { ApiResponseE, GetAxios, Sorter, useDebouncedEffect, UserRole, parseJwt } from "../../Shared";
import { config } from "../../Environments";
import { toast } from "react-toastify";
import { setGlobalLoading } from '../../Store';
import { setResumeActiveStep, setResumeTemplate } from '../../Store/Slices/ResumeSlice';
export const CustomSliderCard = styled.div`
position: relative;
    background-color: rgba(156,178,220,.2);
    padding: 24px;
    display: block;
    text-align: center;
    border-radius: 20px;
    transition: background-color .2s;
`
export const TemplateResumeImg = styled.img`
box-shadow: 0 4px 8px rgba(0,0,0,.1);
border-radius: 8px;
color: transparent;
width: 100%;
height: auto;
`

export const StartTempBtn = styled.div`
text-align: center;
    transform: scale(0.9);
    opacity: 0;
    position: absolute;
    bottom: 30px;
    left: 0%;
    
`

const TemplateSlider_HeroSection = () => {
    const { t } = useTranslation('home');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t: ts } = useTranslation('alert');
    const auth = useSelector((state: AppStore) => state.auth);

    const handleStartTemplate = (templateId: string) => {
        if (!auth.isLoading && !auth.isAuthenticated) {
            dispatch(setShowModel(true));
        }
        else {
            dispatch(setGlobalLoading(true));
            GetAxios(navigate).post<ApiResponseE<number>>(config.apiBase + '/api/Resume/CreateBlank')
                .then(s => {
                    if (s.data.success) {
                        //Check user already have purchase this package and package not expired
                        console.log("choose free template");
                        console.log(s);

                        GetAxios(navigate, dispatch).patch(config.apiBase + '/api/Resume/SetTemplate', undefined, {
                            params: {
                                id: s.data.data,
                                templateId: templateId
                            }
                        }).then(res => {
                            dispatch(setResumeTemplate(templateId));
                            navigate(`/resumes/builder/${s.data.data}/editor/access-information`);
                            dispatch(setGlobalLoading(false));
                        });

                    } else {
                        dispatch(setGlobalLoading(false));
                        toast(ts('resume.unableCreate'), { type: 'error' });
                    }
                }).catch(s => {
                    dispatch(setGlobalLoading(false));
                    toast(ts('resume.unableCreate'), { type: 'error' });
                });
        }
    };
    const templateResume = [
        {
            id: 1,
            templateId: "a8cb0f41-5615-4609-97c5-f30a8d34111f",
            alternateTitle: "montvideo-template",
            img: "/images/resume-img/free-template/montvideo-template-free.webp",
        },
        {
            id: 2,
            templateId: "f16ecb7f-9cfd-47de-b5c0-288bb395547a",
            alternateTitle: "newyork-template",
            img: "/images/resume-img/free-template/newyork-template-free.png",
        },
        {
            id: 3,
            templateId: "dd1ffbfe-6ca5-4978-a004-f31f384945d6",
            alternateTitle: "Omsk-template",
            img: "/images/resume-img/free-template/Omsk-template-free.png",
        },
        {
            id: 4,
            templateId: "6bfd5e83-effc-4963-bb91-a5c539db76e7",
            alternateTitle: "johannesburg-template.",
            img: "/images/resume-img/free-template/johannesburg-template-free.png",
        },
    ];
    const paginationStyle = {
        marginTop: '260px !important',
    };
    const params = {
        slidesPerView: 4,
        spaceBetween: 2,
        slidesPerGroup: 1,
        loop: true,
        loopFillGroupWithBlank: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            style: paginationStyle,
        },
        autoplay: {
            delay: 7800,
            disableOnInteraction: false,
        },
        modules: [Autoplay, Navigation, Pagination],
        className: "mySwiper px-3",
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 10
            },
            410: {
                slidesPerView: 1,
                spaceBetween: 10
            },
            510: {
                slidesPerView: 1,
                spaceBetween: 10
            },
            640: {
                slidesPerView: 2,
                spaceBetween: 10
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 10,
            },
            1200: {
                slidesPerView: 4,
                spaceBetween: 10,
            },
            1800: {
                slidesPerView: 6,
                spaceBetween: 10,
            },
        },
    };



    return (
        <>
            <div className="resume-template-slider-sec mb-3">
                <Swiper {...params} style={{ height: '0px !important' }}>
                    {templateResume.map((item, index) => (
                        <SwiperSlide key={index}>
                            <CustomSliderCard>
                                <div className="img-cont">
                                    <TemplateResumeImg src={item.img} className="template-img" alt={item.alternateTitle} />
                                    <div className="overlay-temp overlay-resume-slide"></div>
                                    <StartTempBtn className="w-100 start-temp-btn-SD01">
                                        <Button onClick={() => { handleStartTemplate(item.templateId) }}
                                            className="rounded-3 fw-600 btn-template text-nowrap text-capitalize animate__animated animate__fadeInUpBig">
                                            {t('startWithThisTemplate')}
                                        </Button>
                                    </StartTempBtn>
                                </div>
                            </CustomSliderCard>
                        </SwiperSlide>
                    ))}

                    <div className="swiper-button-next swiper-btn-custom-CD01"></div>
                    <div className="swiper-button-prev swiper-btn-custom-CD01"></div>
                    <div className="swiper-pagination"></div>
                </Swiper>
            </div>
        </>
    );
};
export default TemplateSlider_HeroSection;
