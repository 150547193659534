import React, { useState } from 'react';
import moment from 'moment';
import { DefaultProfileImage, SelectableSectionClicked } from '../SharedData';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IoDiamondOutline } from 'react-icons/io5';

export const IvyLeague = (props: TemplateComponentProps) => {
   const { t } = useTranslation('resume')

   const {
      navigate,
      contactInformation,
      image,
      imageData,
      objectives,
      experiences,
      educations,
      languages,
      skills,
      achievements,
      certificates,
      customSections,
      honours,
      projects,
      publications,
      references,
      additionalSettings,
      stepsInformation,
      onNavigatedToSection,
   } = props

   return (
      <>
         <WrapperDiv additionalSettings={additionalSettings}>
            <div className='template'>
               <div className='preview-template'>
                  <div id='template-full' className='IvyLeagueResumePage shadow-lg'>
                     <div className='template-main'>
                        <section className='name-sec'>
                           {(contactInformation.firstName || contactInformation.lastName || contactInformation.occupation) && (
                              <div className='template-prague-name text-uppercase text-center'>
                                 <div
                                    className='selectable-section'
                                    onClick={(_) => SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)}
                                 >
                                    <h1 className='fw-700 word-wrap fs-22'>
                                       <span>{contactInformation.firstName}</span> <span>{contactInformation.lastName}</span>
                                    </h1>
                                    <p className='fw-700 fs-15 text-lvyleague'>{contactInformation.occupation}</p>
                                 </div>
                              </div>
                           )}

                           {Object.values(contactInformation).filter((s) => s).length > 0 && (
                              <div
                                 className='contact-info-sec selectable-section'
                                 onClick={(_) => SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)}
                              >
                                 <ul className='d-flex flex-wrap justify-content-center'>
                                    <li className='list-unstyled me-4'>
                                       <p className='text-decoration-none text-black fw-600 fs-13'>{contactInformation.phone}</p>
                                    </li>
                                    <li className='me-4'>
                                       <p className='text-decoration-none text-black fw-600 fs-13'>{contactInformation.email}</p>
                                    </li>
                                    <li className='me-4'>
                                       <p className='text-decoration-none text-black fw-600 fs-13'>
                                          {moment(contactInformation.dob).format('MM/DD/YYYY')}
                                       </p>
                                    </li>
                                    <li className='me-4'>
                                       <p className='text-decoration-none text-black fw-600 fs-13'>{contactInformation.nationality}</p>
                                    </li>
                                    <li className='me-4'>
                                       <p className='text-decoration-none text-black fw-600 fs-13'>{contactInformation.address}</p>
                                    </li>
                                    <li className='me-4'>
                                       <p className='text-decoration-none text-black fw-600 fs-13'>{contactInformation.address2}</p>
                                    </li>
                                    <li className='me-4'>
                                       <p className='text-decoration-none text-black fw-600 fs-13'>{contactInformation.city}</p>
                                    </li>
                                 </ul>
                              </div>
                           )}
                        </section>

                        {stepsInformation.findIndex((x) => x.title == 'Objective') > -1 && objectives && (
                           <section
                              className='objective-sec'
                              onClick={(_) => SelectableSectionClicked('objective', navigate, onNavigatedToSection)}
                           >
                              <div className='mt-3'>
                                 <p className='text-uppercase temp-lvyleague-heading text-center fw-500'>{t('Objective')}</p>
                                 <div className='selectable-section'>
                                    <p
                                       className='sec-value my-2 editor-list-margin-left'
                                       dangerouslySetInnerHTML={{ __html: objectives }}
                                    ></p>
                                 </div>
                              </div>
                           </section>
                        )}

                        {stepsInformation.findIndex((x) => x.title == 'Skills') > -1 && (
                           <section
                              className='skills-sec'
                              onClick={(_) => SelectableSectionClicked('skills', navigate, onNavigatedToSection)}
                           >
                              <div className='mt-3'>
                                 <p className='text-uppercase temp-lvyleague-heading text-center fw-500'>{t('Skills')}</p>
                                 <div className='lh-base selectable-section'>
                                    <ul className='ps-4 fs-12 d-flex flex-wrap'>
                                       {skills.length > 0 &&
                                          skills.map((s, i) => (
                                             <li key={'skill_' + s.name + s.id + i} className='mb-1 me-4'>
                                                {s.name}
                                             </li>
                                          ))}
                                    </ul>
                                 </div>
                              </div>
                           </section>
                        )}

                        {stepsInformation.findIndex((x) => x.title == 'Languages') > -1 && (
                           <section
                              className='languages-sec'
                              onClick={(_) => SelectableSectionClicked('languages', navigate, onNavigatedToSection)}
                           >
                              <div className='mt-3'>
                                 <p className='text-uppercase temp-lvyleague-heading text-center fw-500'>{t('Languages')}</p>
                                 <div className='selectable-section'>
                                    <div className='row'>
                                       {languages.length > 0 &&
                                          languages.map((s, i) => (
                                             <div key={'languages_' + s.name + s.id + i} className='col-4'>
                                                <div className='lang-card fs-12'>
                                                   {s.name && <p className='fw-700'>{s.name}</p>}
                                                   <p className='lh-sm'>
                                                      {s.level && <span>{s.level}</span>}{' '}
                                                      {s.course && <span className='barline'>{s.course}</span>}
                                                   </p>
                                                </div>
                                             </div>
                                          ))}
                                    </div>
                                 </div>
                              </div>
                           </section>
                        )}

                        {stepsInformation.findIndex((x) => x.title == 'Experiences') > -1 && experiences.length > 0 ? (
                           <section
                              className='experience-sec'
                              onClick={(_) => SelectableSectionClicked('work-experience', navigate, onNavigatedToSection)}
                           >
                              <div className='prague-section-lh mt-3'>
                                 <p className='text-uppercase temp-lvyleague-heading text-center fw-500'>{t('Experiences')}</p>
                                 <div className='row selectable-section'>
                                    {experiences.map((e, i) => (
                                       <div className='col-12' key={'exp_' + i}>
                                          <div className='edu-detail'>
                                             <div className='d-flex align-items-center justify-content-between'>
                                                <div>
                                                   <p className='fw-700 fs-16 text-lvyleague'>{e.title}</p>
                                                   {e.company && <p className='fw-500 fs-14 text-gray'>{e.company}</p>}
                                                </div>
                                                <div className='fw-500 fs-14'>
                                                   {e.location && <p>{e.location}</p>}
                                                   <p className='fst-italic'>
                                                      {e.startDate || e.endDate ? (
                                                         <>
                                                            {e.startDate ? (
                                                               <span>
                                                                  {new Date(e.startDate).toLocaleString('default', { month: 'short' })}{' '}
                                                                  {new Date(e.startDate).getFullYear()}
                                                               </span>
                                                            ) : (
                                                               <></>
                                                            )}
                                                            {e.endDate && !e.currentlyWorking ? (
                                                               <span className='barline'>
                                                                  {new Date(e.endDate).toLocaleString('default', { month: 'short' })}{' '}
                                                                  {new Date(e.endDate).getFullYear()}
                                                               </span>
                                                            ) : (
                                                               <></>
                                                            )}

                                                            {e.currentlyWorking ? <em className='barline'>{t('present')} </em> : <></>}
                                                         </>
                                                      ) : (
                                                         <></>
                                                      )}
                                                   </p>
                                                </div>
                                             </div>
                                             <div className=''>
                                                {e.description && (
                                                   <p
                                                      className='sec-value my-2 editor-list-margin-left template-prague-desc'
                                                      dangerouslySetInnerHTML={{ __html: e.description }}
                                                   ></p>
                                                )}
                                             </div>
                                          </div>
                                       </div>
                                    ))}
                                 </div>
                              </div>
                           </section>
                        ) : (
                           <></>
                        )}

                        {stepsInformation.findIndex((x) => x.title == 'Education') > -1 && educations.length > 0 ? (
                           <section
                              className='education-sec'
                              onClick={(_) => SelectableSectionClicked('education', navigate, onNavigatedToSection)}
                           >
                              <div className='prague-section-lh mt-3'>
                                 <p className='text-uppercase temp-lvyleague-heading text-center fw-500'>{t('Education')}</p>
                                 <div className='row selectable-section'>
                                    {educations.map((e, i) => (
                                       <div className='col-12' key={'edu__' + i}>
                                          <div className='edu-detail'>
                                             <div className='d-flex align-items-center justify-content-between'>
                                                <div>
                                                   <p className='fw-700 fs-16 text-lvyleague'>{e.degree}</p>
                                                   {e.institute && <p className='fw-500 fs-14 text-gray'>{e.institute}</p>}
                                                </div>
                                                <div className='fw-500 fs-14'>
                                                   {e.location && <p>{e.location}</p>}
                                                   <p className='fst-italic'>
                                                      {e.startDate || e.completeDate ? (
                                                         <>
                                                            {e.startDate ? (
                                                               <span>
                                                                  {new Date(e.startDate).toLocaleString('default', { month: 'short' })}{' '}
                                                                  {new Date(e.startDate).getFullYear()}
                                                               </span>
                                                            ) : (
                                                               <></>
                                                            )}
                                                            {e.completeDate && !e.currentlyStudying ? (
                                                               <span className='barline'>
                                                                  {new Date(e.completeDate).toLocaleString('default', { month: 'short' })}{' '}
                                                                  {new Date(e.completeDate).getFullYear()}
                                                               </span>
                                                            ) : (
                                                               <></>
                                                            )}

                                                            {e.currentlyStudying ? <em className='barline'>{t('present')} </em> : <></>}
                                                         </>
                                                      ) : (
                                                         <></>
                                                      )}
                                                   </p>
                                                </div>
                                             </div>
                                             <div className=''>
                                                {e.description && (
                                                   <p
                                                      className='sec-value my-2 editor-list-margin-left template-prague-desc'
                                                      dangerouslySetInnerHTML={{ __html: e.description }}
                                                   ></p>
                                                )}
                                             </div>
                                          </div>
                                       </div>
                                    ))}
                                 </div>
                              </div>
                           </section>
                        ) : (
                           <></>
                        )}

                        {stepsInformation.findIndex((x) => x.title == 'Projects') > -1 && projects.length > 0 && (
                           <section
                              className='Project-sec'
                              onClick={(_) => SelectableSectionClicked('projects', navigate, onNavigatedToSection)}
                           >
                              <div className='mt-3'>
                                 <p className='text-uppercase temp-lvyleague-heading text-center fw-500'>{t('Projects')}</p>
                                 <div className='selectable-section'>
                                    {projects.map((v, i) => (
                                       <p
                                          className='sec-value my-2 editor-list-margin-left'
                                          key={'projects__' + i}
                                          dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                       ></p>
                                    ))}
                                 </div>
                              </div>
                           </section>
                        )}

                        {stepsInformation.findIndex((x) => x.title == 'Achievements') > -1 && achievements.length > 0 && (
                           <section
                              className='Achievements-sec'
                              onClick={(_) => SelectableSectionClicked('achievements', navigate, onNavigatedToSection)}
                           >
                              <div className='mt-3'>
                                 <p className='text-uppercase temp-lvyleague-heading text-center fw-500'>{t('Achievements')}</p>
                                 <div className='row'>
                                    {achievements.map((v, i) => (
                                       <div className='col-4' key={'achievements__' + i}>
                                          <div className='selectable-section'>
                                             <div className='d-flex align-items-start justify-content-start mb-2'>
                                                <span>
                                                   <IoDiamondOutline className='text-lvyleague me-2' size={22} />
                                                </span>
                                                <div className='detail-achivements'>
                                                   <p
                                                      className='sec-value editor-list-margin-left'
                                                      dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                                   ></p>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    ))}
                                 </div>
                              </div>
                           </section>
                        )}

                        {stepsInformation.findIndex((x) => x.title == 'Certifications & Courses') > -1 && certificates.length > 0 && (
                           <section
                              className='Certification-sec'
                              onClick={(_) => SelectableSectionClicked('certifications-courses', navigate, onNavigatedToSection)}
                           >
                              <div className='mt-3'>
                                 <p className='text-uppercase temp-lvyleague-heading text-center fw-500'>{t('Certifications & Courses')}</p>
                                 <div className='selectable-section'>
                                    {certificates.map((v, i) => (
                                       <p
                                          className='sec-value my-2 editor-list-margin-left'
                                          key={'certificates__' + i}
                                          dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                       ></p>
                                    ))}
                                 </div>
                              </div>
                           </section>
                        )}

                        {stepsInformation.findIndex((x) => x.title == 'Honors & Awards') > -1 && honours.length > 0 && (
                           <section
                              className='Honors-Award-sec'
                              onClick={(_) => SelectableSectionClicked('honors-awards', navigate, onNavigatedToSection)}
                           >
                              <div className='mt-3'>
                                 <p className='text-uppercase temp-lvyleague-heading text-center fw-500'>{t('Honors & Awards')}</p>
                                 <div className='row'>
                                    {honours.map((v, i) => (
                                       <div className='col-4' key={'honour__' + i}>
                                          <div className='selectable-section'>
                                             <div className='d-flex align-items-start justify-content-start mb-2'>
                                                <span>
                                                   <IoDiamondOutline className='text-lvyleague me-2' size={22} />
                                                </span>
                                                <div className='detail-achivements'>
                                                   <p
                                                      className='sec-value editor-list-margin-left'
                                                      dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                                   ></p>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    ))}
                                 </div>
                              </div>
                           </section>
                        )}

                        {stepsInformation.findIndex((x) => x.title == 'Publications') > -1 && publications.length > 0 && (
                           <section
                              className='Publications-sec'
                              onClick={(_) => SelectableSectionClicked('publications', navigate, onNavigatedToSection)}
                           >
                              <div className='mt-3'>
                                 <p className='text-uppercase temp-lvyleague-heading text-center fw-500'> {t('Publications')}</p>
                                 <div className='selectable-section'>
                                    {publications.map((v, i) => (
                                       <p
                                          className='sec-value my-2 editor-list-margin-left'
                                          key={'publication__' + i}
                                          dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                       ></p>
                                    ))}
                                 </div>
                              </div>
                           </section>
                        )}

                        {stepsInformation.findIndex((x) => x.title == 'References') > -1 && references.length > 0 && (
                           <section
                              className='References-sec'
                              onClick={(_) => SelectableSectionClicked('references', navigate, onNavigatedToSection)}
                           >
                              <div className='mt-3'>
                                 <p className='text-uppercase temp-lvyleague-heading text-center fw-500'> {t('References')}</p>
                                 <div className='selectable-section'>
                                    <div className='lang-card fs-12'>
                                       {references.map((v, i) => (
                                          <p className='mb-2' key={'refer__' + i}>
                                             <span className='text-lvyleague fw-600'>{v.name}</span>
                                             <span className='barline'>{v.city}</span>
                                             <span className='barline'>{v.tel}</span>
                                          </p>
                                       ))}
                                    </div>
                                 </div>
                              </div>
                           </section>
                        )}

                        <section className='Custom-sec'>
                           <div className='mt-3'>
                              <p className='text-uppercase temp-lvyleague-heading text-center fw-500'></p>
                              <div className='row'>
                                 {stepsInformation.findIndex((x) => x.title == 'Custom sections') > -1 &&
                                    customSections.map((v, i) => (
                                       <div className='col-4' key={'custom_' + i}>
                                          <div
                                             className='selectable-section'
                                             onClick={(_) => SelectableSectionClicked('custom-sections', navigate, onNavigatedToSection)}
                                          >
                                             <div className='d-flex align-items-start justify-content-start mb-2'>
                                                <span>
                                                   <IoDiamondOutline className='text-lvyleague me-2' size={22} />
                                                </span>
                                                <div className='detail-achivements'>
                                                   <span className='text-lvyleague fw-600 fs-14'>{v.title}</span>
                                                   <p
                                                      className='sec-value editor-list-margin-left'
                                                      dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                                   ></p>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    ))}
                              </div>
                           </div>
                        </section>
                     </div>
                  </div>
               </div>
            </div>
         </WrapperDiv>
      </>
   )
}

const WrapperDiv = styled.div`
   & .sec-value,
   .sec-value * {
      line-height: ${(props: { additionalSettings: AdditionalSettings }) =>
      !!props?.additionalSettings?.lineHeight && props?.additionalSettings?.lineHeight};
      font-size: ${(props: { additionalSettings: AdditionalSettings }) =>
      !!props?.additionalSettings?.fontSize && props?.additionalSettings?.fontSize + ' !important'};
   }

   & .text-lvyleague {
      color: ${(props: { additionalSettings: AdditionalSettings }) =>
      !!props?.additionalSettings?.color && props.additionalSettings.color} !important;
   }

   & .temp-lvyleague-heading {
      color: ${(props: { additionalSettings: AdditionalSettings }) =>
      !!props?.additionalSettings?.color && props.additionalSettings.color} !important;
      border-bottom: 1px solid
         ${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.color && props.additionalSettings.color} !important;
   }

   & * {
      font-family: ${(props: { additionalSettings: AdditionalSettings }) =>
      !!props?.additionalSettings?.fontFamily && props?.additionalSettings?.fontFamily === 'Classic'
         ? 'Noto Serif, serif;'
         : props?.additionalSettings?.fontFamily === 'Modern'
            ? 'Rubik, sans-serif;'
            : props?.additionalSettings?.fontFamily === 'Elegant'
               ? 'Libre Baskerville, serif;'
               : props?.additionalSettings?.fontFamily === 'Tech'
                  ? 'Roboto Mono, monospace;'
                  : props?.additionalSettings?.fontFamily === 'Professional'
                     ? 'Bitter, serif;'
                     : 'default;'};
   }
`
