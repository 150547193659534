import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CardTitle } from "../Tenders/Index";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';

const Index = () => {

    const navigate = useNavigate();
    const { t } = useTranslation('admin');

    return (
        <>
            <div className="container-fuild py-50 margin15">
                <div className="card custom-shadow h-250px">
                    <div className="card-header">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                                <CardTitle> Member List</CardTitle>
                            </div>
                        </div>
                    </div>
                    <div className="card-body px-4">
                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <div>
                                    <h5> This one is the member section page.</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Index;
