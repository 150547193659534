import React, { useState } from 'react';
import moment from 'moment';
import { DefaultProfileImage, SelectableSectionClicked } from '../SharedData';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { BiSolidQuoteSingleLeft } from 'react-icons/bi';

export const Quebec = (props: TemplateComponentProps) => {
   const { t } = useTranslation('resume');
   const { t: ct } = useTranslation('common');

   const {
      navigate,
      contactInformation,
      image,
      imageData,
      objectives,
      experiences,
      educations,
      languages,
      skills,
      achievements,
      certificates,
      customSections,
      honours,
      projects,
      publications,
      references,
      additionalSettings,
      stepsInformation,
      onNavigatedToSection,
   } = props

   return (
      <WrapperDiv additionalSettings={additionalSettings}>
         <div className='template'>
            <div className='preview-template'>
               <div id='template-full'>
                  <div className='template-main'>
                     <div className='quebec-template-container h-100'>
                        <div className='container-fluid'>
                           <div className='row'>
                              <div className='col-9'>
                                 <div className='template-quebec-left-col'>
                                    <div className='row border-bottom pb-3'>
                                       <div className='col-9'>
                                          <div className='quebec-header-left'>
                                             {(contactInformation.firstName ||
                                                contactInformation.lastName ||
                                                contactInformation.occupation) && (
                                                   <section
                                                      className='template-quebec-name-sec selectable-section'
                                                      onClick={(_) =>
                                                         SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)
                                                      }
                                                   >
                                                      <p className='text-uppercase fw-700 template-quebec-name'>
                                                         <span>{contactInformation.firstName}</span>
                                                         <span className='ms-6px'>{contactInformation.lastName}</span>
                                                      </p>
                                                      <p className='fw-700'>{contactInformation.occupation}</p>
                                                   </section>
                                                )}

                                             {stepsInformation.findIndex((x) => x.title == 'Objective') > -1 && objectives && (
                                                <section
                                                   className='template-quebec-objective-sec selectable-section mt-2'
                                                   onClick={(_) => SelectableSectionClicked('objective', navigate, onNavigatedToSection)}
                                                >
                                                   <p
                                                      className='sec-value editor-list-margin-left'
                                                      dangerouslySetInnerHTML={{ __html: objectives }}
                                                   ></p>
                                                </section>
                                             )}
                                          </div>
                                       </div>

                                       <div className='col-3'>
                                          <div className='quebec-header-right d-flex justify-content-end'>
                                             {Object.values(contactInformation).filter((s) => s).length > 0 && (
                                                <section
                                                   className='template-quebec-img-sec selectable-section'
                                                   onClick={(_) =>
                                                      SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)
                                                   }
                                                >
                                                   {image && (
                                                      <img
                                                         className={
                                                            imageData.isRounded
                                                               ? 'template-quebec-img rounded-circle img-fluid'
                                                               : 'template-quebec-img img-fluid'
                                                         }
                                                         src={image}
                                                         alt='profile-img'
                                                      />
                                                   )}
                                                </section>
                                             )}
                                          </div>
                                       </div>
                                    </div>

                                    {stepsInformation.findIndex((x) => x.title == 'Experiences') > -1 && experiences.length > 0 ? (
                                       <section
                                          className='template-quebec-experience-sec border-bottom selectable-section mt-3 pb-4'
                                          onClick={(_) => SelectableSectionClicked('work-experience', navigate, onNavigatedToSection)}
                                       >
                                          <p className='template-quebec-heading text-uppercase fw-600'>{t('Experiences')}</p>

                                          {experiences.map((e, i) => (
                                             <div className='d-flex' key={'exp_' + i}>
                                                <div className='left quebec-color mt-3'>
                                                   {e.location && <p>{e.location}</p>}
                                                   <p className='fst-italic'>
                                                      {e.startDate || e.endDate ? (
                                                         <>
                                                            {e.startDate ? (
                                                               <span>
                                                                  {new Date(e.startDate).toLocaleString('default', { month: 'short' })}{' '}
                                                                  {new Date(e.startDate).getFullYear()}
                                                               </span>
                                                            ) : (
                                                               <></>
                                                            )}
                                                            {e.endDate && !e.currentlyWorking ? (
                                                               <span className='barline'>
                                                                  {new Date(e.endDate).toLocaleString('default', { month: 'short' })}{' '}
                                                                  {new Date(e.endDate).getFullYear()}
                                                               </span>
                                                            ) : (
                                                               <></>
                                                            )}

                                                            {e.currentlyWorking ? <em className='barline'>{t('present')} </em> : <></>}
                                                         </>
                                                      ) : (
                                                         <></>
                                                      )}
                                                   </p>
                                                </div>

                                                <div className='right mt-2 ps-3'>
                                                   <p className='fw-bold'>{e.title}</p>
                                                   {e.company && <p className='fst-italic'>{e.company}</p>}
                                                   {e.description && (
                                                      <p
                                                         className='mt-2 sec-value editor-list-margin-left'
                                                         dangerouslySetInnerHTML={{ __html: e.description }}
                                                      ></p>
                                                   )}
                                                </div>
                                             </div>
                                          ))}
                                       </section>
                                    ) : (
                                       <></>
                                    )}

                                    {stepsInformation.findIndex((x) => x.title == 'Education') > -1 && educations.length > 0 ? (
                                       <section
                                          className='template-quebec-education-sec border-bottom selectable-section mt-3 pb-4'
                                          onClick={(_) => SelectableSectionClicked('education', navigate, onNavigatedToSection)}
                                       >
                                          <p className='template-quebec-heading text-uppercase fw-600'>{t('Education')}</p>

                                          {educations.map((e, i) => (
                                             <div className='d-flex' key={'edu__' + i}>
                                                <div className='left quebec-color mt-3'>
                                                   {e.location && <p>{e.location}</p>}
                                                   <p className='fst-italic'>
                                                      {e.startDate || e.completeDate ? (
                                                         <>
                                                            {e.startDate ? (
                                                               <span>
                                                                  {new Date(e.startDate).toLocaleString('default', { month: 'short' })}{' '}
                                                                  {new Date(e.startDate).getFullYear()}
                                                               </span>
                                                            ) : (
                                                               <></>
                                                            )}
                                                            {e.completeDate && !e.currentlyStudying ? (
                                                               <span className='barline'>
                                                                  {new Date(e.completeDate).toLocaleString('default', { month: 'short' })}{' '}
                                                                  {new Date(e.completeDate).getFullYear()}
                                                               </span>
                                                            ) : (
                                                               <></>
                                                            )}

                                                            {e.currentlyStudying ? <em className='barline'>{t('present')} </em> : <></>}
                                                         </>
                                                      ) : (
                                                         <></>
                                                      )}
                                                   </p>
                                                </div>

                                                <div className='right mt-2 ps-3'>
                                                   <p className='fw-bold'>{e.degree}</p>
                                                   {e.institute && <p className='fst-italic'>{e.institute}</p>}
                                                   {e.description && (
                                                      <p
                                                         className='mt-2 sec-value editor-list-margin-left'
                                                         dangerouslySetInnerHTML={{ __html: e.description }}
                                                      ></p>
                                                   )}
                                                </div>
                                             </div>
                                          ))}
                                       </section>
                                    ) : (
                                       <></>
                                    )}

                                    {stepsInformation.findIndex((x) => x.title == 'Projects') > -1 && projects.length > 0 && (
                                       <section
                                          className='template-quebec-projects-sec border-bottom selectable-section mt-3 pb-4'
                                          onClick={(_) => SelectableSectionClicked('projects', navigate, onNavigatedToSection)}
                                       >
                                          <p className='template-quebec-heading text-uppercase fw-600'>{t('Projects')}</p>
                                          {projects.map((v, i) => (
                                             <p
                                                key={'projects__' + i}
                                                className='template-quebec-desc sec-value my-2 editor-list-margin-left'
                                                dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                             ></p>
                                          ))}
                                       </section>
                                    )}

                                    {stepsInformation.findIndex((x) => x.title == 'Achievements') > -1 && achievements.length > 0 && (
                                       <section
                                          className='template-quebec-achievements-sec border-bottom selectable-section mt-3 pb-4'
                                          onClick={(_) => SelectableSectionClicked('achievements', navigate, onNavigatedToSection)}
                                       >
                                          <p className='template-quebec-heading text-uppercase fw-600'>{t('Achievements')}</p>
                                          {achievements.map((v, i) => (
                                             <p
                                                key={'achievements__' + i}
                                                className='template-quebec-desc sec-value my-2 editor-list-margin-left'
                                                dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                             ></p>
                                          ))}
                                       </section>
                                    )}

                                    {stepsInformation.findIndex((x) => x.title == 'Certifications & Courses') > -1 &&
                                       certificates.length > 0 && (
                                          <section
                                             className='template-quebec-certifications-sec border-bottom selectable-section mt-3 pb-4'
                                             onClick={(_) =>
                                                SelectableSectionClicked('certifications-courses', navigate, onNavigatedToSection)
                                             }
                                          >
                                             <p className='template-quebec-heading text-uppercase fw-600'>
                                                {t('Certifications & Courses')}
                                             </p>
                                             {certificates.map((v, i) => (
                                                <p
                                                   key={'certificates__' + i}
                                                   className='template-quebec-desc sec-value my-2 editor-list-margin-left'
                                                   dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                                ></p>
                                             ))}
                                          </section>
                                       )}

                                    {stepsInformation.findIndex((x) => x.title == 'Honors & Awards') > -1 && honours.length > 0 && (
                                       <section
                                          className='template-quebec-awards-sec border-bottom selectable-section mt-3 pb-4'
                                          onClick={(_) => SelectableSectionClicked('honors-awards', navigate, onNavigatedToSection)}
                                       >
                                          <p className='template-quebec-heading text-uppercase fw-600'>{t('Honors & Awards')}</p>
                                          {honours.map((v, i) => (
                                             <p
                                                key={'honour__' + i}
                                                className='template-quebec-desc sec-value my-2 editor-list-margin-left'
                                                dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                             ></p>
                                          ))}
                                       </section>
                                    )}

                                    {stepsInformation.findIndex((x) => x.title == 'Publications') > -1 && publications.length > 0 && (
                                       <section
                                          className='template-quebec-publications-sec border-bottom selectable-section mt-3 pb-4'
                                          onClick={(_) => SelectableSectionClicked('publications', navigate, onNavigatedToSection)}
                                       >
                                          <p className='template-quebec-heading text-uppercase fw-600'>{t('Publications')}</p>
                                          {publications.map((v, i) => (
                                             <p
                                                key={'publication__' + i}
                                                className='template-quebec-desc sec-value my-2 editor-list-margin-left'
                                                dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                             ></p>
                                          ))}
                                       </section>
                                    )}

                                    {stepsInformation.findIndex((x) => x.title == 'References') > -1 && references.length > 0 && (
                                       <section
                                          className='template-quebec-references-sec border-bottom selectable-section mt-3 pb-4'
                                          onClick={(_) => SelectableSectionClicked('references', navigate, onNavigatedToSection)}
                                       >
                                          <p className='template-quebec-heading text-uppercase fw-600'>{t('References')}</p>
                                          {references.map((v, i) => (
                                             <div className='position-relative' key={'refer__' + i}>
                                                <div className='quebec-quotes-container position-absolute'>
                                                   <BiSolidQuoteSingleLeft className='quebec-quotes quebec-quote-left' />
                                                   <BiSolidQuoteSingleLeft className='quebec-quotes' />
                                                </div>
                                                <p
                                                   className='template-quebec-desc quebec-reference sec-value my-2 editor-list-margin-left'
                                                   key={'refer__' + i}
                                                >
                                                   <p>{v.name}</p>
                                                   <p>
                                                      {v.city} {v.city != '' && v.tel != '' ? '|' : ''} {v.tel}
                                                   </p>
                                                </p>
                                             </div>
                                          ))}
                                       </section>
                                    )}

                                    {stepsInformation.findIndex((x) => x.title == 'Custom sections') > -1 &&
                                       customSections.map((v, i) => (
                                          <section
                                             key={'custom__' + i}
                                             className='template-quebec-custom-sec border-bottom selectable-section mt-3 pb-4'
                                             onClick={(_) => SelectableSectionClicked('custom-sections', navigate, onNavigatedToSection)}
                                          >
                                             <p className='template-quebec-heading text-uppercase fw-600'>{v.title}</p>
                                             <p
                                                className='template-quebec-desc sec-value my-2 editor-list-margin-left'
                                                dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                             ></p>
                                          </section>
                                       ))}
                                 </div>
                              </div>

                              <div className='col-3'>
                                 <div className='template-quebec-right-col'>
                                    {Object.values(contactInformation).filter((s) => s).length > 0 && (
                                       <section
                                          className='template-quebec-contact-sec selectable-section'
                                          onClick={(_) => SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)}
                                       >
                                          <p className='template-quebec-heading text-uppercase fw-600 mb-4'>{t('Contact Information')}</p>
                                          <div className='mt-3'>
                                             <p className='fw-700'>{t('email')}</p>
                                             <a href={`mailto:${contactInformation.email}`} className='mt-1 text-decoration-none'>
                                                {contactInformation.email}
                                             </a>
                                          </div>
                                          <div className='mt-3'>
                                             <p className='fw-700'>{t('address')} 1</p>
                                             <p className='mt-1'>{contactInformation.address}</p>
                                          </div>
                                          <div className='mt-3'>
                                             <p className='fw-700'>{t('address')} 2</p>
                                             <p className='mt-1'>{contactInformation.address2}</p>
                                          </div>
                                          <div className='mt-3'>
                                             <p className='fw-700'>{ct('city')}</p>
                                             <p className='mt-1'>{contactInformation.city}</p>
                                          </div>
                                          <div className='mt-3'>
                                             <p className='fw-700'>Phone</p>
                                             <a href={`tel:${contactInformation.phone}`} className='mt-1 text-decoration-none'>
                                                {contactInformation.phone}
                                             </a>
                                          </div>
                                          <div className='mt-3'>
                                             <p className='fw-700'>Date of Birth</p>
                                             <p className='mt-1'>{moment(contactInformation.dob).format('MM/DD/YYYY')}</p>
                                          </div>
                                          <div className='mt-3'>
                                             <p className='fw-700'>Nationality</p>
                                             <p className='mt-1'>{contactInformation.nationality}</p>
                                          </div>
                                          <div className='mt-3'>
                                             <p className='fw-700'>Link</p>
                                             <a href={`https://${contactInformation.linkedInLink}`} className='mt-1 text-decoration-none'>
                                                {contactInformation.linkedInLink}
                                             </a>
                                          </div>
                                       </section>
                                    )}

                                    {stepsInformation.findIndex((x) => x.title == 'Skills') > -1 && (
                                       <section
                                          className='template-quebec-skills-sec selectable-section mt-4'
                                          onClick={(_) => SelectableSectionClicked('skills', navigate, onNavigatedToSection)}
                                       >
                                          <p className='template-quebec-heading text-uppercase fw-600 mb-4'>{t('Skills')}</p>
                                          <ul className='ms-3 lh-lg sec-value'>
                                             {skills.length > 0 &&
                                                skills.map((s, i) => <li key={'skill_' + s.name + s.id + i}>{s.name}</li>)}
                                          </ul>
                                       </section>
                                    )}

                                    {stepsInformation.findIndex((x) => x.title == 'Languages') > -1 && (
                                       <section
                                          className='template-quebec-languages-sec selectable-section mt-4'
                                          onClick={(_) => SelectableSectionClicked('languages', navigate, onNavigatedToSection)}
                                       >
                                          <p className='template-quebec-heading text-uppercase fw-600 mb-4'>{t('Languages')}</p>

                                          {languages.length > 0 &&
                                             languages.map((s, i) => (
                                                <div className='mb-2' key={'languages_' + s.name + s.id + i}>
                                                   {s.name && <p className='fw-700'>{s.name}</p>}
                                                   <p>
                                                      {s.level && <span>{s.level}</span>}. {s.course && <span>{s.course}</span>}
                                                   </p>
                                                </div>
                                             ))}
                                       </section>
                                    )}
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </WrapperDiv>
   )
}

const WrapperDiv = styled.div`
   & .sec-value,
   .sec-value * {
      line-height: ${(props: { additionalSettings: AdditionalSettings }) =>
      !!props?.additionalSettings?.lineHeight && props?.additionalSettings?.lineHeight};
      font-size: ${(props: { additionalSettings: AdditionalSettings }) =>
      !!props?.additionalSettings?.fontSize && props?.additionalSettings?.fontSize + ' !important'};
   }

   & .right::before {
      background-color: ${(props: { additionalSettings: AdditionalSettings }) =>
      !!props?.additionalSettings?.color && props.additionalSettings.color};
   }

   & .quebec-quotes {
      color: ${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.color && props.additionalSettings.color};
   }

   & .template-quebec-right-col {
      background-color: ${(props: { additionalSettings: AdditionalSettings }) =>
      !!props?.additionalSettings?.color && props.additionalSettings.color};
   }

   & * {
      font-family: ${(props: { additionalSettings: AdditionalSettings }) =>
      !!props?.additionalSettings?.fontFamily && props?.additionalSettings?.fontFamily === 'Classic'
         ? 'Noto Serif, serif;'
         : props?.additionalSettings?.fontFamily === 'Modern'
            ? 'Rubik, sans-serif;'
            : props?.additionalSettings?.fontFamily === 'Elegant'
               ? 'Libre Baskerville, serif;'
               : props?.additionalSettings?.fontFamily === 'Tech'
                  ? 'Roboto Mono, monospace;'
                  : props?.additionalSettings?.fontFamily === 'Professional'
                     ? 'Bitter, serif;'
                     : 'default;'};
   }
`
