import { useFormik } from "formik";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from 'yup';
import { config } from "../../../Environments";
import { GetAxios, KeyPairValue } from '../../../Shared';
import MainContent from "./MainContent";
import SideContent from "./SideContent";
import { useDispatch } from "react-redux";
import { setGlobalLoading } from "../../../Store";
import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import { useTranslation } from 'react-i18next';
import { confirmAlert } from "react-confirm-alert";

const Index = () => {

  const auth = useSelector((state: AppStore) => state.auth);
  const [submitting, setSubmitting] = useState(false);
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const [editorState, setEditState] = useState(EditorState.createEmpty());
  const { t: ts } = useTranslation('alert');
  const { t } = useTranslation('company');
  const profileschema = Yup.object().shape({
    firstName: Yup.string().required(ts('account.required') || 'Required'),
    lastName: Yup.string().required(ts('account.required') || 'Required'),
    email: Yup.string().email(ts('signIn.inValidEmail') || "Invalid email address").required(ts('account.required') || 'Required')
      .test(
        "not-yopmail",
        ts('account.yopmail') || "not allowed",
        (value) => !value || !value.endsWith("@yopmail.com")
      ),
    gender: Yup.string().required(ts('account.required') || 'Required'),
    dateOfBirth: Yup.string().required(ts('account.required') || 'Required'),
   // nationality: Yup.string().required(ts('account.required') || 'Required'),
    city: Yup.string().required(ts('account.required') || 'Required'),
    tel: Yup.string().required(ts('account.required') || 'Required'),
    nationality: Yup.string().required(ts('account.required') || 'Required'),
    zipCode: Yup.string().when("nationality", {
      is: "US",
      then: Yup.string().required(ts('account.required') || 'Required'),
      otherwise: Yup.string().notRequired(),
    }),
    state: Yup.number().when("nationality", {
      is: "US",
      then: Yup.number().min(1, ts("account.required") || "Required"),
      otherwise: Yup.number().notRequired(),
    }),
    maritalStatus: Yup.string().required(ts('account.required') || 'Required'),
    availability: Yup.string().required(ts('account.required') || 'Required'),
    lang: Yup.string().required(ts('account.required') || 'Required'),
    department: Yup.number().when("nationality", {
      is: "DO",
      then: Yup.number().min(1, ts("account.required") || "Required"),
      otherwise: Yup.number().notRequired(),
    }),
    //department: Yup.string().required(ts('account.required') || 'Required'),
    socialNetworks: Yup.array().of(
      Yup.object().shape({
        text: Yup.string()
          .matches(
            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            ts('error.inCorrectUrl') || 'Enter correct url'
          )
          .required('Required')
      })
    ),
    skills: Yup.array().of(
      Yup.object().shape({
        skill: Yup.string().required(ts('account.required') || 'Required'),
        description: Yup.string().required(ts('account.required') || 'Required'),
        range: Yup.number().required(ts('account.required') || 'Required')
      })
    )
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      gender: "",
      dateOfBirth: "",
      nationality: "",
     // residence: "",
      tel: "",
      email: "",
      //countryResidence: "",
      state: 0,
      city:"",
      zipCode: "",
      department: 0,
      maritalStatus: "",
      availability: "",
      socialNetworks: [{ text: "" }],
      aboutJob: '',
      language: [],
      skills: [{ skill: "", description: "", range: 50 }],
      additionalSkills: '',
      lang: i18n.language,

    },
    validationSchema: profileschema,
    onSubmit: values => {
      setSubmitting(true);
      const vals = {
        ...values,
        socialNetworks: JSON.stringify(values.socialNetworks),
        language: JSON.stringify(values.language)
      };
      if (auth.raw) {
        const token = 'Bearer ' + auth.raw.access_token;
        GetAxios().post(config.apiBase + '/api/Employee/SaveCandidateProfile', vals, {
          headers: {
            'Authorization': token
          }
        }).then(res => {
          toast(ts('account.profileUpdate'), { type: 'success' });
          setSubmitting(false);
        }).catch(err => {
          setSubmitting(false);

          toast(ts('error.wentWrong'), { type: 'error' });
          console.error(err);
        });
      }
    },
  });
  React.useEffect(() => {
    //if (auth?.raw) {
    dispatch(setGlobalLoading(true));
    GetAxios().get(config.apiBase + '/api/Employee/GetEditProfile')
      .then(res => {
        const vals = {
          ...res.data.data,

          socialNetworks: JSON.parse(JSON.parse(res.data.data.socialNetworks)),
          dateOfBirth: new Date(res.data.data.dateOfBirth),
          language: JSON.parse((res.data.data.language == "null" ? '[]' : res.data.data.language) || '[]')
        };
        formik.setValues(vals);
        console.log(res.data.data);
        if (res.data.data.aboutJob) {
          const html = convertFromHTML(res.data.data.aboutJob || '');
          setEditState(EditorState.createWithContent(ContentState.createFromBlockArray(html.contentBlocks, html.entityMap)))
        }
        dispatch(setGlobalLoading(false));
      });

    // }
  }, []);
  //}, [auth]);

  const confirmSubmit = () => {
    confirmAlert({
      title: t('save.areYouSure').toString(),
      message: t('save.sureSave').toString(),
      buttons: [
        {
          label: t('save.yes').toString(),
          onClick: () => {
            formik.handleSubmit();
          },
        },
        {
          label: t('save.no').toString(),
          onClick: () => { },
        },
      ],
    });
  }

  return (
    <>
      <div className="container">
        <div className="row py-50">
          <div className="col-md-4 col-sm-12 ps-lg-0">
            <SideContent formik={formik} />
          </div>
          <div className="col-md-8 col-sm-12 pe-lg-0">
            <MainContent formik={formik} confirmSubmit={confirmSubmit} submitting={submitting} editorState={editorState} setEditState={setEditState} />
          </div>
        </div>
      </div>
    </>
  );
};
export default Index;


