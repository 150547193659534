import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const Privacy = () => {

    const { t } = useTranslation('home');

    return (
        <>
            <div className="container">
                <div className="row py-50 mx-auto" style={{ overflowX: 'hidden' }}>
                    <div className="col-12 fontsize-privacy">
                        <div>
                            <h1 className="mb-3 mt-0 fw-600 title-privacy">{t('privacyPolicy.privacyPolicyTitle')}</h1>
                            <p><strong>{t('privacyPolicy.effectiveDate')}: </strong> {t('privacyPolicy.date')}</p>
                            <p>{t('privacyPolicy.asUsed')} <strong>“{t('privacyPolicy.bonjob')},” “{t('privacyPolicy.we')}” </strong>{t('privacyPolicy.and')} <strong> “{t('privacyPolicy.us')}”</strong> {t('privacyPolicy.subsidiaryText')} .</p>
                            <p>{t('privacyPolicy.committedProtecting')}.</p>
                            <p>{t('privacyPolicy.privacyLine1')}.</p>
                            <p>{t('privacyPolicy.privacyLine2')}.</p>
                            <p>{t('privacyPolicy.forPurposes')}:</p>
                            <ul className='ps-3'>
                                <li><p className='mb-0'><strong>“{t('privacyPolicy.user')}”</strong> {t('privacyPolicy.userDesc')}.</p>
                                </li>
                                <li><p><strong>“{t('privacyPolicy.website')}”</strong> {t('privacyPolicy.websiteDesc1')} <a target="_blank" href="/" className='text-decoration-none'>https://www.Curriculosi.com/</a> ), {t('privacyPolicy.websiteDesc2')}.</p>
                                </li>
                            </ul>
                            <p className='text-uppercase'>{t('privacyPolicy.privacyLine3')}.
                            </p>
                            <span className='fs-18'>{t('privacyPolicy.learnMoreAbout')}:</span>
                            <ol className='my-2'>
                                <li>
                                    <p className='text-uppercase mb-0'><strong>{t('privacyPolicy.learnMoreList1')}</strong></p>
                                </li>
                                <li>
                                    <p className='text-uppercase mb-0'><strong>{t('privacyPolicy.learnMoreList2')}</strong></p>
                                </li>
                                <li>
                                    <p className='text-uppercase mb-0'><strong>{t('privacyPolicy.learnMoreList3')}</strong></p>
                                </li>
                                <li>
                                    <p className='text-uppercase mb-0'><strong>{t('privacyPolicy.learnMoreList4')}</strong></p>
                                </li>
                                <li>
                                    <p className='text-uppercase mb-0'><strong>{t('privacyPolicy.learnMoreList5')}</strong></p>
                                </li>
                                <li>
                                    <p className='text-uppercase mb-0'><strong>{t('privacyPolicy.learnMoreList6')}</strong></p>
                                </li>
                                <li>
                                    <p className='text-uppercase mb-0'><strong>{t('privacyPolicy.learnMoreList7')}</strong></p>
                                </li>
                                <li>
                                    <p className='text-uppercase mb-0'><strong>{t('privacyPolicy.learnMoreList8')}</strong></p>
                                </li>
                                <li>
                                    <p className='text-uppercase mb-0'><strong>{t('privacyPolicy.learnMoreList9')}</strong></p>
                                </li>
                                <li>
                                    <p className='text-uppercase mb-0'><strong>{t('privacyPolicy.learnMoreList10')}</strong></p>
                                </li>
                                <li>
                                    <p className='text-uppercase mb-0'><strong>{t('privacyPolicy.learnMoreList11')}</strong></p>
                                </li>
                                <li>
                                    <p className='text-uppercase mb-0'><strong>{t('privacyPolicy.learnMoreList12')}</strong></p>
                                </li>
                            </ol>
                            <p className='fw-600 subtitle-privacy'>1. {t('privacyPolicy.howCollectInformation')}</p>
                            <p className='fs-20 fw-600'> {t('privacyPolicy.informationProvide')}</p>
                            <p>{t('privacyPolicy.informationProvideDesc1')}.</p>
                            <p>{t('privacyPolicy.informationProvideDesc2')}.</p>
                            <p>{t('privacyPolicy.informationProvideDesc3')}.</p>
                            <p className='fs-20 fw-600'>{t('privacyPolicy.informationGenerated')}</p>
                            <p>{t('privacyPolicy.informationGeneratedDesc1')}:</p>
                            <ul className='ps-3'>
                                <li><p className='mb-0'>{t('privacyPolicy.informationGeneratedDescList1')}</p>
                                </li>
                                <li><p className='mb-0'>{t('privacyPolicy.informationGeneratedDescList2')}</p>
                                </li>
                                <li><p className='mb-0'>{t('privacyPolicy.informationGeneratedDescList3')}<br /> {t('privacyPolicy.and')}</p>
                                </li>
                                <li><p className='mb-0'>{t('privacyPolicy.informationGeneratedDescList4')}</p>
                                </li>
                            </ul>
                            <p>{t('privacyPolicy.informationGeneratedDesc2')} <strong>{t('privacyPolicy.cookiesBeacons')}</strong> {t('privacyPolicy.below')}.</p>
                            <p className='fs-20 fw-600'>{t('privacyPolicy.informationSources')}</p>
                            <p>{t('privacyPolicy.informationSourcesDesc1')}.</p>
                            <p>{t('privacyPolicy.informationSourcesDesc2')}.</p>
                            <p className='fw-600 subtitle-privacy'>2. {t('privacyPolicy.2howInformationUsed')}</p>
                            <p className='fs-20 fw-600'>{t('privacyPolicy.personallyIdentifiable')}</p>
                            <p>{t('privacyPolicy.howInformationUsedDesc1')} <strong>
                                (“{t('privacyPolicy.personallyIdentifiable')}”)</strong>.  {t('privacyPolicy.howInformationUsedDesc2')}:
                            </p>
                            <ul className='ps-3'>
                                <li><p className='mb-0'>{t('privacyPolicy.howInformationUsedList1')}</p>
                                </li>
                                <li><p className='mb-0'>{t('privacyPolicy.howInformationUsedList2')}</p>
                                </li>
                                <li><p className='mb-0'> {t('privacyPolicy.howInformationUsedList3')}
                                </p>
                                </li>
                                <li><p className='mb-0'>{t('privacyPolicy.howInformationUsedList4')}</p>
                                </li>
                            </ul>
                            <p>{t('privacyPolicy.dataDerived')} <strong> {t('privacyPolicy.nonPersonally')}</strong> {t('privacyPolicy.unlessCombined')}.</p>
                            <p>{t('privacyPolicy.personallyIdentifiableCollect')}:</p>
                            <ul className='ps-3'>
                                <li><p className='mb-0'>{t('privacyPolicy.personallyIdentifyList1')}</p>
                                </li>
                                <li><p className='mb-0'>{t('privacyPolicy.personallyIdentifyList2')}</p>
                                </li>
                                <li><p className='mb-0'>{t('privacyPolicy.personallyIdentifyList2')}</p>
                                </li>
                                <li><p className='mb-0'>{t('privacyPolicy.personallyIdentifyList3')}</p>
                                </li>
                                <li><p className='mb-0'>{t('privacyPolicy.personallyIdentifyList4')}</p>
                                </li>
                                <li><p className='mb-0'>{t('privacyPolicy.personallyIdentifyList5')}</p>
                                </li>
                                <li><p className='mb-0'>{t('privacyPolicy.personallyIdentifyList6')}</p>
                                </li>
                                <li><p className='mb-0'>{t('privacyPolicy.personallyIdentifyList7')}</p>
                                </li>
                                <li><p className='mb-0'>{t('privacyPolicy.personallyIdentifyList8')}</p>
                                </li>
                                <li><p className='mb-0'>{t('privacyPolicy.personallyIdentifyList9')}</p>
                                </li>
                                <li><p className='mb-0'>{t('privacyPolicy.personallyIdentifyList10')}</p>
                                </li>
                                <li><p className='mb-0'>{t('privacyPolicy.personallyIdentifyList11')}</p>
                                </li>
                                <li><p className='mb-0'>{t('privacyPolicy.personallyIdentifyList12')}</p>
                                </li>
                                <li><p className='mb-0'>{t('privacyPolicy.personallyIdentifyList13')}</p>
                                </li>
                                <li><p className='mb-0'>{t('privacyPolicy.personallyIdentifyList14')}</p>
                                </li>
                                <li><p className='mb-0'>{t('privacyPolicy.personallyIdentifyList15')}</p>
                                </li>
                                <li><p className='mb-0'>{t('privacyPolicy.personallyIdentifyList16')}</p>
                                </li>
                            </ul>
                            <p className='fs-20 fw-600'>{t('privacyPolicy.nonPersonally')}</p>
                            <p>{t('privacyPolicy.nonPersonallyDesc1')}</p>
                            <p>{t('privacyPolicy.nonPersonallyDesc2')}.</p>
                            <p className='fw-600 subtitle-privacy'>3. {t('privacyPolicy.3howInformation')}</p>
                            <p>{t('privacyPolicy.personallyIdentifiable')}</p>
                            <p>{t('privacyPolicy.notSellShare')}.</p>
                            <p>{t('privacyPolicy.sharePersonally')}</p>
                            <ul className='ps-3'>
                                <li><p className='mb-0'>{t('privacyPolicy.sharePersonallyList1')}</p>
                                </li>
                                <li><p className='mb-0'>{t('privacyPolicy.sharePersonallyList2')}</p>
                                </li>
                                <li><p className='mb-0'>{t('privacyPolicy.sharePersonallyList4')}</p>
                                </li>
                                <li><p className='mb-0'>{t('privacyPolicy.sharePersonallyList5')}</p>
                                </li>
                                <li><p className='mb-0'>{t('privacyPolicy.sharePersonallyList6')}</p>
                                </li>
                                <li><p className='mb-0'>{t('privacyPolicy.sharePersonallyList7')}</p>
                                </li>
                                <li><p className='mb-0'>{t('privacyPolicy.sharePersonallyList8')}</p>
                                </li>
                            </ul>
                            <p>{t('privacyPolicy.sharePersonallyList9')}.</p>
                            <p>{t('privacyPolicy.sharePersonallyList10')}:</p>
                            <ul className='ps-3'>
                                <li><p className='mb-0'>{t('privacyPolicy.sharePersonallyList11')}</p>
                                </li>
                                <li><p className='mb-0'>{t('privacyPolicy.sharePersonallyList12')}</p>
                                </li>
                                <li><p className='mb-0'>{t('privacyPolicy.sharePersonallyList13')}</p>
                                </li>
                                <li><p className='mb-0'>{t('privacyPolicy.sharePersonallyList14')}</p>
                                </li>
                                <li><p className='mb-0'>{t('privacyPolicy.sharePersonallyList15')}</p>
                                </li>
                            </ul>
                            <p>{t('privacyPolicy.sharePersonallyList16')}.</p>
                            <p className='fs-20 fw-600'>{t('privacyPolicy.sharePersonallyListTitle17')}</p>
                            <p>{t('privacyPolicy.sharePersonallyList18')}</p>
                            <ul className='ps-3'>
                                <li><p className='mb-0'>{t('privacyPolicy.sharePersonallyList19')}</p>
                                </li>
                                <li><p className='mb-0'>{t('privacyPolicy.sharePersonallyList20')}</p>
                                </li>
                                <li><p className='mb-0'> {t('privacyPolicy.sharePersonallyList21')}</p>
                                </li>
                            </ul>
                            <p className='fw-600 subtitle-privacy'>4. {t('privacyPolicy.cookiesBeacons')}</p>
                            <p>{t('privacyPolicy.cookiesBeaconsDesc1')}.
                            </p>
                            <ul className='ps-3'>
                                <li><p><strong>{t('privacyPolicy.cookiesBeacons')}.</strong>  {t('privacyPolicy.browserCookiesDesc1')}. <br />
                                    {t('privacyPolicy.browserCookiesDesc2')}<br />
                                    {t('privacyPolicy.browserCookiesDesc3')}<br />
                                    {t('privacyPolicy.browserCookiesDesc4')} <a target="_blank" href="http://www.allaboutcookies.org" className='text-decoration-none'>http://www.allaboutcookies.org.</a>
                                </p>
                                </li>
                                <li><p className='mb-0'><strong>{t('privacyPolicy.flashCookies')}.  </strong> {t('privacyPolicy.flashCookiesDesc')}.
                                </p>
                                </li>
                                <li><p className='mb-0'> <strong>Beacons. </strong>Our Website and e-mails may contain small electronic files known as beacons (also referred to as web beacons, clear GIFs, pixel tags and single-pixel GIFs) that permit us to, for example, count Users who have visited those pages or opened an e-mail and for other website-related statistics.  Beacons in e-mail marketing campaigns allow us to track your responses and your interests in our content, offerings and web pages. You may use the tools in your device to disable these technologies as well.</p>
                                </li>
                            </ul>
                            <p>{t('privacyPolicy.beacons')}. <strong> (“{t('privacyPolicy.google')}“)</strong> {t('privacyPolicy.cookiesBeaconsDesc2')} <a target="_blank" href="https://www.google.com/policies/privacy/partners/" className='text-decoration-none'> www.google.com/policies/privacy/partners/</a>, {t('privacyPolicy.cookiesBeaconsDesc3')}<a target="_blank" href="https://www.google.com/analytics/terms/us.html" className='text-decoration-none'>  https://www.google.com/analytics/terms/us.html.</a>
                                {t('privacyPolicy.cookiesBeaconsDesc4')} <a target="_blank" href="https://tools.google.com/dlpage/gaoptout/" className='text-decoration-none'>https://tools.google.com/dlpage/gaoptout/.</a>
                            </p>
                            <p className='fw-600 subtitle-privacy'>5. {t('privacyPolicy.yourChoices')}</p>
                            <p>{t('privacyPolicy.yourChoicesSubtitle')}.</p>
                            <p className='fw-600'>{t('privacyPolicy.emailCommunications')}</p>
                            <p>{t('privacyPolicy.emailCommunicationsDesc')}.
                            </p>
                            <p className='fw-600'> {t('privacyPolicy.cookies/Beacons')}</p>
                            <p>{t('privacyPolicy.cookies/BeaconsDesc')}.</p>
                            <p className='fw-600'> {t('privacyPolicy.networkAdvertisingInitiative')}</p>
                            <p>{t('privacyPolicy.networkAdvertisingInitiativeDesc1')} <a target="_blank" href="http://www.aboutads.info" className='text-decoration-none'>http://www.aboutads.info.</a>
                            </p>
                            <p>{t('privacyPolicy.networkAdvertisingInitiativeDesc2')}.
                            </p>
                            <p className='fw-600'>Do Not Track{t('privacyPolicy.doNotTrack')}</p>
                            <p>{t('privacyPolicy.doNotTrackDesc1')}. </p>
                            <p>{t('privacyPolicy.doNotTrackDesc2')}.</p>
                            <p className='fw-600'> {t('privacyPolicy.updatingDeletingInformation')}</p>
                            <p>{t('privacyPolicy.updatingDeletingInformationDesc')} <a target="_blank" href="https://mail.google.com/mail/?view=cm&fs=1&to=privacy@Curriculosi.com" className='text-decoration-none'>privacy@Curriculosi.com</a></p>
                            <div>
                                <p className='fw-600 subtitle-privacy'>6. {t('privacyPolicy.securingInformation')}</p>
                                <p>{t('privacyPolicy.securingInformationDesc')}.
                                </p>
                            </div>
                            <div>
                                <p className='fw-600 subtitle-privacy'>7. {t('privacyPolicy.children')}</p>
                                <p>{t('privacyPolicy.childrenDesc')}.
                                </p>
                            </div>
                            <div>
                                <p className='fw-600 subtitle-privacy'>8. {t('privacyPolicy.thirdPartyWebsites')}</p>
                                <p>{t('privacyPolicy.thirdPartyWebsitesDesc')}.
                                </p>
                            </div>
                            <div>
                                <p className='fw-600 subtitle-privacy'>9. {t('privacyPolicy.interpretation')}</p>
                                <p>{t('privacyPolicy.interpretationDesc1')} <a target="_blank" href="/terms-of-use" className='text-decoration-none'>Website Terms of Use</a>  {t('privacyPolicy.interpretationDesc2')}.
                                </p>
                            </div>
                            <div>
                                <p className='fw-600 subtitle-privacy'>10. {t('privacyPolicy.changesPrivacyPolicy')}</p>
                                <p>{t('privacyPolicy.changesPrivacyPolicyDesc1')}.
                                </p>
                                <p>{t('privacyPolicy.changesPrivacyPolicyDesc2')}.</p>
                                <p>{t('privacyPolicy.changesPrivacyPolicyDesc3')}.</p>
                            </div>
                            <div>
                                <p className='fw-600 subtitle-privacy'>11. {t('privacyPolicy.contactUs')}</p>
                                <p>{t('privacyPolicy.contactUsDesc')} <br /> {t('privacyPolicy.at')} <a target="_blank" href="https://mail.google.com/mail/?view=cm&fs=1&to=privacy@Curriculosi.com" className='text-decoration-none'>privacy@Curriculosi.com</a>
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Privacy;