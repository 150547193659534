import React, { useState } from 'react';
import moment from 'moment';
import { DefaultProfileImage, SelectableSectionClicked } from '../SharedData';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const Vienna = (props: TemplateComponentProps) => {
   const { t } = useTranslation('resume');
   const { t: ct } = useTranslation('common');

   const {
      navigate,
      contactInformation,
      image,
      imageData,
      objectives,
      experiences,
      educations,
      languages,
      skills,
      achievements,
      certificates,
      customSections,
      honours,
      projects,
      publications,
      references,
      additionalSettings,
      stepsInformation,
      onNavigatedToSection,
   } = props

   return (
      <WrapperDiv additionalSettings={additionalSettings}>
         <div className='template'>
            <div className='preview-template'>
               <div id='template-full'>
                  <div className='template-main'>
                     <div className='vienna-template-container'>
                        <div className='container-fluid'>
                           <div className='row template-vienna-header'>
                              <div className='col-4 p-24px border-r-vienna d-flex justify-content-center align-items-center'>
                                 {Object.values(contactInformation).filter((s) => s).length > 0 && (
                                    <section
                                       className='d-flex justify-content-center selectable-section'
                                       onClick={(_) => SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)}
                                    >
                                       {image && (
                                          <img
                                             className={
                                                imageData.isRounded
                                                   ? 'template-vienna-img rounded-circle img-fluid object-fit-cover'
                                                   : 'template-vienna-img img-fluid object-fit-cover'
                                             }
                                             src={image}
                                             alt='applicant profile'
                                             onError={(e) => {
                                                if (e.target instanceof HTMLImageElement) {
                                                   e.target.src = DefaultProfileImage
                                                }
                                             }}
                                          />
                                       )}
                                    </section>
                                 )}
                              </div>

                              <div className='col-8 template-vienna-info-sec'>
                                 <div>
                                    {(contactInformation.firstName || contactInformation.lastName || contactInformation.occupation) && (
                                       <section
                                          className='selectable-section'
                                          onClick={(_) => SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)}
                                       >
                                          <h2 className='template-vienna-name text-uppercase'>
                                             <span>{contactInformation.firstName}</span> <span>{contactInformation.lastName}</span>
                                          </h2>
                                          <p className='template-vienna-occupation fw-600'>{contactInformation.occupation}</p>
                                       </section>
                                    )}

                                    {Object.values(contactInformation).filter((s) => s).length > 0 && (
                                       <section
                                          className='template-vienna-custom-info d-flex selectable-section'
                                          onClick={(_) => SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)}
                                       >
                                          <div>
                                             <p>{contactInformation.address}</p>
                                             <p>{contactInformation.address2}</p>
                                             <a className='text-decoration-none' href={`tel:${contactInformation.phone}`}>
                                                {contactInformation.phone}
                                             </a>
                                             <p>{contactInformation.nationality}</p>
                                          </div>

                                          <div>
                                             <a className='text-decoration-none word-break' href={`mailto:${contactInformation.email}`}>
                                                {contactInformation.email}
                                             </a>
                                             <p>{contactInformation.city}</p>
                                             <p>{moment(contactInformation.dob).format('MM/DD/YYYY')}</p>
                                             <a
                                                className='word-break text-decoration-none'
                                                href={`https://${contactInformation.linkedInLink}`}
                                             >
                                                {contactInformation.linkedInLink}
                                             </a>
                                          </div>
                                       </section>
                                    )}
                                 </div>
                              </div>
                           </div>

                           <div className='row'>
                              <div className='col-4 border-r-vienna'>
                                 <div className='template-vienna-left-col'>
                                    {stepsInformation.findIndex((x) => x.title == 'Objective') > -1 && objectives && (
                                       <section
                                          className='template-vienna-objective-sec mt-5 selectable-section position-relative'
                                          onClick={(_) => SelectableSectionClicked('objective', navigate, onNavigatedToSection)}
                                       >
                                          <div
                                             className='fs-15px objective bg-transparent sec-value editor-list-margin-left'
                                             dangerouslySetInnerHTML={{ __html: objectives }}
                                          ></div>
                                          <div className='double-quotes'>{quoteSvg}</div>
                                       </section>
                                    )}

                                    {stepsInformation.findIndex((x) => x.title == 'Skills') > -1 && (
                                       <section
                                          className='template-vienna-skills-sec pt-40px selectable-section'
                                          onClick={(_) => SelectableSectionClicked('skills', navigate, onNavigatedToSection)}
                                       >
                                          <div className='d-flex justify-content-center mb-24px'>
                                             <p className='text-uppercase template-vienna-heading'>{t('Skills')}</p>
                                          </div>
                                          <ul className='d-flex flex-column ps-4 fs-15px template-vienna-ul sec-value'>
                                             {skills.length > 0 &&
                                                skills.map((s, i) => <li key={'skill_' + s.name + s.id + i}>{s.name}</li>)}
                                          </ul>
                                       </section>
                                    )}

                                    {stepsInformation.findIndex((x) => x.title == 'Languages') > -1 && (
                                       <section
                                          className='template-vienna-languages-sec pt-40px selectable-section'
                                          onClick={(_) => SelectableSectionClicked('languages', navigate, onNavigatedToSection)}
                                       >
                                          <div className='d-flex justify-content-center mb-24px'>
                                             <p className='text-uppercase template-vienna-heading'>{t('Languages')}</p>
                                          </div>
                                          <ul className='d-flex flex-column ps-4 fs-15px template-vienna-ul'>
                                             {languages.length > 0 &&
                                                languages.map((s, i) => (
                                                   <li key={'languages_' + s.name + s.id + i}>
                                                      {s.name && <span>{s.name}</span>}
                                                      {s.level && <span className='barline template-vienna-desc'>{s.level}</span>}
                                                      {s.course && (
                                                         <span className='d-inline-block template-vienna-desc'>- {s.course}</span>
                                                      )}
                                                   </li>
                                                ))}
                                          </ul>
                                       </section>
                                    )}
                                 </div>
                              </div>

                              <div className='col-8'>
                                 <div className='template-vienna-right-col'>
                                    {stepsInformation.findIndex((x) => x.title == 'Experiences') > -1 && experiences.length > 0 ? (
                                       <section
                                          className='template-vienna-experience-sec selectable-section'
                                          onClick={(_) => SelectableSectionClicked('work-experience', navigate, onNavigatedToSection)}
                                       >
                                          <div className='mb-24px'>
                                             <p className='text-uppercase template-vienna-heading d-inline-block'>{t('Experiences')}</p>
                                          </div>

                                          {experiences.map((e, i) => (
                                             <div className='mb-3' key={'exp_' + i}>
                                                <div className='d-flex justify-content-between align-items-center vienna-title-font'>
                                                   <p className='fw-700 text-uppercase'>{e.title}</p>
                                                   <p className='fst-italic'>
                                                      {e.startDate || e.endDate ? (
                                                         <>
                                                            {e.startDate ? (
                                                               <span>
                                                                  {new Date(e.startDate).toLocaleString('default', { month: 'short' })}{' '}
                                                                  {new Date(e.startDate).getFullYear()}
                                                               </span>
                                                            ) : (
                                                               <></>
                                                            )}
                                                            {e.endDate && !e.currentlyWorking ? (
                                                               <span className='barline'>
                                                                  {new Date(e.endDate).toLocaleString('default', { month: 'short' })}{' '}
                                                                  {new Date(e.endDate).getFullYear()}
                                                               </span>
                                                            ) : (
                                                               <></>
                                                            )}

                                                            {e.currentlyWorking ? <em className='barline'>{t('present')} </em> : <></>}
                                                         </>
                                                      ) : (
                                                         <></>
                                                      )}
                                                   </p>
                                                </div>
                                                <p>
                                                   {e.company && <span>{e.company}</span>}
                                                   {e.location && <span className='vertical-linebar'>{e.location}</span>}
                                                </p>

                                                {e.description && (
                                                   <p
                                                      dangerouslySetInnerHTML={{ __html: e.description }}
                                                      className='template-vienna-desc mt-3 sec-value editor-list-margin-left'
                                                   ></p>
                                                )}
                                             </div>
                                          ))}
                                       </section>
                                    ) : (
                                       <></>
                                    )}

                                    {stepsInformation.findIndex((x) => x.title == 'Education') > -1 && educations.length > 0 ? (
                                       <section
                                          className='template-vienna-education-sec selectable-section'
                                          onClick={(_) => SelectableSectionClicked('education', navigate, onNavigatedToSection)}
                                       >
                                          <div className='mb-24px'>
                                             <p className='text-uppercase template-vienna-heading d-inline-block'>{t('Education')}</p>
                                          </div>

                                          {educations.map((e, i) => (
                                             <div className='mb-3' key={'edu__' + i}>
                                                <div className='d-flex justify-content-between align-items-center vienna-title-font'>
                                                   <p className='fw-700 text-uppercase'>{e.degree}</p>
                                                   <p className='fst-italic'>
                                                      {e.startDate || e.completeDate ? (
                                                         <>
                                                            {e.startDate ? (
                                                               <span>
                                                                  {new Date(e.startDate).toLocaleString('default', { month: 'short' })}{' '}
                                                                  {new Date(e.startDate).getFullYear()}
                                                               </span>
                                                            ) : (
                                                               <></>
                                                            )}
                                                            {e.completeDate && !e.currentlyStudying ? (
                                                               <span className='barline'>
                                                                  {new Date(e.completeDate).toLocaleString('default', { month: 'short' })}{' '}
                                                                  {new Date(e.completeDate).getFullYear()}
                                                               </span>
                                                            ) : (
                                                               <></>
                                                            )}

                                                            {e.currentlyStudying ? <em className='barline'>{t('present')} </em> : <></>}
                                                         </>
                                                      ) : (
                                                         <></>
                                                      )}
                                                   </p>
                                                </div>

                                                <p>
                                                   {e.institute && <span>{e.institute}</span>}
                                                   {e.location && <span className='vertical-linebar'>{e.location}</span>}
                                                </p>

                                                {e.description && (
                                                   <p
                                                      dangerouslySetInnerHTML={{ __html: e.description }}
                                                      className='template-vienna-desc mt-3 sec-value editor-list-margin-left'
                                                   ></p>
                                                )}
                                             </div>
                                          ))}
                                       </section>
                                    ) : (
                                       <></>
                                    )}

                                    {stepsInformation.findIndex((x) => x.title == 'Projects') > -1 && projects.length > 0 && (
                                       <section
                                          className='template-vienna-projects-sec mt-40px selectable-section'
                                          onClick={(_) => SelectableSectionClicked('projects', navigate, onNavigatedToSection)}
                                       >
                                          <div className='mb-24px'>
                                             <p className='text-uppercase template-vienna-heading d-inline-block'>{t('Projects')}</p>
                                          </div>
                                          {projects.map((v, i) => (
                                             <div key={'projects__' + i}>
                                                <p
                                                   className='template-vienna-desc mt-3 sec-value my-2 editor-list-margin-left'
                                                   dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                                ></p>
                                             </div>
                                          ))}
                                       </section>
                                    )}

                                    {stepsInformation.findIndex((x) => x.title == 'Achievements') > -1 && achievements.length > 0 && (
                                       <section
                                          className='template-vienna-achievements-sec mt-40px selectable-section'
                                          onClick={(_) => SelectableSectionClicked('achievements', navigate, onNavigatedToSection)}
                                       >
                                          <div className='mb-24px'>
                                             <p className='text-uppercase template-vienna-heading d-inline-block'>{t('Achievements')}</p>
                                          </div>
                                          {achievements.map((v, i) => (
                                             <div key={'achievements__' + i}>
                                                <p
                                                   className='template-vienna-desc mt-3 sec-value my-2 editor-list-margin-left'
                                                   dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                                ></p>
                                             </div>
                                          ))}
                                       </section>
                                    )}

                                    {stepsInformation.findIndex((x) => x.title == 'Certifications & Courses') > -1 &&
                                       certificates.length > 0 && (
                                          <section
                                             className='template-vienna-certifications-sec mt-40px selectable-section'
                                             onClick={(_) =>
                                                SelectableSectionClicked('certifications-courses', navigate, onNavigatedToSection)
                                             }
                                          >
                                             <div className='mb-24px'>
                                                <p className='text-uppercase template-vienna-heading d-inline-block'>
                                                   {t('Certifications & Courses')}
                                                </p>
                                             </div>
                                             {certificates.map((v, i) => (
                                                <div key={'certificates__' + i}>
                                                   <p
                                                      className='template-vienna-desc mt-3 sec-value my-2 editor-list-margin-left'
                                                      dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                                   ></p>
                                                </div>
                                             ))}
                                          </section>
                                       )}

                                    {stepsInformation.findIndex((x) => x.title == 'Honors & Awards') > -1 && honours.length > 0 && (
                                       <section
                                          className='template-vienna-awards-sec mt-40px selectable-section'
                                          onClick={(_) => SelectableSectionClicked('honors-awards', navigate, onNavigatedToSection)}
                                       >
                                          <div className='mb-24px'>
                                             <p className='text-uppercase template-vienna-heading d-inline-block'>{t('Honors & Awards')}</p>
                                          </div>
                                          {honours.map((v, i) => (
                                             <div key={'honour__' + i}>
                                                <p
                                                   className='template-vienna-desc mt-3 sec-value my-2 editor-list-margin-left'
                                                   dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                                ></p>
                                             </div>
                                          ))}
                                       </section>
                                    )}

                                    {stepsInformation.findIndex((x) => x.title == 'Publications') > -1 && publications.length > 0 && (
                                       <section
                                          className='template-vienna-publications-sec mt-40px selectable-section'
                                          onClick={(_) => SelectableSectionClicked('publications', navigate, onNavigatedToSection)}
                                       >
                                          <div className='mb-24px'>
                                             <p className='text-uppercase template-vienna-heading d-inline-block'>{t('Publications')}</p>
                                          </div>
                                          {publications.map((v, i) => (
                                             <div key={'publication__' + i}>
                                                <p
                                                   className='template-vienna-desc mt-3 sec-value my-2 editor-list-margin-left'
                                                   dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                                ></p>
                                             </div>
                                          ))}
                                       </section>
                                    )}

                                    {stepsInformation.findIndex((x) => x.title == 'References') > -1 && references.length > 0 && (
                                       <section
                                          className='template-references-awards-sec mt-40px selectable-section'
                                          onClick={(_) => SelectableSectionClicked('references', navigate, onNavigatedToSection)}
                                       >
                                          <div className='mb-24px'>
                                             <p className='text-uppercase template-vienna-heading d-inline-block'>{t('References')}</p>
                                          </div>
                                          {references.map((v, i) => (
                                             <div
                                                key={'refer__' + i}
                                                className='template-vienna-desc sec-value my-2 editor-list-margin-left'
                                             >
                                                <p>{v.name}</p>
                                                <p>
                                                   {v.city} {v.city != '' && v.tel != '' ? '|' : ''} {v.tel}
                                                </p>
                                             </div>
                                          ))}
                                       </section>
                                    )}

                                    {stepsInformation.findIndex((x) => x.title == 'Custom sections') > -1 &&
                                       customSections.map((v, i) => (
                                          <section
                                             key={'custom_' + i}
                                             className='template-vienna-custom-sec mt-40px selectable-section'
                                             onClick={(_) => SelectableSectionClicked('custom-sections', navigate, onNavigatedToSection)}
                                          >
                                             <div className='mb-24px'>
                                                <p className='text-uppercase template-vienna-heading d-inline-block'>{v.title}</p>
                                             </div>
                                             <p
                                                className='template-vienna-desc mt-3 sec-value my-2 editor-list-margin-left'
                                                dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                             ></p>
                                          </section>
                                       ))}
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </WrapperDiv>
   )
}

const WrapperDiv = styled.div`
   & .template-vienna-header {
      background-color: ${(props: { additionalSettings: AdditionalSettings }) =>
      !!props?.additionalSettings?.color && props.additionalSettings.color};
   }

   & .quote {
      fill: ${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.color && props.additionalSettings.color};
   }

   & .sec-value,
   .sec-value * {
      line-height: ${(props: { additionalSettings: AdditionalSettings }) =>
      !!props?.additionalSettings?.lineHeight && props?.additionalSettings?.lineHeight};
      font-size: ${(props: { additionalSettings: AdditionalSettings }) =>
      !!props?.additionalSettings?.fontSize && props?.additionalSettings?.fontSize + ' !important'};
   }

   & .template-vienna-ul {
      font-size: ${(props: { additionalSettings: AdditionalSettings }) =>
      !!props?.additionalSettings?.fontSize && props?.additionalSettings?.fontSize + ' !important'};
   }

   & .vienna-title-font {
      font-size: ${(props: { additionalSettings: AdditionalSettings }) =>
      !!props?.additionalSettings?.fontSize && props?.additionalSettings?.fontSize + ' !important'};
   }

   & * {
      font-family: ${(props: { additionalSettings: AdditionalSettings }) =>
      !!props?.additionalSettings?.fontFamily && props?.additionalSettings?.fontFamily === 'Classic'
         ? 'Noto Serif, serif;'
         : props?.additionalSettings?.fontFamily === 'Modern'
            ? 'Rubik, sans-serif;'
            : props?.additionalSettings?.fontFamily === 'Elegant'
               ? 'Libre Baskerville, serif;'
               : props?.additionalSettings?.fontFamily === 'Tech'
                  ? 'Roboto Mono, monospace;'
                  : props?.additionalSettings?.fontFamily === 'Professional'
                     ? 'Bitter, serif;'
                     : 'default;'};
   }
`

const quoteSvg = (
   <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='100%'
      viewBox='0 0 73 62'
      enableBackground='new 0 0 73 62'
   >
      <path
         fill='none'
         opacity='1.000000'
         stroke='none'
         d='M36.000000,1.000000 
            C45.354229,1.000000 54.708454,1.000000 64.389481,1.338781 
            C54.827740,4.664383 48.297077,11.682373 43.411465,20.243610 
            C37.668289,30.307592 37.729336,41.230801 40.545723,52.100319 
            C41.919762,57.403278 46.549133,60.281078 51.000000,63.000000 
            C42.312439,63.000000 33.624874,63.000000 24.596565,62.669006 
            C31.361744,59.601055 31.461191,54.819355 30.942648,49.784069 
            C30.433819,44.843094 26.469364,43.089962 22.457548,42.099567 
            C16.135757,40.538918 13.165178,37.088871 12.971292,30.620117 
            C12.664199,20.374393 19.932127,10.035181 31.190538,7.593081 
            C35.621021,6.632050 36.349091,4.623645 36.000000,1.000000 z'
      />
      <path
         className='quote'
         opacity='1.000000'
         stroke='none'
         d='M51.458069,63.000000 
            C46.549133,60.281078 41.919762,57.403278 40.545723,52.100319 
            C37.729336,41.230801 37.668289,30.307592 43.411465,20.243610 
            C48.297077,11.682373 54.827740,4.664383 64.858139,1.338781 
            C67.888908,1.000000 70.777809,1.000000 74.000000,1.000000 
            C74.000000,2.326223 74.000000,3.663149 73.654266,5.514091 
            C72.597214,6.533445 71.929199,7.398190 71.168053,7.489888 
            C60.750000,8.744967 54.920895,14.984355 51.788864,24.590582 
            C49.450851,31.761465 51.679256,41.303989 61.180355,42.267593 
            C66.692741,42.826656 68.717476,46.789394 69.080208,52.490372 
            C69.434097,58.052422 65.696297,60.406765 62.000000,63.000000 
            C58.638714,63.000000 55.277424,63.000000 51.458069,63.000000 z'
      />
      <path
         className='quote'
         opacity='1.000000'
         stroke='none'
         d='M35.550018,1.000000 
            C36.349091,4.623645 35.621021,6.632050 31.190538,7.593081 
            C19.932127,10.035181 12.664199,20.374393 12.971292,30.620117 
            C13.165178,37.088871 16.135757,40.538918 22.457548,42.099567 
            C26.469364,43.089962 30.433819,44.843094 30.942648,49.784069 
            C31.461191,54.819355 31.361744,59.601055 24.127911,62.669006 
            C20.638712,63.000000 17.277426,63.000000 13.326134,62.672058 
            C5.788144,60.046703 3.379049,54.035645 1.000000,48.000000 
            C1.000000,42.645073 1.000000,37.290142 1.350597,31.307844 
            C1.986928,29.415197 2.144288,28.103966 2.578244,26.891748 
            C6.939816,14.708074 15.116206,6.099933 27.000000,1.000000 
            C29.700014,1.000000 32.400028,1.000000 35.550018,1.000000 z'
      />
      <path
         fill='none'
         opacity='1.000000'
         stroke='none'
         d='M62.479176,63.000000 
            C65.696297,60.406765 69.434097,58.052422 69.080208,52.490372 
            C68.717476,46.789394 66.692741,42.826656 61.180355,42.267593 
            C51.679256,41.303989 49.450851,31.761465 51.788864,24.590582 
            C54.920895,14.984355 60.750000,8.744967 71.168053,7.489888 
            C71.929199,7.398190 72.597214,6.533445 73.654266,6.014053 
            C74.000000,25.000069 74.000000,44.000137 74.000000,63.000000 
            C70.242615,63.000000 66.600487,63.000000 62.479176,63.000000 z'
      />
      <path
         fill='none'
         opacity='1.000000'
         stroke='none'
         d='M26.531334,1.000000 
            C15.116206,6.099933 6.939816,14.708074 2.578244,26.891748 
            C2.144288,28.103966 1.986928,29.415197 1.350597,30.840237 
            C1.000000,21.078798 1.000000,11.157597 1.000000,1.000000 
            C9.352740,1.000000 17.707705,1.000000 26.531334,1.000000 z'
      />
      <path
         fill='none'
         opacity='1.000000'
         stroke='none'
         d='M1.000000,48.472664 
            C3.379049,54.035645 5.788144,60.046703 12.868065,62.672058 
            C9.067219,63.000000 5.134439,63.000000 1.000000,63.000000 
            C1.000000,58.316349 1.000000,53.630836 1.000000,48.472664 z'
      />
   </svg>
)
