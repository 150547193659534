import React, { useEffect, useState, useRef } from "react";
import Table from "react-bootstrap/Table";
import { useTranslation } from 'react-i18next';
import { CardTitle } from "react-bootstrap";
import { ApiResponseE, GetAxios, Sorter, useDebouncedEffect, UserRole, parseJwt } from "../../../Shared";
import { setGlobalLoading, setShowModel } from "../../../Store";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { config } from "../../../Environments";

const PackageIndex = () => {
    const { i18n } = useTranslation();
    const [list, setList] = useState<ResumePackageModel[]>([]);
    const [currency, setCurrency]=useState<string>("USD");
    const [exchangeRate, setExchangeRate]=useState<number>(1);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation('plan');


    const loadData = () => {
        dispatch(setGlobalLoading(true));
        GetAxios(navigate, dispatch).get(config.apiBase + '/api/Resume/GetUserResumePackages?lang='+i18n.language).then(s => {
          setList(s.data.list);
          dispatch(setGlobalLoading(false));
        }).catch(s => {
          dispatch(setGlobalLoading(false));

        });
      }  
      useEffect(() => {
    
        loadData();
      }, [i18n.language]);
     
      React.useEffect(() => {
          //get user currency if is not usd then find exchange rate
          GetAxios().get(config.apiBase + '/api/General/GetCurrency')
              .then(res => {
                  setCurrency(res.data.data);
                  if (res.data.data != "USD") {
                      GetAxios().get(config.apiBase + '/api/General/GetExchangeRate?baseCurrency=USD&targetCurrency=DOP')
                          .then(res => {
                              if (res.data.success) {
                                  setExchangeRate(res.data.data); 
                              }
                          })
                  }
              })
      }, []);
      const FormatCurrency=(price:number, currency:string)=> {
          let neg = false;
           if (price < 0) {
               neg = true;
               price = Math.abs(price);
           }
           return  currency+ price.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString();
       }

    return (
        <>
            <div className="container my-3 h-100vh">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card custom-shadow">
                            <div className="card-header">
                                <div className="d-flex align-items-center">
                                    <CardTitle> {t("resume.myPackages")}</CardTitle>
                                </div>
                            </div>
                            <div className="card-body px-4">
                                <div className="table-responsive">
                                    <Table bordered className="mx-auto mt-3">
                                        <thead>
                                            <tr className="text-center table-primary fw-700 fs-18">
                                            <th className=""> {t("resume.plan")} </th>
                                                <th className="">{t("resume.availability")}</th>
                                                <th className="">{t("resume.myPackage")}</th>
                
                                                <th className="">{t("resume.expiringDate")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                list.map((item: ResumePackageModel, index: any) => (

                                                    <tr className="fs-15 fw-500 text-center" key={45*index+ item.planId}>
                                                    <td>
                                                        <span className=""> {item.noOfDays} {t("resume.day")} {item.packageName} <br />
                                                            - {currency=="USD"?FormatCurrency(item.packagePrice,"$"): FormatCurrency(item.packagePrice * exchangeRate,"RD$")} </span>
                                                    </td>
                                                    <td>
                                                        <span className=""> {t("resume.accessToAll")} <br />
                                                        {t("resume.templateWithThe")} <br />
                                                          {item.planId ==2 ? t("resume.goldBadge"):t("resume.silverBadge")}  </span>
                                                    </td>
                                                    <td>
                                                        <span className=""> {t("resume.click")} <Link to={item.planId==2?"/resumes/gold-templates":"/resumes/silver-templates"}>{t("resume.here")}</Link> {t("resume.toSee")} <br />
                                                        {t("resume.theAvailable")} <br />
                                                        {t("resume.templates")}</span>
                                                    </td>
                                                    <td>
                                                        <span className="badge px-2 py-1 fs-15 text-bg-danger"> {item.expiryDate} </span>
                                                    </td>
                                                </tr>
                                                ))
                                            }
                                            {/* <tr className="fs-15 fw-500 text-center">
                                                <td>
                                                    <span className=""> 14 Day Silver <br />
                                                        Access - $2.95 </span>
                                                </td>
                                                <td>
                                                    <span className=""> Access to all <br />
                                                        templates with the <br />
                                                        Silver badge </span>
                                                </td>
                                                <td>
                                                    <span className=""> Click <Link to="">here</Link> to see <br />
                                                        the available <br />
                                                        templates </span>
                                                </td>
                                                <td>
                                                    <span className="badge px-2 py-1 fs-15 text-bg-danger"> 30-6-2024 </span>
                                                </td>
                                            </tr> */}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default PackageIndex;
