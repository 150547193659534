import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { config } from "../../../Environments";
import { GetAxios, parseJwt } from "../../../Shared";
import MainContent from "./MainContent";
import SideContent from "./SideContent";
import { setGlobalLoading } from "../../../Store";
import { useNavigate, useSearchParams } from 'react-router-dom';
const Index = () => {
  const auth = useSelector((state: AppStore) => state.auth);
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const [userId, setId] = useState(params?.get('id') || "");



  const navigate = useNavigate();
  React.useEffect(() => {
    dispatch(setGlobalLoading(true));
    let url = config.apiBase + '/api/Employee/ProfilePublicView/?id=' + userId;

    GetAxios(navigate).get(url).then(res => {
      setData(res.data.data);
      dispatch(setGlobalLoading(false));
    });
  }, []);
  /* React.useEffect(() => {
   let id = searchParams.get('id');
    if (!id) {
      if (auth.raw)
        id = parseJwt(auth.raw?.access_token || '')?.sub;
    }
    if (!id) return
    dispatch(setGlobalLoading(true));
      GetAxios().get(config.apiBase + '/api/Employee/ProfilePublicView?id=' + id).then(res => {
      setData(res.data.data);
    dispatch(setGlobalLoading(false));
    });
  }, [auth, searchParams]);   */
  return (
    <>
      <div className="container">
        <div className="row g-4 py-50">
          <div className="col-md-4 p-lg-0">
            <SideContent data={data} />
          </div>
          <div className="col-md-8 pe-lg-0">
            <MainContent data={data} />
          </div>
        </div>
      </div>
    </>
  );
};
export default Index;
