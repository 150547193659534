import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BiFullscreen } from 'react-icons/bi';
import { Outlet } from 'react-router-dom';
import { StylesFullScreenModal } from '../../Components/StylesFullScreenModal';
import { ResumePreviewMain } from './Preview/ResumePreviewMain';
import { GetAxios } from '../../../../Shared';
import { setGlobalLoading } from '../../../../Store';
import { config } from '../../../../Environments';
import { useDispatch } from 'react-redux';
import { NavigateFunction, useLocation, useNavigate, useParams } from 'react-router-dom';
import { TemplateList } from '../../../../TemplateImageJSON';
export const EditorLayout = () => {

    //Check Resume Plan Exist or Not
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { resumeId } = useParams();
    useEffect(() => {
        GetAxios().get(config.apiBase + '/api/Resume/GetResumeTemplate?id=' + resumeId).then(res => {
            if (res.data.data != "") {
                const template = TemplateList.find(s => s.id == res.data.data);
                if (template?.isFree == false) {
                    GetAxios().get(config.apiBase + '/api/Resume/CheckResumePlanExist?planId=' + template.planId).then(res => {
                        if (!res.data.data) {
                            navigate(`/resumes`);

                        }
                    });
                }

            }

        });
    })
    return (
        <Row>
            <Col md={7} sm={12} className="px-4 px-md-5 px-lg-5 pt-3 scrollbar-col-nobg">
                <div className='position-relative'>
                    <Outlet />
                </div>
            </Col>
            <Col md={5} sm={12} className="px-4 pt-3 scrollbar-col-6">
                <FullScreen>
                    <ResumePreviewMain />
                </FullScreen>
            </Col>
        </Row>
    );
}

const FullScreen = (props: {
    children: JSX.Element
}) => {
    const [showFullScreen, setShowFullScreen] = useState(false);
    return (
        <div className='resume-full-screen-main'>
            <StylesFullScreenModal show={showFullScreen} onCloseClick={() => setShowFullScreen(false)} />
            <div className='resume-full-screen-icon-outer'>
                <div className='resume-full-screen-icon-container'>
                    <BiFullscreen size={34} onClick={() => setShowFullScreen(true)} />
                </div>
            </div>
            {props.children}
        </div>
    );
}