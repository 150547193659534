import React, { useState } from 'react';
import { Link, useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaRegEnvelope, FaInfoCircle } from "react-icons/fa";
import { GetAxios } from "../../../Shared";
import { config } from "../../../Environments";
import { setGlobalLoading } from "../../../Store";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';


export const ResumePlanConfirmation = () => {
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const [data, setData] = useState<any>();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { t: ts } = useTranslation('alert');
    const { t } = useTranslation('plan');
    React.useEffect(() => {
        if (searchParams.get('id')) {
            dispatch(setGlobalLoading(true));
            GetAxios().get(config.apiBase + '/api/Resume/GetResumePlanConfirmation?id=' + searchParams.get('id') + '&lang=' + i18n.language).then(res => {
                if (res.data.success) {
                    setData(res.data.data)
                    dispatch(setGlobalLoading(false));
                }
                else {
                    toast(res.data.message, { type: 'error' });
                    dispatch(setGlobalLoading(false));
                }
            });
        }
        else {
           // toast(ts('seminar.choosePlan'), { type: 'error' });
            navigate("/resumes");
        }
    }, [searchParams, i18n.language]);

    const [currency, setCurrency]=useState<string>("USD");
    const [exchangeRate, setExchangeRate]=useState<number>(1);
    React.useEffect(() => {
        //get user currency if is not usd then find exchange rate
        GetAxios().get(config.apiBase + '/api/General/GetCurrency')
            .then(res => {
                setCurrency(res.data.data);
                if (res.data.data != "USD") {
                    GetAxios().get(config.apiBase + '/api/General/GetExchangeRate?baseCurrency=USD&targetCurrency=DOP')
                        .then(res => {
                            if (res.data.success) {
                                setExchangeRate(res.data.data); 
                            }
                        })
                }
            })
    }, []);
    const FormatCurrency=(price:number, currency:string)=> {
       let neg = false;
        if (price < 0) {
            neg = true;
            price = Math.abs(price);
        }
        return  currency+ price.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString();
    }

    return (
        <>
            <div className='container pb-5'>
                <h2 className='fw-700 my-4'> {t('common.orderConfirm')}</h2>
                <div className='card my-4'>
                    <div className='row pb-4 p-3'>
                        <div className='col-md-8 mb-3'>
                            <div className='billing border border-1 p-3 mb-3'>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <h6 className='fw-600'> {t('common.billingInformation')}</h6>

                                </div>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <p className='fw-500 text-gray mb-0'> {data?.userName}</p>
                                    <p className='fw-500 text-gray mb-0'>

                                    </p>
                                </div>
                                {
                                    data?.paymentMethod !== 'Cheque' &&
                                    <p className='fw-500 text-gray mb-0'>{data?.billingDetail}  </p>
                                }
                            </div>
                            <div className='billing border border-1 p-3'>
                                <div className='fs-14'>
                                    <div className='detail'>
                                        <div className='top-bottom'>
                                            <div className='row'>
                                                <div className='col-md-12 mb-2'>
                                                    <div className='payment-method'>
                                                        <div className='title'>
                                                            <h5 className='fw-700'> {t('common.paymentMethod')}  </h5>
                                                            <div className='border border-top border-1 my-3'></div>
                                                        </div>
                                                        {
                                                            data?.paymentMethod !== 'Cheque' ?
                                                                <div className="form-check my-2 d-flex align-items-center justify-content-start">

                                                                    <img src="/images/content/mastercard-img.png" className='h-28  mx-1' alt="master card image" />
                                                                    <label className="form-check-label key-weight fs-21 fw-600" htmlFor="inlineCheckbox2"> {t('common.paidWith')} {data?.cardNumberAsteriks} </label>
                                                                </div> :
                                                                <div className="form-check my-2 d-flex align-items-center justify-content-start">

                                                                    <img src="/images/content/bank-img.png" className='h-22  mx-1' alt='bank image' />
                                                                    <label className="form-check-label key-weight fs-21 fw-600" htmlFor="ending8040">  {t('common.cheque')} </label>
                                                                </div>

                                                        }


                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='p-3 order-summary-bg'>
                                <h5 className='fw-700 my-2'> {t('common.orderSum')} </h5>
                                <div className='edit-sec'>
                                    <div className='d-flex justify-content-between align-items-center'>

                                        <div>
                                            <p className='fsize-13 fw-500 mb-0'>{t('common.packageName')}  </p>
                                        </div>
                                        <div>
                                            <p className='fsize-13 fw-500 mb-0'>

                                                {data?.packageName}

                                            </p>
                                        </div>

                                    </div>
                                    <div className='border border-top border-1 my-2'></div>
                                </div>
                                <div className='detail-order'>
                                   
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div>
                                            <p className='fs-14 fw-500 mb-0'> {t('common.subTotal')} </p>
                                        </div>
                                        <div>
                                            <p className='fs-14 fw-500 mb-0'>{currency =="USD"? FormatCurrency(data?.packagePrice,"$"):FormatCurrency(data?.packagePrice * exchangeRate,"RD$" )}</p>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div>
                                            <Link to="" className='fs-14'> {t('common.taxfee')} </Link>
                                        </div>
                                        <div>
                                            <p className='fs-14 fw-500 mb-0'> {currency =="USD"? FormatCurrency(data?.tax,"$"):FormatCurrency(data?.tax* exchangeRate,"RD$" )} </p>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        {/* <div>
                                            <Link to="" className='fs-14'> Have a promo code? </Link>
                                        </div> */}
                                    </div>
                                    <div className='border border-top border-1 my-2'></div>
                                    <div className='d-flex justify-content-between align-items-center mb-1'>
                                        <div>
                                            <h5 className='fw-500 mb-0'> {t('common.total')} (USD) </h5>
                                        </div>
                                        <div>
                                            <h5 className='fw-500 mb-0 text-blue'>{currency =="USD"? FormatCurrency(data?.totalPrice,"$"):FormatCurrency(data?.totalPrice * exchangeRate,"RD$" )} </h5>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div>
                                            <p className='fs-14 fw-500 mb-0'> {t('common.expiryDate')}: </p>
                                        </div>
                                        <div>
                                            <p className='fs-14 fw-500 mb-0'> {data?.expiryDate} </p>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div>
                                            <p className='fs-14 fw-500 mb-0'> {t('common.paidAmount')}: </p>
                                        </div>
                                        <div>
                                            <p className='fs-14 fw-500 mb-0'>  {currency =="USD"? FormatCurrency(data?.paidPrice,"$"):FormatCurrency(data?.paidPrice * exchangeRate,"RD$" ) }</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

