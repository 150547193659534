import { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { BiFileBlank } from "react-icons/bi";
import { useDispatch } from 'react-redux';
import { NavigateFunction, useLocation, useNavigate, useParams } from 'react-router-dom';
import { TemplateList } from '../../../TemplateImageJSON';
import { AdjustInCenter, AvailableColorTitle, ButtonICON, ColorCircle, ColorWrapper, HoverOverlay, OverlayButtonWrapper, RESUMEBGIMAGE, ShowTemplateListWrapper, StickyHeaderWrapper, SubTitle, TabButtons, TemplateHTMLHeader, TemplateHTMLHeaderBadge, TemplateHTMLHeaderTitle, Title, UseThisTemplateButton, WrapperDiv } from './TemplateStyle';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';


function GoldTemplates() {
    const { t } = useTranslation('resume');

    const navigate = useNavigate();
    const dispatch = useDispatch();
    

    const [TemplateFilter, setTemplateFilter] = useState<TemplateFilter>("All");
    const [RenderList, setRenderList] = useState(TemplateList);

    useEffect(() => {
        const newlist = TemplateList.filter(x => x.type.includes(TemplateFilter) && x.planId==2);
        setRenderList(newlist)
    }, [TemplateFilter]);
    return (
        <>
        
            <WrapperDiv>
            <Title> {t('selectTemplate.goldTemplate')} </Title>   
                <StickyHeaderWrapper>
                    <AdjustInCenter className='d-flex align-items-end'>
                        <div className='d-flex align-items-end justify-content-center'>
                            <div className='d-flex flex-wrap'>
                                <TabButtons onClick={() => setTemplateFilter("All")} className='tabsButton' active={TemplateFilter === "All" ? true : false}> {t('selectTemplate.all')} </TabButtons>
                                <TabButtons onClick={() => setTemplateFilter("Modern")} className='tabsButton' active={TemplateFilter === "Modern" ? true : false}> {t('selectTemplate.modern')} </TabButtons>
                                <TabButtons onClick={() => setTemplateFilter("Minimalist")} className='tabsButton' active={TemplateFilter === "Minimalist" ? true : false}> {t('selectTemplate.minimalist')} </TabButtons>
                                <TabButtons onClick={() => setTemplateFilter("Classic")} className='tabsButton' active={TemplateFilter === "Classic" ? true : false}> {t('selectTemplate.classic')} </TabButtons>
                            </div>
                        </div>
                    </AdjustInCenter>
                </StickyHeaderWrapper>
            </WrapperDiv>
            <ShowTemplateListWrapper className='pt-4'>
                <Container >
                    <Row>
                        {
                            RenderList?.map((x, i) =>
                                <Col sm={12} md={6} lg={4} xxl={4} key={'colm_' + i}>
                                    <GoldTemplateHTML navigate={navigate} data={x}  />
                                </Col>
                            )
                        }
                    </Row>
                </Container>
            </ShowTemplateListWrapper>
        </>
    );
}

export default GoldTemplates;


function GoldTemplateHTML(props: {
    data: Step1TemplateSelection,
    navigate: NavigateFunction
}) {

    const { t } = useTranslation('resume');

    const { data, navigate } = props;
    const dispatch = useDispatch();

   

    const BadgeContainer = styled.div`
    position: relative;
  `;

    const BadgeImage = styled.img`
    position: absolute;
    bottom: 30px;
    right: 15px;
  `;
    return (
        <div>
            <TemplateHTMLHeader>
                <TemplateHTMLHeaderTitle>
                    {data?.name}
                </TemplateHTMLHeaderTitle>
                {
                    data?.badge
                    && <TemplateHTMLHeaderBadge>
                        {data?.badge}
                    </TemplateHTMLHeaderBadge>
                }
            </TemplateHTMLHeader>
            <HoverOverlay className='position-relative'>
                <BadgeContainer>
                    <RESUMEBGIMAGE bgImage={data?.imgsrc} />
                    {data?.planId == 1 &&
                        <BadgeImage src="/images/resume-img/free-trail-badge.webp" alt="free-badge" className="img-fluid h-65px" />
                    }
                    {data?.planId == 2 &&
                        <BadgeImage src="/images/resume-img/gold-badge-logo.webp" alt="gold-membership-badge" className="img-fluid h-65px" />
                    }
                    {data?.planId == 3 &&
                        <BadgeImage src="/images/resume-img/silver-badge-logo.webp" alt="silver-membership-badge" className="img-fluid h-65px" />
                    }
                </BadgeContainer>
                <OverlayButtonWrapper className='showOverlayButton'>
                    {data?.planId == 1 &&
                        <img src="/images/resume-img/free-trail-badge.webp" alt="free-badge" className="img-fluid h-200px" />
                    }
                    {data?.planId == 2 &&
                        <img src="/images/resume-img/gold-badge-logo.webp" alt="gold-membership-badge" className="img-fluid h-200px" />
                    }
                    {data?.planId == 3 &&
                        <img src="/images/resume-img/silver-badge-logo.webp" alt="silver-membership-badge" className="img-fluid h-200px" />
                    }
                    
                </OverlayButtonWrapper>
            </HoverOverlay>
            <div className='mb-5'>
                <AvailableColorTitle>
                    {t('selectTemplate.availableColors')}:
                </AvailableColorTitle>
                <div className='d-flex align-items-center'>
                    {
                        data?.colors?.map((x, i) => <ColorWrapper key={'col_' + i}><ColorCircle obj={x} /></ColorWrapper>)
                    }
                </div>
            </div>
        </div>
    )
}