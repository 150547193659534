import { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { config } from '../../../../../../Environments';
import { GetAxios } from '../../../../../../Shared';
import { setGlobalLoading } from '../../../../../../Store';
import { setResumeActive, setResumeAddRecord } from '../../../../../../Store/Slices/ResumeSlice';
import { FaRegFileAlt, FaArrowLeft, FaPlus } from "react-icons/fa";
import TipsPopup from '../../../../Components/TipsPopup';
import { ParseHTMLTips } from '../../../../../../Components/Common/ParseTipsHTML';
import { confirmAlert } from "react-confirm-alert";
import { AddAnother } from "../../../../Components/AddAnother";
import { useTranslation } from 'react-i18next';

export const LanguagesAdd = (props: {
    onBack?: (e: void) => void,
    routePrefix?: string
}) => {

    const { t } = useTranslation('resume');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { id, resumeId } = useParams();

    const languages = useSelector((state: AppStore) => state.resume.languages);
    const information = useSelector((state: AppStore) => state.resume.activeLanguage);

    useEffect(() => {
        const defaultValue = languages.find(s => s.id == Number(id));
        dispatch(setResumeActive({ languages: defaultValue }));
    }, [languages, id, resumeId]);

    const setValue = (value: { [n in keyof ResumeLanguages]: ResumeLanguages[n] }) => {
        const info = { ...(information || {}), ...value };
        dispatch(setResumeActive({ languages: info }));
        dispatch(setResumeAddRecord({ languages: info }));
    };


    const Savebtn = () => {
        dispatch(setGlobalLoading(true));
        GetAxios(navigate, dispatch).post(config.apiBase + '/api/Resume/SaveLanguage', {
            ...information,
            id: id,
            resumeId: resumeId
        }).then(s => {
            navigate('..');
            dispatch(setGlobalLoading(false));
        })
    }
    // const addLanguages = () => {
    //     dispatch(setGlobalLoading(true));
    //     GetAxios(navigate, dispatch)
    //         .post(config.apiBase + '/api/Resume/SaveLanguage', { resumeId: resumeId })
    //         .then(s => {
    //             dispatch(setResumeAddRecord({
    //                 languages: {
    //                     id: s.data.data,
    //                     resumeId: Number(resumeId)
    //                 }
    //             }));
    //             dispatch(setResumeActive({}));
    //            // navigate('language/edit/' + s.data.data);


    //             dispatch(setGlobalLoading(false));
    //         });
    // };
    const Backbtn = () => {
        confirmAlert({
            title: t('newResumeCard.backAlertMsg.title').toString(),
            message: t('newResumeCard.backAlertMsg.description').toString(),
            closeOnClickOutside: false,
            closeOnEscape: false,
            buttons: [
                {
                    label: t('save').toString(),
                    onClick: Savebtn
                },
                {
                    label: t('exit').toString(),
                    className: 'bg-danger',
                    onClick: _ => {
                        navigate('..');
                    }
                }
            ]
        })
    }


    return (
        <>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='mb-4'>
                        <TipsPopup title="contactStep.tips" description="langMdl.description"
                            tipsList={ParseHTMLTips(languageTipsBody, t)} />
                    </div>
                    <div className="mb-4">
                        <div className="dZcjzV sections-header">
                            <div className="feVKyO"> {t('langStep.langTitle')}?</div>
                        </div>
                        <div className="gJaquO"> {t('langStep.langDesc')}.</div>
                    </div>
                    <div className='form-float'>
                        <div className="form-floating mb-4 rounded fw-500">
                            <input type="text" className="form-control rounded bg-field" placeholder={t('langStep.langAdd.french').toString()} value={information?.name} onChange={e => setValue({ name: e.target.value })} />
                            <label className='label-float'> {t('langStep.langAdd.language').toString()} </label>
                            {/* <span className='helper-text ms-3'>{t('eg')}. {t('langStep.langAdd.french')}</span> */}
                        </div>
                    </div>
                </div>
                <div className='col-md-12'>
                    <label className='mb-3 fw-600'> {t('langStep.langAdd.abilityLevel')}</label>
                    <AbilityLevel selected={information?.level} selectedChanged={_ => setValue({ level: _ })} />
                </div>
                {/* <div className='col-md-12'>
                    <div className='my-3 pt-2'>
                        <Link to={"javascript:void(0)"} className='bg-blue border border-2 border-primary fs-16 fw-600 px-3 py-2 rounded-4 text-decoration-none text-white'>
                            Add a Language (optional) <FaPlus className="ms-1" />
                        </Link>
                    </div>
                </div> */}
            </div>
            <div className='col-md-12'>
                <label className='my-3 fw-600'>  {t('langStep.langAdd.certificatesOptional')}</label>
                {/*  <button className='add-btn fw-600 rounded' onClick={addLanguages}>Add</button> */}

                <div className='form-float'>
                    <div className="form-floating mb-4 rounded fw-500">
                        <input type="text" className="form-control rounded bg-field" placeholder={t('langStep.langAdd.diplomaFrench').toString()} value={information?.course} onChange={e => setValue({ course: e.target.value })} />
                        <label className='label-float'> {t('langStep.langAdd.courseCertificate')} </label>
                        {/* <span className='helper-text ms-3'>{t('eg')}. {t('langStep.langAdd.diplomaFrench')}</span> */}
                    </div>
                </div>
            </div>
            <div className="bottom-btn-sticky">
                <div className='next-previous-btns d-flex align-items-center justify-content-between'>
                    <div className=''>
                        <Button className="fw-500 previous-btn bg-back"
                            onClick={Backbtn}
                        ><FaArrowLeft className="me-2" /> {t('back')}</Button>
                    </div>
                    <div className=''>
                        <Button className="save-btn-resume fw-500"
                            onClick={Savebtn}
                        ><FaRegFileAlt className="me-1" size={20} /> {t('save')}</Button>
                    </div>
                </div>
            </div>
        </>
    );
}
const AbilityLevel = (props: {
    selected?: AbilityLevel,
    selectedChanged: (e: AbilityLevel) => void
}) => {
    const { selected, selectedChanged } = props;

    const { t } = useTranslation('resume');

    return (
        <div style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(120px, auto))',
            gap: '8px',
        }}>
            <div className={"ability-level " + (selected == 'Elementary' ? 'active' : '')} onClick={_ => selectedChanged('Elementary')}>
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="23" viewBox="0 0 26 23" className='mb-1'>
                    <g fill="none" fillRule="evenodd">
                        <g fill="#26A0F4">
                            <g>
                                <g>
                                    <path
                                        d="M31.023 11.75c.521 0 .951.39 1.014.895l.008.128v16.432c0 .522-.39.952-.894 1.015l-.128.008h-22.5c-.522 0-.952-.39-1.015-.895l-.008-.128V12.773c0-.565.458-1.022 1.023-1.022.521 0 .951.39 1.014.894l.008.128v15.41H30L30 12.772c0-.521.39-.951.894-1.014l.129-.008zm-1.984-2.094l.006.117v14.25c0 .423-.259.798-.645.95l-.108.037-8.25 2.25-.011.003c-.033.009-.066.016-.1.02l-.033.005c-.04.005-.083.008-.125.008-.043 0-.085-.003-.127-.008l-.057-.009.02.004c-.033-.005-.067-.013-.1-.021l-.005-.002-8.25-2.25c-.408-.111-.702-.46-.748-.873l-.006-.114V9.773c0-.634.568-1.106 1.177-1.01l.115.024 7.98 2.175 7.982-2.175c.612-.167 1.216.257 1.285.87zm-16.494 1.456v12.129l6.205 1.692V12.804l-6.205-1.692zm14.454 0l-6.204 1.691v12.13l6.204-1.692V11.112z"
                                        transform="translate(-224 -420) translate(165 396) translate(52 16)"
                                    ></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
                <span className='fw-500 text-level'> {t('langStep.langAdd.elementary')} </span>
            </div>
            <div className={"ability-level " + (selected == 'Intermediate' ? 'active' : '')} onClick={_ => selectedChanged('Intermediate')}>
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" className='mb-1'>
                    <g fill="none" fillRule="evenodd">
                        <g fill="#26A0F4">
                            <g>
                                <g>
                                    <path
                                        d="M16.635 23.784c1.696 0 3.378.288 4.984.773 1.72.52 2.9 2.128 2.9 3.96v1.75c0 .543-.43.983-.961.983H9.712c-.532 0-.962-.44-.962-.982v-1.762c0-1.83 1.176-3.439 2.893-3.958 1.585-.48 3.267-.764 4.992-.764zm0 1.965c-1.526 0-3.028.254-4.446.683-.9.272-1.516 1.114-1.516 2.074v.779h11.923v-.767c0-.905-.547-1.704-1.366-2.023l-.156-.054c-1.44-.435-2.944-.692-4.44-.692zM29.562 7.73c2.636 2.263 4.188 5.592 4.188 9.178 0 3.587-1.553 6.917-4.19 9.18-.406.349-1.012.295-1.354-.12-.342-.416-.289-1.036.118-1.384 2.206-1.894 3.503-4.675 3.503-7.676 0-3-1.297-5.78-3.502-7.674-.406-.349-.459-.968-.117-1.384.341-.415.948-.469 1.354-.12zm-2.285 3.176c1.66 1.518 2.627 3.681 2.627 6.002 0 2.322-.968 4.486-2.627 6.004-.396.361-1.004.327-1.358-.077-.354-.404-.32-1.025.075-1.387 1.256-1.15 1.987-2.783 1.987-4.54 0-1.756-.73-3.39-1.987-4.539-.396-.361-.43-.982-.075-1.387.354-.404.962-.438 1.358-.076zm-10.642 1.09c2.655 0 4.807 2.2 4.807 4.912 0 2.817-2.209 5.698-4.807 5.698-2.599 0-4.808-2.88-4.808-5.698 0-2.712 2.153-4.911 4.808-4.911zm0 1.965c-1.593 0-2.885 1.32-2.885 2.947 0 1.823 1.465 3.733 2.885 3.733 1.419 0 2.884-1.91 2.884-3.733 0-1.627-1.292-2.947-2.884-2.947zm8.414.219c.646.748 1.009 1.71 1.009 2.728 0 1.018-.364 1.98-1.01 2.729-.35.407-.958.446-1.357.088-.398-.359-.437-.98-.086-1.387.34-.393.53-.896.53-1.43 0-.534-.19-1.036-.528-1.428-.352-.407-.314-1.028.085-1.387.398-.359 1.005-.32 1.357.087z"
                                        transform="translate(-377 -419) translate(317 396) translate(52 16)"
                                    ></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
                <span className='fw-500 text-level'> {t('langStep.langAdd.intermediate')}</span>
            </div>
            <div className={"ability-level " + (selected == 'Advanced' ? 'active' : '')} onClick={_ => selectedChanged('Advanced')}>
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="27" viewBox="0 0 28 27" className='mb-1'>
                    <g fill="none" fillRule="evenodd">
                        <g fill="#26A0F4">
                            <g>
                                <g>
                                    <path
                                        d="M18.243 6.415c.385-.22.857-.22 1.24 0l7.666 4.38h3.078c.647 0 1.18.492 1.244 1.123l.006.127.001 4.49c1.381 1.059 2.272 2.726 2.272 4.601 0 1.291-.422 2.484-1.136 3.447v6.78c0 .854-.836 1.456-1.646 1.186l-3.013-1.004-3.014 1.004c-.81.27-1.646-.332-1.646-1.185v-6.781c-.714-.963-1.136-2.156-1.136-3.447 0-3.2 2.595-5.795 5.796-5.795.349 0 .69.03 1.023.09v-2.136H8.75v13.41H20c.69 0 1.25.56 1.25 1.25s-.56 1.25-1.25 1.25H7.5c-.69 0-1.25-.56-1.25-1.25v-15.91c0-.69.56-1.25 1.25-1.25h3.077zm11.87 20.101c-.667.268-1.395.416-2.158.416-.764 0-1.492-.148-2.16-.416v3.113l1.764-.588c.257-.085.534-.085.79 0l1.765.588zm-2.158-8.675c-1.82 0-3.296 1.475-3.296 3.295 0 1.82 1.476 3.296 3.296 3.296 1.82 0 3.295-1.476 3.295-3.296 0-1.82-1.475-3.295-3.295-3.295zM16.59 21.023c.69 0 1.25.56 1.25 1.25s-.56 1.25-1.25 1.25h-4.546c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25zM20 16.477c.69 0 1.25.56 1.25 1.25s-.56 1.25-1.25 1.25h-7.955c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25zM18.864 8.94l-3.249 1.855h6.496L18.864 8.94z"
                                        transform="translate(-527 -418) translate(469 396) translate(52 16)"
                                    ></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
                <span className='fw-500 text-level'> {t('langStep.langAdd.advanced')} </span>
            </div>
            <div className={"ability-level " + (selected == 'Native' ? 'active' : '')} onClick={_ => selectedChanged('Native')}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="26" viewBox="0 0 24 26" className='mb-1'>
                    <g fill="none" fillRule="evenodd">
                        <g fill="#26A0F4">
                            <g>
                                <g>
                                    <path
                                        d="M30 8.75c.69 0 1.25.56 1.25 1.25v5c0 2.347-1.903 4.25-4.25 4.25l.143-.002c-.521 3.003-2.89 5.372-5.892 5.894l-.001 1.608H22c2.9 0 5.25 2.35 5.25 5.25 0 .69-.56 1.25-1.25 1.25H14c-.69 0-1.25-.56-1.25-1.25 0-2.9 2.35-5.25 5.25-5.25h.75v-1.607c-3.003-.522-5.372-2.892-5.893-5.895C10.576 19.172 8.75 17.3 8.75 15v-5c0-.69.56-1.25 1.25-1.25zm-8 20.5h-4c-1.069 0-1.995.61-2.45 1.5h8.9c-.455-.89-1.381-1.5-2.45-1.5zm2.75-18h-9.5V18c0 2.619 2.12 4.743 4.737 4.75h.026H20c2.623 0 4.75-2.127 4.75-4.75v-6.75zm-12 0h-1.5V15c0 .882.652 1.61 1.5 1.732V11.25zm16 0h-1.5l.001 5.482c.801-.115 1.427-.773 1.493-1.588L28.75 15v-3.75z"
                                        transform="translate(-681 -420) translate(621 396) translate(52 16)"
                                    ></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
                <span className='fw-500 text-level'> {t('langStep.langAdd.native')} </span>
            </div>
        </div>

    )
}



const languageTipsBody = {
    "tipsMdlTitle": ["langMdl.tipsList1",
        "langMdl.tipsList2",
        "langMdl.tipsList3",
        "langMdl.tipsList4",
    ],
    "langMdl.exmp.title": ["langMdl.exmp.exmpList1",
        "langMdl.exmp.exmpList2",
        "langMdl.exmp.exmpList3",
        "langMdl.exmp.exmpList4",
        "langMdl.exmp.exmpList5",
        "langMdl.exmp.exmpList6",
    ],
}