import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Link, useNavigate } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { GetAxios, GetAxiosLite, KeyPairValue, LabelValuePair } from '../../../Shared';
import { config } from '../../../Environments';
import { useTranslation } from 'react-i18next';



const SpecialOfferData = [
    {
        id: "1",
        title: "job.planTitle1",
        price: "199",
    },
    {
        id: "2",
        title: "job.planTitle2",
        price: "299",
    },
    {
        id: "3",
        title: "job.planTitle3",
        price: "399",
    },
    {
        id: "4",
        title: "job.planTitle4",
        price: "599",
    },
    {
        id: "5",
        title: "job.planTitle5",
        price: "799",
    },
    {
        id: "6",
        title: "job.planTitle6",
        price: "999",
    },
]

const ResumeDatabasePackage = [
    {
        id: "7",
        productName: "job.planTitle7",
        duration: "job.planDuration7",
        rateCard: "usd 299",
    },
    {
        id: "8",
        productName: "job.planTitle8",
        duration: "job.planDuration8",
        rateCard: "usd 299",
    },
    {
        id: "9",
        productName: "job.planTitle9",
        duration: "job.planDuration9",
        rateCard: "usd 299",
    },
]

const ResumeSearchDataDB = [
    {
        id: "1",
        title: "Profile having 2 Years of Experience with Ansys skills",
        description: "AutoCAD,tribon m3 ,delftship,ansys,Ansys ,MS Office training,",
        name: "Geor es Bush",
        currentLocation: "Cayes",
        nationality: "Haitian",
    },
    {
        id: "2",
        title: "3 Years of Experience in Construction & Engineering Industry",
        description: "Mechanical Technician,Mechanical Engineers,MECHAN",
        name: "Bill Clinton",
        currentLocation: "Gonaives",
        nationality: "Haitian",
    },
    {
        id: "3",
        title: "Profile having 2 Years of Experience with Ansys skills",
        description: "AutoCAD,tribon m3 ,delftship,ansys,Ansys ,MS Office training,",
        name: "Geor es Bush",
        currentLocation: "Cayes",
        nationality: "Haitian",
    },
]


export const JobPlans = () => {

    const [modalShow, setModalShow] = useState(false);
    const { t: t } = useTranslation('plan');
    const { t: ts } = useTranslation('alert');
    const navigate = useNavigate();

    function setJobPlan(selectedPlan: any) {
        if (selectedPlan == 0) {
            toast(ts('job.choosePlan'), { type: 'error' });
        }
        else {
            navigate("/employer-account/job-plan-checkout?pId=" + selectedPlan);
        }
    };


    return (
        <>
            <div className='card mb-5'>
                <div className='container p-4'>
                    <div className='row mb-4'>
                        <div className='col-md-6 col-12'>
                            <div>
                                <img src='/images/upgrade-account/insititution-seminar-employee.jpg' className='mx-auto seminar-employee-w' alt="Resume database employee" />
                            </div>
                        </div>
                        <div className='col-md-6 col-12'>
                            <div>
                                <img src='/images/upgrade-account/seminar-employee-chart.jpg' className='mx-auto seminar-employee-w' alt="Curriculosi-employee-chart" />
                            </div>
                        </div>
                    </div>
                    <div className='container'>
                        <div className='row special-offer-bg p-3'>
                            <div className='col-md-12'>
                                <div>
                                    <h4>{t('job.specialOffers')}</h4>
                                </div>
                            </div>
                            {SpecialOfferData.map((item, index) => {
                                return (
                                    <>
                                        <div className='col-md-2 px-1'>
                                            <div className='card offer-card-bg p-2 rounded-0' key={index}>
                                                <div className='mx-auto p-3'>
                                                    <h6 className='text-uppercase text-gray'>{t(item.title)}</h6>
                                                </div>
                                                <div className='d-flex align-items-baseline justify-content-between'>
                                                    <div>
                                                        <h6 className='text-uppercase fw-700'>Usd <span className='me-1'>{item.price}</span></h6>
                                                    </div>
                                                    <div>
                                                        <button type="submit" onClick={() => { setJobPlan(item.id) }} className="border-0 btn-contine py-2 px-3 text-white fw-600 text-uppercase">
                                                            {t('job.buy')}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    </div>
                    <div className='row my-4'>
                        <div className='col-md-5 col-12'>
                            <div className='card offer-card-bg p-2 rounded-0 border-0 resume-bg-db'>
                                <div className='text-end me-2 pt-80'>
                                    <h3 className='text-uppercase text-white fw-700'> {t('job.candidateSearch')} <br /><span className='fw-800'>{t('job.liveDemo')}</span></h3>
                                    <button type="submit" className="btn-try-now fw-500 text-uppercase" onClick={() =>
                                        GetAxios().get(config.apiBase + '/api/Employer/CheckPlanPurchase').then(res => {
                                            if (res.data) {
                                                setModalShow(true)
                                            }
                                            else {
                                                toast(ts('resume.choosePlan'), { type: 'error' });
                                            }

                                        })}>
                                        {t('job.tryNow')}
                                    </button>
                                    <ResumeDatabaseModal handleModalShow={modalShow} handleModalClose={() => setModalShow(false)} />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-7 col-12'>
                            <div className='row special-offer-bg p-2'>
                                <div className='col-md-12'>
                                    <div>
                                        <h4>{t('job.rDPackages')}</h4>
                                    </div>
                                </div>
                                <div className='card rounded-0 px-0'>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>{t('job.productName')}</th>
                                                <th>{t('job.duration')}</th>
                                                <th>{t('job.rateCard')}</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ResumeDatabasePackage.map((x, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>
                                                            <span className='text-gray'>{t(x.productName)}</span>
                                                        </td>
                                                        <td>
                                                            <span className='text-gray'>{t(x.duration)}</span>
                                                        </td>
                                                        <td>
                                                            <span className='text-uppercase text-blue fw-600'>{x.rateCard}</span></td>
                                                        <td>
                                                            <button type="submit" onClick={() => { setJobPlan(x.id) }} className="border-0 btn-contine p-2 text-white fw-600 text-uppercase fs-12 text-white">
                                                                {t('job.buyNow')}
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export const ResumeDatabaseModal = (props: any) => {

    const [classId, setclassId] = useState(0);
    const [sector, setSector] = useState(0);
    const [dept, setdept] = useState(0);
    const [query, setQuery] = useState('');
    const { i18n } = useTranslation();


    //Resume Preview Link
    const [resumesList, setResumesList] = useState<ResumeView[]>([]);
    const [sectors, setSectors] = useState<KeyPairValue[]>([]);
    const [departments, setDepartments] = useState<LabelValuePair[]>([]);
    const [Classification, setClassifications] = useState<KeyPairValue[]>([]);
    const { t: t } = useTranslation('plan');

    useEffect(() => {
        GetAxios().get(config.apiBase + '/api/General/GetDepartments').then(res => {
            setDepartments(res.data);
        });
        GetAxios().get(config.apiBase + '/api/General/GetClassifications?lang=' + i18n.language).then(res => {
            setClassifications(res.data);
        });

        GetAxios().get(config.apiBase + '/api/General/GetListBySystemType?lang=' + i18n.language + '&type=Sectors').then(res => {
            setSectors(res.data);
        });

    }, [i18n.language]);

    const Search = () => {
        GetAxios().post(config.apiBase + '/api/Resume/SearchResumes', {
            Query: query,
            Sector: sector,
            DepartmentId: dept,
            ClassificationId: classId
        }).then(res => {
            setResumesList(res.data.data);

        })
    }

    return (
        <>
            <Modal show={props.handleModalShow} onHide={props.handleModalClose} animation={true} className='popup-modal-width top-cl' size='lg'>
                <Modal.Header className='popup-gray-bg' closeButton>
                    <Modal.Title className='fw-700'>{t('job.rDA')}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-4'
                    style={{
                        maxHeight: 'calc(100vh - 210px)',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                    }}>
                    <div className='row mb-2'>
                        <div className='col-md-4'>
                            <select onChange={e => setclassId(Number(e.target.value))} value={classId} className="form-select form-control key-weight mt-2">
                                <option value={0}>{t('job.selectClassification')}</option>
                                {
                                    Classification.map((v, i) => (
                                        <option key={'%_' + i} value={v.key}>{v.value}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='col-md-4'>
                            <select onChange={e => setSector(Number(e.target.value))} value={sector} className="form-select form-control key-weight mt-2" >
                                <option value={0}>{t('job.selectSector')}</option>
                                {sectors.map((v, index) => (
                                    <option key={'sseinde__' + index} value={v.key}>{v.value}</option>
                                ))
                                }
                            </select>
                        </div>
                        <div className='col-md-4'>
                            <select onChange={e => { setdept(Number(e.target.value)) }} value={dept} className="form-select form-control key-weight mt-2">
                                <option value={0}>{t('job.selectDept')}</option>
                                {
                                    departments.map((v, i) => (
                                        <option key={'%__' + i} value={v.value}>{v.label}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    {
                        resumesList.length == 0 ?
                            <p className="text-danger">{t('job.noresumeFound')}</p> :
                            resumesList.map((y, i) => {
                                return (
                                    <>
                                        <div className='row amount-detail pt-2' key={"resumes__" + i}>
                                            <div className='col-md-8 col-12'>
                                                <Link to={'/employer-account/resume-preview?id=' + y.id} title={t('job.clickResume').toString()} target='_blank' className='fw-600 mb-0 text-uppercase text-decoration-none text-dark pointer-event preview-link'>{y.firstName} {y.lastName}</Link>
                                                <p className='mb-0 fw-600 text-gray fs-14'>{y.skills}</p>
                                                <p className='mb-0 fw-500 text-gray fs-14' dangerouslySetInnerHTML={{ __html: y.objectives || "N/A" }}>

                                                </p>
                                            </div>
                                            <div className='col-md-4 col-12 pe-0'>

                                                <p className='mb-0 fw-500 text-gray fs-14'><b>{t('job.tel')}:</b><span>{y.phone}</span></p>
                                                <p className='mb-0 fw-500 text-gray fs-14'><b>{t('job.email')}:</b> <span>{y.email}</span></p>
                                                <p className='mb-0 fw-500 text-gray fs-14'><b>{t('job.currentLoc')}:</b> <span>{y.address}</span></p>
                                                <p className='mb-0 fw-500 text-gray fs-14'><b>{t('job.nationality')}:</b><span>{y.nationality}</span></p>

                                            </div>
                                            <div className='border-2 border-bottom my-2'></div>
                                        </div>
                                    </>
                                )
                            })
                    }

                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-between align-items-center px-3'>
                    <div>
                        <p className='fw-600 text-gray'><span className='fw-700 text-black'>{resumesList.length}</span> {resumesList.length > 1 ? t('job.resumesFound') : t('job.resumeFound')}</p>
                    </div>
                    <div className='d-flex'>
                        <Button variant="danger" onClick={Search} className='fw-500 text-uppercase add-section-btn me-1 rounded-0 border-0 fs-15'>
                            {t('job.search')}
                        </Button>
                        <Button variant="danger" className='fw-500 rounded-0 text-uppercase' onClick={props.handleModalClose}>
                            {t('job.cancel')}
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}


export interface ResumeView {
    id: number,
    userId: string,
    firstName: string,
    lastName: string,
    objectives: string,
    nationality: string,
    email: string,
    phone: string,
    address: string,
    skills: string
}



