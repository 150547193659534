import axios from "axios";
import { FieldArray, FormikProvider } from "formik";
import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { FaTimes } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { CountrySelect, DatePickSelect } from "../../../Components";
import { config } from "../../../Environments";
import { GetAxios, KeyPairNValue, LabelValuePair } from "../../../Shared";
import { useTranslation } from "react-i18next";

const SideContent = (props: any) => {
  
const MaleAvatar = "/images/MalePlaceholder.png";
const FemaleAvatar = "/images/FemalePlaceholder.png";
  const { t } = useTranslation("candidate");
  const { t: ct } = useTranslation("common");
  const { t: ts } = useTranslation("alert");
  const dispatch = useDispatch();
  const [departments, setDepartments] = useState<LabelValuePair[]>([]);
  const formik = props.formik;
  // const [picture, setPicture] = useState<any>('');
  //const [pictureLoaded, setPictureLoaded] = useState(false);
  const [picture, setPicture] = useState("");
  const [dummyPicture, setDummyPicture] = useState("");
  const [states, setStates] = useState<KeyPairNValue[]>([]);
  /*   React.useEffect(() => {
      setPicture(props.imageName);
    }, [props, props.imageName]); */
    const { i18n } = useTranslation();
  React.useEffect(() => {
    GetAxios()
      .get(config.apiBase + "/api/Employee/GetProfilePicture")
      .then((res) => {
        if (res.data.success) {

          setPicture(config.apiBase + res.data.data);
        }
      });
  }, []);

  const changePicture = (gender: string) => {
    GetAxios()
      .get(config.apiBase + "/api/Employee/GetProfilePicture")
      .then((res) => {
        if (res.data.data == "" && gender == "Female") {
          setPicture(FemaleAvatar);
        }
        else if (res.data.data == "" && gender == "Male") {
          setPicture(MaleAvatar);
        }
        else {
          setPicture(config.apiBase + res.data.data);
        }
      });
  }
  let myref: any = null;

  React.useEffect(() => {
    GetAxios()
      .get(config.apiBase + "/api/General/GetDepartments")
      .then((res) => {
        setDepartments(res.data);
      });
    GetAxios()
      .get(config.apiBase + "/api/General/GetListBySystemType?lang=" + i18n.language + "&type=USStates")
      .then((res) => {
        setStates(res.data);
      });
  }, []);

  const onFileSelected = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setPicture(reader.result?.toString() || "");

        const axios = GetAxios();
        const formData = new FormData();
        formData.append("picture", e.target.files[0]);
        axios
          .patch(
            config.apiBase + "/api/Employee/UpdateProfilePicture",
            formData
          )
          .then((res) => {
            if (res.data.success) {
              // setPictureLoaded(true);
              toast(ts("account.picUpdate"), { type: "success" });
            } else {
              toast(ts("error.updatePic"), { type: "error" });
            }
          })
          .catch((err) => {
            console.error(err);

            toast(ts("error.updatePic"), { type: "error" });
          });
      });
      reader.readAsDataURL(e.target.files[0]);

      /*  const axios = GetAxios();
       const formData = new FormData();
       formData.append('picture', e.target.files[0])
       axios.patch(config.apiBase + '/api/Employee/UpdateProfilePicture', formData).then(res => {
        
         if (res.data.success) {
           setPictureLoaded(true);
           toast(res.data.message, { type: 'success' });
         } else {
           toast('Unable to update picture', { type: 'error' });
         }
       }).catch(err => {
       
         console.error(err);
         toast('Unable to update picture', { type: 'error' });
       }); */
    }
  };

  const handleBrowse = function (e: any) {
    e.preventDefault();
    myref.click();
  };
  // const [defaultCountry, setDefaultCountry] = useState("DO");
  // React.useEffect(() => {
  //   axios.get(config.apiBase + "/api/General/Info").then((res) => {
  //     setDefaultCountry("DO");
  //     /*  if (!formik.values.countryResidence) {
  //        formik.setFieldValue('countryResidence', res?.data?.country || 'HT')
  //      } */
  //   });
  // }, []);



  return (
    <div className="card border-0 rounded-0">
      <div className="profile-sec">
        <img
          src={picture}
          className="img-fluid rounded w-100"
          onError={(ev: any) => {
            GetAxios()
              .get(config.apiBase + "/api/General/GetUserSex")
              .then((res) => {
                if (res.data.data == "Male") {
                  setDummyPicture(MaleAvatar);
                } else {
                  setDummyPicture(FemaleAvatar);
                }
              });
            ev.target.src = dummyPicture;

          }}
          alt="candidate profile image"
        />
      </div>
      <div className="card-body pb-5">
        <div className="">
          <div className="text-center mt-2 mb-4">
            <input
              className="hidden d-none"
              id="logo-input"
              ref={(r) => {
                myref = r;
              }}
              type="file"
              onChange={onFileSelected}
            />
            <a
              className="button-default border-0 text-uppercase text-decoration-none text-nowrap pointer-event"
              id="btnUploadImage"
              onClick={handleBrowse}
            >
              {t("editCandProfile.uploadAPicture")}
            </a>
          </div>
          <div className="d-flex flex-column border-bottom">
            <div className="d-flex align-items-center justify-content-between client-side-desc">
              <h5 className="text-color-33 fw-600 text-uppercase mb-2">
                {t("editCandProfile.editCandidateProfile")}
              </h5>
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-5">
              <div className="d-flex align-items-center line-height-star">
                <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                  {ct("name")}:
                </label>
                <span className="fs-1 mt-3 text-danger">*</span>
              </div>
            </div>
            <div className="col-7 ps-1">
              <div>
                <div className="d-inline">
                  <input
                    name="firstName"
                    onChange={formik.handleChange}
                    value={formik.values.firstName}
                    type="text"
                    className="d-inline form-control border-0 job-regitration-input w-45 font-input"
                    placeholder={t("editCandProfile.firstName").toString()}
                  />
                  <input
                    name="lastName"
                    onChange={formik.handleChange}
                    value={formik.values.lastName}
                    type="text"
                    className="d-inline form-control border-0 job-regitration-input w-45 font-input"
                    placeholder={t("editCandProfile.lastName").toString()}
                  />
                </div>
                {formik.errors.firstName && formik.touched.firstName ? (
                  <small className="text-danger">
                    {formik.errors.firstName}
                  </small>
                ) : (
                  ""
                )}
                {formik.errors.lastName && formik.touched.lastName ? (
                  <small className="text-danger ms-4">
                    {formik.errors.lastName}
                  </small>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-5">
              <div className="d-flex align-items-center line-height-star">
                <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                  {ct("gender")}:
                </label>
                <span className="fs-1 mt-3 text-danger">*</span>
              </div>
            </div>
            <div className="col-7 ps-1">
              <div>
                <div className="form-check form-check-inline fs-radio">
                  <input
                    className="form-check-input"
                    onChange={(e) => {
                      formik.setFieldValue("gender", e.target.value);
                      changePicture(e.target.value);
                    }}
                    checked={formik.values.gender === "Male" ? true : false}
                    value="Male"
                    type="radio"
                    name="gender"
                    id="flexRadioDefault1"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault1"
                  >
                    {ct("male")}
                  </label>
                </div>
                <div className="form-check form-check-inline fs-radio">
                  <input
                    className="form-check-input"
                    type="radio" /* onChange={formik.handleChange} */
                    onChange={(e) => {
                      formik.setFieldValue("gender", e.target.value);
                      changePicture(e.target.value);
                    }}
                    checked={formik.values.gender === "Female" ? true : false}
                    value="Female"
                    name="gender"
                    id="flexRadioDefault2"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault2"
                  >
                    {ct("female")}
                  </label>
                </div>
                {formik.errors.gender && formik.touched.gender ? (
                  <small className="text-danger">{formik.errors.gender}</small>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-5 align-self-center">
              <div className="d-flex align-items-center line-height-star">
                <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                  {ct("dateOfBirth")}:
                </label>
                <span className="fs-1 mt-3 text-danger">*</span>
              </div>
            </div>
            <div className="col-7 ps-1">
              <div>
                <span>
                  <DatePickSelect
                    yearFrom={1960}
                    yearTo={new Date().getFullYear()}
                    maxDate={new Date()}
                    minDate={new Date(1960, 1, 1)}
                    placeHolder={ct("dateOfBirth").toString()}
                    selected={formik.values.dateOfBirth}
                    onChange={(date) => {
                      formik.setFieldValue("dateOfBirth", date);
                    }}
                  />
                </span>
                {formik.errors.dateOfBirth && formik.touched.dateOfBirth ? (
                  <small className="text-danger">
                    {formik.errors.dateOfBirth}
                  </small>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          {/* <div className="row pt-3">
            <div className="col-5">
              <div className="d-flex align-items-center line-height-star">
                <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                  {t("nationality")}:
                </label>
                <span className="fs-1 mt-3 text-danger">*</span>
              </div>
            </div>
            <div className="col-7 ps-1">
              <div>
                <span>
                  <input
                    className="form-control font-input"
                    name="nationality"
                    onChange={formik.handleChange}
                    value={formik.values.nationality}
                    type="text"
                    placeholder={t("editCandProfile.residence").toString()}
                  />
                </span>
                {formik.errors.nationality && formik.touched.nationality ? (
                  <small className="text-danger">
                    {formik.errors.nationality}
                  </small>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div> */}
          <div className="row pt-3">
            <div className="col-5">
              <div className="d-flex align-items-center line-height-star">
                <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                  {ct("city")}:
                </label>
                <span className="fs-1 mt-3 text-danger">*</span>
              </div>
            </div>
            <div className="col-7 ps-1">
              <div>
                <span>
                  <input
                    className="form-control border-0 job-regitration-input font-input"
                    name="city"
                    onChange={formik.handleChange}
                    value={formik.values.city}
                    type="text"
                    placeholder={t("editCandProfile.residence").toString()}
                  />
                </span>
                {formik.errors.city && formik.touched.city ? (
                  <small className="text-danger">
                    {formik.errors.city}
                  </small>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-5">
              <div className="d-flex align-items-center line-height-star">
                <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                  {ct("tel")}:
                </label>
                <span className="fs-1 mt-3 text-danger">*</span>
              </div>
            </div>
            <div className="col-7 ps-1">
              <div>
                <span className="">
                  <input
                    className="form-control border-0 job-regitration-input font-input"
                    name="tel"
                    onChange={formik.handleChange}
                    value={formik.values.tel}
                    type="text"
                    placeholder={t(
                      "editCandProfile.telephoneNumber"
                    ).toString()}
                  />
                </span>
                {formik.errors.tel && formik.touched.tel ? (
                  <small className="text-danger">{formik.errors.tel}</small>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-5">
              <div className="d-flex align-items-center line-height-star">
                <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                  {ct("email")}:
                </label>
                <span className="fs-1 mt-3 text-danger">*</span>
              </div>
            </div>
            <div className="col-7 ps-1">
              <div>
                <span className="">
                  <input
                    className="form-control border-0 job-regitration-input font-input"
                    name="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    type="email"
                    placeholder={t("editCandProfile.emailAddress").toString()}
                  />
                </span>
                {formik.errors.email && formik.touched.email ? (
                  <small className="text-danger">{formik.errors.email}</small>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-5 align-self-center">
              <div className="d-flex align-items-center line-height-star">
                <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600 w-75">
                  {t("editCandProfile.countryResidence")}
                </label>
                <span className="fs-1 mt-3 text-danger">*</span>
              </div>
            </div>
            <div className="col-7 ps-1">
              <div>
                <span>
                  <CountrySelect
                    value={formik.values.nationality?.trim() || 'DO'}
                    onChange={(code) => {
                      formik.setFieldValue("nationality", code);
                      //formik.setFieldValue("zipCode", code + "-" + formik.values.zipCode.replace(/[^0-9\.]+/g, ""));
                    }}
                  />
                </span>
                {formik.errors.nationality && formik.touched.nationality ? (
                  <small className="text-danger">
                    {formik.errors.nationality}
                  </small>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          {(formik.values.nationality=='' || formik.values.nationality === 'DO')&&
            <>
              <div className="row pt-3">
                <div className="col-5 align-self-center">
                  <div className="d-flex align-items-center line-height-star">
                    <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                      {ct("department")}:
                    </label>
                    {formik.values.nationality === 'DO' &&
                      <span className="fs-1 mt-3 text-danger">*</span>
                    }
                  </div>
                </div>
                <div className="col-7 ps-1">
                  <div>
                    <select
                      id="inputState"
                      name="department"
                      className="form-select form-control fw-500"
                      value={formik.values.department}
                      onChange={formik.handleChange}
                    >
                      <option value="0"> {ct("selectDepartment")} </option>
                      {departments.map((v, i) => (
                        <option key={"%__" + i} value={v.value}>
                          {v.label}
                        </option>
                      ))}
                    </select>
                    {formik.values.nationality === 'DO' && formik.errors.department && formik.touched.department ? (
                      <small className="text-danger">
                        {formik.errors.department}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </>}
          {formik.values.nationality === 'US' &&
            <>
              <div className="row pt-3">
                <div className="col-5">
                  <div>
                    <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                      {ct("state")}:
                    </label>
                  </div>
                </div>
                <div className="col-7 ps-1">
                  <div>
                    <span className="">
                      {/* <input
                        className="form-control border-0 job-regitration-input font-input"
                        name="state"
                        onChange={formik.handleChange}
                        value={formik.values.state}
                        type="text"
                        maxLength={9}
                        pattern="[0-9]*"
                        placeholder={t("editCandProfile.state").toString()}
                      /> */}
                      <select
                        id="inputState"
                        name="state"
                        className="form-select form-control fw-500"
                        value={formik.values.state}
                        onChange={formik.handleChange}
                      >
                        <option value="0"> {ct("selectState")} </option>
                        {states.map((v, i) => (
                          <option key={"%__" + i} value={v.key}>
                            {v.value}
                          </option>
                        ))}
                      </select>

                    </span>
                   {formik.errors.state && formik.touched.state ? (
                  <small className="text-danger">{formik.errors.state}</small>
                ) : (
                  ""
                )} 
                  </div>
                </div>
              </div>
              <div className="row pt-3">
                <div className="col-5">
                  <div>
                    <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                      {ct("zipCode")}:
                    </label>
                  </div>
                </div>
                <div className="col-7 ps-1">
                  <div>
                    <span className="">
                      <input
                        className="form-control border-0 job-regitration-input font-input"
                        name="zipCode"
                        onChange={formik.handleChange}
                        value={formik.values.zipCode}
                        type="text"
                        maxLength={9}
                        pattern="[0-9]*"
                        placeholder={t("editCandProfile.zipCode").toString()}
                      />
                    </span>
                    {/* {formik.errors.zipCode && formik.touched.zipCode ? (
                  <small className="text-danger">{formik.errors.zipCode}</small>
                ) : (
                  ""
                )} */}
                  </div>
                </div>
              </div>
            </>}
          <div className="row pt-3">
            <div className="col-5 align-self-center">
              <div className="d-flex align-items-center line-height-star">
                <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                  {ct("maritalStatus")}:
                </label>
                <span className="fs-1 mt-3 text-danger">*</span>
              </div>
            </div>
            <div className="col-7 ps-1">
              <div>
                <select
                  id="inputState"
                  name="maritalStatus"
                  className="form-select form-control fw-500"
                  value={formik.values.maritalStatus}
                  onChange={formik.handleChange}
                  required
                >
                  <option value=""> {ct("selectMaritalStatus")} </option>
                  <option key={"s_" + 1} value="Single">
                    {" "}
                    {t("editCandProfile.single")}{" "}
                  </option>
                  <option key={"s_" + 2} value="Married">
                    {" "}
                    {t("editCandProfile.married")}{" "}
                  </option>
                  <option key={"s_" + 3} value="Divorced">
                    {" "}
                    {t("editCandProfile.divorced")}
                  </option>
                  <option key={"s_" + 4} value="Separated">
                    {" "}
                    {t("editCandProfile.separated")}
                  </option>
                  <option key={"s_" + 5} value="Widowed">
                    {" "}
                    {t("editCandProfile.widowed")}{" "}
                  </option>
                </select>
                {formik.errors.maritalStatus && formik.touched.maritalStatus ? (
                  <small className="text-danger">
                    {formik.errors.maritalStatus}
                  </small>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-5">
              <div>
                <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                  {t("availability")}:
                </label>
              </div>
            </div>
            <div className="col-7 ps-1">
              <div>
                <span className="">
                  <input
                    className="form-control border-0 job-regitration-input font-input"
                    name="availability"
                    onChange={formik.handleChange}
                    value={formik.values.availability}
                    type="text"
                    placeholder={t("editCandProfile.availability").toString()}
                  />
                </span>
                {/* {formik.errors.availability && formik.touched.availability ? (
                  <small className="text-danger">
                    {formik.errors.availability}
                  </small>
                ) : (
                  ""
                )} */}
              </div>
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-5">
              <div>
                <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                  {t("socialNetwork")}:
                </label>
              </div>
            </div>
            <div className="col-7 ps-1">
              <div className="SocialNetworkRepeater">
                <FormikProvider value={formik}>
                  <FieldArray
                    name="socialNetworks"
                    render={(arrayHelpers) => (
                      <>
                        {formik.values.socialNetworks.map(
                          (item: any, index: number) => (
                            <div key={"sna_=_" + index}>
                              <div className="d-flex align-items-center">
                                <input
                                  className="form-control border-0 job-regitration-input font-input ps-0 pt-0"
                                  type="text"
                                  name={`socialNetworks.${index}.text`}
                                  value={item.text}
                                  onChange={formik.handleChange}
                                  placeholder={t(
                                    "editCandProfile.socialNetwork"
                                  ).toString()}
                                />
                                <FaTimes
                                  onClick={() => arrayHelpers.remove(index)}
                                  className="text-danger cursor-pointer"
                                />
                              </div>
                              {formik.errors &&
                                formik.errors.socialNetworks &&
                                formik.errors.socialNetworks[index] &&
                                formik.errors.socialNetworks[index].text &&
                                formik.touched &&
                                formik.touched.socialNetworks &&
                                formik.touched.socialNetworks[index] &&
                                formik.touched.socialNetworks[index].text && (
                                  <small className="text-danger">
                                    {formik.errors.socialNetworks[index].text}
                                  </small>
                                )}
                            </div>
                          )
                        )}

                        <input
                          onClick={() => arrayHelpers.push({ text: "" })}
                          type="button"
                          value={ct("add").toString()}
                          className="button-default mt-1 border-0"
                        />
                      </>
                    )}
                  />
                </FormikProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SideContent;
