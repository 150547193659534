import { config } from '../../../Environments';
import { parseLinkIcon, parseSocialLinkColor } from '../../../Shared/Helpers';
import { useTranslation } from 'react-i18next';

const SideContent = ({ data }) => {

    const { t } = useTranslation('candidate');
    const { t: ct } = useTranslation('common');

    const alternateFile = "/images/ImgPlaceholder.png";
    const MaleAvatar = '/images/MalePlaceholder.png';
    const FemaleAvatar = '/images/FemalePlaceholder.png';
    return (
        <>
            <div className="">
                <div className="bg-white">
                    <div className="side-img">
                        <div className="img-fluid">
                            <img src={config.apiBase + data?.imageName} alt="candidate profile image" className="w-100"
                                onError={(ev) => {
                                    ev.target.src = (data?.gender === "Male") ? MaleAvatar : FemaleAvatar;

                                }} />
                            {/*    <img className="w-100" src={(config.apiBase + data?.imageName) || alternateFile}
                                onError={(e) => e.target.src = alternateFile} alt="" /> */}
                        </div>
                    </div>
                    <div className="side-content px-3 pb-5">
                        <div>
                            <h6 className="text-center mt-3 fw-600">
                                <a className="text-decoration-none text-color-33 pointer-event" >{data?.firstName} {data?.lastName}</a>
                            </h6>
                        </div>
                        <div className="mt-4 border-bottom border-secondary">
                            <h5 className="text-color-33 fw-600 text-uppercase mb-2"> {t('candProfile.generalInformation')} </h5>
                        </div>
                        <div className="row pt-3">
                            <div className="col-4">
                                <label className="mb-0 fsize-13 fw-600 text-uppercase"> {ct('gender')}: </label>
                            </div>
                            <div className="col-8 px-0">
                                <span className="fsize-13">{data?.gender || 'N/A'}</span>
                            </div>
                        </div>
                        <div className="row pt-3">
                            <div className="col-4">
                                <label className="mb-0 fsize-13 fw-600 text-uppercase">  {t('birthday')}: </label>
                            </div>
                            <div className="col-8 px-0">
                                <span className="fsize-13">{new Date(data?.dob).toLocaleDateString()}</span>
                            </div>
                        </div>
                        <div className="row pt-3">
                            <div className="col-4">
                                <label className="mb-0 fsize-13 fw-600 text-uppercase"> {ct('city')}: </label>
                            </div>
                            <div className="col-8 px-0">
                                <span className="fsize-13">{data?.address || 'N/A'}</span>
                            </div>
                        </div>

                        <div className="row pt-3">
                            <div className="col-4">
                                <label className="mb-0 fsize-13 fw-600 text-uppercase"> {ct('phone')}: </label>
                            </div>
                            <div className="col-8 px-0">
                                <span className="fsize-13"> {data?.phoneNumber || 'N/A'} </span>
                            </div>
                        </div>
                        <div className="row pt-3">
                            <div className="col-4">
                                <label className="mb-0 fsize-13 fw-600 text-uppercase"> {ct('email')}: </label>
                            </div>
                            <div className="col-8 px-0">
                                <span className="fsize-13"> {data?.email || 'N/A'} </span>
                            </div>
                        </div>
                        <div className="row pt-3">
                            <div className="col-4">
                                <label className="mb-0 fsize-13 fw-600 text-uppercase"> {t('nationality')}: </label>
                            </div>
                            <div className="col-8 px-0">
                                <span className="fsize-13"> {data?.nationality || 'N/A'} </span>
                            </div>
                        </div>
                        <div className="row pt-3">
                            <div className="col-4">
                                <label className="mb-0 fsize-13 fw-600 text-uppercase"> {ct('maritalStatus')}: </label>
                            </div>
                            <div className="col-8 px-0">
                                <span className="fsize-13"> {data?.maritalStatus || 'N/A'} </span>
                            </div>
                        </div>
                        <div className="row pt-3">
                            <div className="col-4">
                                <label className="mb-0 fsize-13 fw-600 text-uppercase"> {t('editCandProfile.countryResidence')}: </label>
                            </div>
                            <div className="col-8 px-0">
                                <span className="fsize-13"> {data?.countryResidence || 'N/A'} </span>
                            </div>
                        </div>
                        {data?.countryResidence === 'DO' &&
                            <>
                                <div className="row pt-3">
                                    <div className="col-4">
                                        <label className="mb-0 fsize-13 fw-600 text-uppercase"> {ct('department')}: </label>
                                    </div>
                                    <div className="col-8 px-0">
                                        <span className="fsize-13"> {data?.department || 'N/A'} </span>
                                    </div>
                                </div>
                            </>}
                        {data?.countryResidence === 'US' &&
                            <>
                                <div className="row pt-3">
                                    <div className="col-4">
                                        <label className="mb-0 fsize-13 fw-600 text-uppercase"> {ct('state')}: </label>
                                    </div>
                                    <div className="col-8 px-0">
                                        <span className="fsize-13"> {data?.state || 'N/A'} </span>
                                    </div>
                                </div>
                                <div className="row pt-3">
                                    <div className="col-4">
                                        <label className="mb-0 fsize-13 fw-600 text-uppercase"> {ct('zipCode')}: </label>
                                    </div>
                                    <div className="col-8 px-0">
                                        <span className="fsize-13"> {data?.zipcode || 'N/A'} </span>
                                    </div>
                                </div>
                            </>}
                        <div className="row pt-3">
                            <div className="col-4">
                                <label className="mb-0 fsize-13 fw-600 text-uppercase"> {t('availability')}: </label>
                            </div>
                            <div className="col-8 px-0">
                                <span className="fsize-13"> {data?.availabilty || 'N/A'} </span>
                            </div>
                        </div>
                        {/* <div className="row pt-3">
                            <div className="col-4">
                                <label className="mb-0 fsize-13 fw-600 text-uppercase">WEBSITE:</label>
                            </div>
                            <div className="col-8 px-0">
                                <span className="fsize-13"> www.example.com </span>
                            </div>
                        </div>
                        <div className="row pt-3">
                            <div className="col-4">
                                <label className="mb-0 fsize-13 fw-600 text-uppercase">DEGREE:</label>
                            </div>
                            <div className="col-8 px-0">
                                <span className="fsize-13"> MBA </span>
                            </div>
                        </div> */}
                        <div className="side-cocial">
                            <div className="mt-4 border-bottom border-secondary">
                                <h5 className="text-color-33 fw-600 text-uppercase mb-2"> {t('socialize')} </h5>
                            </div>
                            <div className="social-icons-box pt-3">
                                {
                                    JSON.parse(data?.socialNetworks || '[]')?.map((v, i) => (
                                        <a key={'ssnn__' + i} href={v.text} className={"icon pointer-event text-decoration-none " + parseSocialLinkColor(v?.text?.toLowerCase() || '')}>
                                            {parseLinkIcon(v.text.toLowerCase())}
                                        </a>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SideContent;
