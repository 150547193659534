import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const Register2 = () => {
    const { t: at } = useTranslation('auth');
    return (
        <>
            <div className="process-content">
                <div className="container">
                    <div className="form-mail-verify">
                        <div className="padding-step2">
                            <div className='text-center'>
                                <h1 className='fw-700 h1-title mt-0'>Curriculosi</h1>
                            </div>
                            <div className="row p-mail">
                                <div className="">
                                    <img src="/images/email-icon.png" alt="" className="rounded mx-auto d-block" />
                                </div>
                                <div className="">
                                    <h3 className="text-center h3-desc">{at('confirmAccount.verifyYourEmail')}</h3>
                                </div>
                                <div className="mb-30">
                                    <p className="text-center fw-normal mb-0 fs-18">{at('confirmAccount.inOrderToStart')} <span className='fw-bold'> Curriculosi </span> {at('confirmAccount.confirmAccountAddress')}</p>
                                </div>
                                <div className="mb-30 text-center">
                                    <div className="d-grid">
                                        <Link className="verify-btn text-white text-decoration-none rounded-3" to={'/'} >{at('confirmAccount.verifyEmail')}</Link>
                                    </div>
                                </div>
                                <div className='bottom-width'>
                                </div>
                                <div className="text-center">
                                    <p>{at('confirmAccount.ignoreEmail')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Register2;