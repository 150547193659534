import React, { useState } from 'react';
import moment from 'moment';
import { DefaultProfileImage, SelectableSectionClicked } from '../SharedData';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IoDiamondOutline } from 'react-icons/io5';
import { FaCity, FaPhoneAlt } from 'react-icons/fa';
import { MdOutlineAlternateEmail } from 'react-icons/md';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { FaLink } from 'react-icons/fa';
import { LiaAwardSolid } from 'react-icons/lia';
import { FaLocationDot } from 'react-icons/fa6';
import { BiSolidCity } from "react-icons/bi";
import { GoHomeFill } from 'react-icons/go';

export const Polished = (props: TemplateComponentProps) => {
   const { t } = useTranslation('resume')

   const {
      navigate,
      contactInformation,
      image,
      imageData,
      objectives,
      experiences,
      educations,
      languages,
      skills,
      achievements,
      certificates,
      customSections,
      honours,
      projects,
      publications,
      references,
      additionalSettings,
      stepsInformation,
      onNavigatedToSection,
   } = props

   return (
      <>
         <WrapperDiv additionalSettings={additionalSettings}>
            <div className='template'>
               <div className='preview-template'>
                  <div id='template-full' className='shadow-sm'>
                     <div className='template-main print-mt-0'>
                        <div className='row'>
                           <div className='col-4 p-0'>
                              <div className='sidebar-wrap-polish position-relative'>
                                 {(contactInformation.firstName || contactInformation.lastName) && (
                                    <section
                                       className='name-sec'
                                       onClick={(_) => SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)}
                                    >
                                       <div className='selectable-section'>
                                          <h1 className='temp-polished-name text-wrap text-uppercase text-white'>
                                             <span>{contactInformation.firstName}</span> <span>{contactInformation.lastName}</span>
                                          </h1>
                                       </div>
                                    </section>
                                 )}

                                 {stepsInformation.findIndex((x) => x.title == 'Education') > -1 && educations.length > 0 ? (
                                    <section
                                       className='education-sec mt-3'
                                       onClick={(_) => SelectableSectionClicked('education', navigate, onNavigatedToSection)}
                                    >
                                       <div className='prague-section-lh'>
                                          <p className='text-uppercase temp-polished-heading fw-600 mb-3'>{t('Education')}</p>
                                          <div className='row selectable-section'>
                                             {educations.map((e, i) => (
                                                <div className='col-12' key={'edu_' + i}>
                                                   <div className='edu-detail'>
                                                      <p className='fw-700 parague-exp-sec-occupation'>{e.degree}</p>
                                                      {e.institute && <p className=''>{e.institute}</p>}
                                                      <div className='d-flex align-items-center justify-content-between fw-500'>
                                                         <p className=''>
                                                            {e.startDate || e.completeDate ? (
                                                               <>
                                                                  {e.startDate ? (
                                                                     <span>
                                                                        {new Date(e.startDate).toLocaleString('default', {
                                                                           month: 'short',
                                                                        })}{' '}
                                                                        {new Date(e.startDate).getFullYear()}
                                                                     </span>
                                                                  ) : (
                                                                     <></>
                                                                  )}
                                                                  {e.completeDate && !e.currentlyStudying ? (
                                                                     <span className='barline'>
                                                                        {new Date(e.completeDate).toLocaleString('default', {
                                                                           month: 'short',
                                                                        })}{' '}
                                                                        {new Date(e.completeDate).getFullYear()}
                                                                     </span>
                                                                  ) : (
                                                                     <></>
                                                                  )}

                                                                  {e.currentlyStudying ? (
                                                                     <em className='barline'>{t('present')} </em>
                                                                  ) : (
                                                                     <></>
                                                                  )}
                                                               </>
                                                            ) : (
                                                               <></>
                                                            )}
                                                         </p>
                                                         {e.location && <p>{e.location}</p>}
                                                      </div>
                                                      {e.description && (
                                                         <p
                                                            className='sec-value my-2 editor-list-margin-left text-white polished-desc'
                                                            dangerouslySetInnerHTML={{ __html: e.description }}
                                                         ></p>
                                                      )}
                                                   </div>
                                                </div>
                                             ))}
                                          </div>
                                       </div>
                                    </section>
                                 ) : (
                                    <></>
                                 )}

                                 {stepsInformation.findIndex((x) => x.title == 'Languages') > -1 && (
                                    <section
                                       className='languages-sec'
                                       onClick={(_) => SelectableSectionClicked('languages', navigate, onNavigatedToSection)}
                                    >
                                       <div className='mt-3'>
                                          <p className='text-uppercase temp-polished-heading fw-600 mb-3'>{t('Languages')}</p>
                                          <div className='selectable-section'>
                                             <div className='row'>
                                                <div className='col-12'>
                                                   {languages.length > 0 &&
                                                      languages.map((s, i) => (
                                                         <div key={'languages_' + s.name + s.id + i} className='lang-card fs-12 mb-2'>
                                                            {s.name && <p className='text-white fw-600 fs-14'>{s.name}</p>}
                                                            <p className='lh-sm'>
                                                               {s.level && <span>{s.level}</span>}{' '}
                                                               {s.course && <span className='vertical-linebar'>{s.course}</span>}
                                                            </p>
                                                         </div>
                                                      ))}
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </section>
                                 )}

                                 {stepsInformation.findIndex((x) => x.title == 'Certifications & Courses') > -1 &&
                                    certificates.length > 0 && (
                                       <section
                                          className='Certification-sec'
                                          onClick={(_) =>
                                             SelectableSectionClicked('certifications-courses', navigate, onNavigatedToSection)
                                          }
                                       >
                                          <div className='mt-3'>
                                             <p className='text-uppercase temp-polished-heading fw-600 mb-3'>{t('Certifications & Courses')}</p>
                                             <div className='selectable-section'>
                                                {certificates.map((v, i) => (
                                                   <p
                                                      className='sec-value my-2 editor-list-margin-left text-white polished-desc'
                                                      key={'certificates__' + i}
                                                      dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                                   ></p>
                                                ))}
                                             </div>
                                          </div>
                                       </section>
                                    )}

                                 {stepsInformation.findIndex((x) => x.title == 'Honors & Awards') > -1 && honours.length > 0 && (
                                    <section
                                       className='Honors-Award-sec'
                                       onClick={(_) => SelectableSectionClicked('honors-awards', navigate, onNavigatedToSection)}
                                    >
                                       <div className='mt-3'>
                                          <p className='text-uppercase temp-polished-heading fw-600 mb-3'>{t('Honors & Awards')}</p>
                                          <div className='row'>
                                             <div className='col-12'>
                                                <div className='selectable-section'>
                                                   {honours.map((v, i) => (
                                                      <div
                                                         className='d-flex align-items-start justify-content-start mb-2'
                                                         key={'honour__' + i}
                                                      >
                                                         <span>
                                                            <LiaAwardSolid className='text-white me-2' size={22} />
                                                         </span>
                                                         <div className='detail-achivements'>
                                                            <p
                                                               className='sec-value editor-list-margin-left text-white polished-desc'
                                                               dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                                            ></p>
                                                         </div>
                                                      </div>
                                                   ))}
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </section>
                                 )}

                                 <section className='Custom-sec'>
                                    <div className='mt-3'>
                                       <p className='text-uppercase temp-polished-heading fw-600 mb-3'></p>
                                       <div className='row'>
                                          <div className='col-12 selectable-section'>
                                             <div className=''>
                                                {stepsInformation.findIndex((x) => x.title == 'Custom sections') > -1 &&
                                                   customSections.map((v, i) => (
                                                      <div
                                                         key={'custom__' + i}
                                                         className='d-flex align-items-start justify-content-start mb-2'
                                                         onClick={(_) =>
                                                            SelectableSectionClicked('custom-sections', navigate, onNavigatedToSection)
                                                         }
                                                      >
                                                         <span>
                                                            <IoDiamondOutline className='text-white me-2' size={22} />
                                                         </span>
                                                         <div className='custom-sec-detail'>
                                                            <span className='text-white fw-600 fs-14'>{v.title}</span>
                                                            <p
                                                               className='sec-value editor-list-margin-left text-white polished-desc'
                                                               dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                                            ></p>
                                                         </div>
                                                      </div>
                                                   ))}
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </section>
                              </div>
                           </div>
                           <div className='col-8'>
                              <div className='right-sec-polished p-4'>
                                 <section
                                    className='contact-info-sec'
                                    onClick={(_) => SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)}
                                 >
                                    {contactInformation.occupation && (
                                       <div className='selectable-section'>
                                          <p className='fw-700 fs-20 text-polished mb-2'>{contactInformation.occupation}</p>
                                       </div>
                                    )}

                                    {Object.values(contactInformation).filter((s) => s).length > 0 && (
                                       <ul className='list-unstyled d-flex flex-wrap justify-content-start gap-2 selectable-section'>
                                          <li className='mb-1'>
                                             <div className='d-flex align-items-center'>
                                                <FaPhoneAlt className='text-gray me-1' size={15} />
                                                <p className='text-decoration-none text-black fw-600 fs-13'>{contactInformation.phone}</p>
                                             </div>
                                          </li>
                                          <li className='mb-1'>
                                             <div className='d-flex align-items-center'>
                                                <MdOutlineAlternateEmail className='text-gray me-1' size={15} />
                                                <p className='text-decoration-none text-black fw-600 fs-13'>{contactInformation.email}</p>
                                             </div>
                                          </li>
                                          <li className='mb-1'>
                                             <div className='d-flex align-items-center'>
                                                <FaRegCalendarAlt className='text-gray me-1' size={15} />
                                                <p className='text-decoration-none text-black fw-600 fs-13'>
                                                   {moment(contactInformation.dob).format('MM/DD/YYYY')}
                                                </p>
                                             </div>
                                          </li>
                                          <li className='mb-1'>
                                             <div className='d-flex align-items-center'>
                                                <FaLink className='text-gray me-1' size={15} />
                                                <p className='text-decoration-none text-black fw-600 fs-13'>
                                                   {contactInformation.linkedInLink}
                                                </p>
                                             </div>
                                          </li>
                                          <li className='mb-1'>
                                             <div className='d-flex align-items-center'>
                                                <GoHomeFill className='text-gray me-1' size={15} />
                                                <p className='text-decoration-none text-black fw-600 fs-13'>{contactInformation.address}</p>
                                             </div>
                                          </li>
                                          <li className='mb-1'>
                                             <div className='d-flex align-items-center'>
                                                <FaLocationDot className='text-gray me-1' size={15} />
                                                <p className='text-decoration-none text-black fw-600 fs-13'>{contactInformation.address2}</p>
                                             </div>
                                          </li>
                                          <li className='mb-1'>
                                             <div className='d-flex align-items-center'>
                                                <BiSolidCity className='text-gray me-1' size={15} />
                                                <p className='text-decoration-none text-black fw-600 fs-13'>{contactInformation.city}</p>
                                             </div>
                                          </li>
                                       </ul>
                                    )}
                                 </section>

                                 {stepsInformation.findIndex((x) => x.title == 'Objective') > -1 && objectives && (
                                    <section
                                       className='objective-sec'
                                       onClick={(_) => SelectableSectionClicked('objective', navigate, onNavigatedToSection)}
                                    >
                                       <div className='mt-4'>
                                          <p className='text-uppercase polished-title fw-600 mb-16'>{t('Objective')}</p>
                                          <div className='selectable-section'>
                                             <p
                                                className='sec-value my-2 editor-list-margin-left'
                                                dangerouslySetInnerHTML={{ __html: objectives }}
                                             ></p>
                                          </div>
                                       </div>
                                    </section>
                                 )}

                                 {stepsInformation.findIndex((x) => x.title == 'Skills') > -1 && (
                                    <section
                                       className='skills-sec'
                                       onClick={(_) => SelectableSectionClicked('skills', navigate, onNavigatedToSection)}
                                    >
                                       <div className='mt-3'>
                                          <p className='text-uppercase polished-title fw-600 mb-16'>{t('Skills')}</p>
                                          <div className='lh-base selectable-section'>
                                             <ul className='ps-4 fs-12 d-flex flex-wrap'>
                                                {skills.length > 0 &&
                                                   skills.map((s, i) => (
                                                      <li key={'skill_' + s.name + s.id + i} className='mb-1 me-4'>
                                                         {s.name}
                                                      </li>
                                                   ))}
                                             </ul>
                                          </div>
                                       </div>
                                    </section>
                                 )}

                                 {stepsInformation.findIndex((x) => x.title == 'Experiences') > -1 && experiences.length > 0 ? (
                                    <section
                                       className='experience-sec'
                                       onClick={(_) => SelectableSectionClicked('work-experience', navigate, onNavigatedToSection)}
                                    >
                                       <div className='prague-section-lh mt-3'>
                                          <p className='text-uppercase polished-title fw-600 mb-16'>{t('Experiences')}</p>
                                          <div className='row selectable-section'>
                                             {experiences.map((e, i) => (
                                                <div className='col-12' key={'exp_' + i}>
                                                   <div className='edu-detail'>
                                                      <div className='d-flex align-items-center justify-content-between'>
                                                         <div>
                                                            <p className='fw-700 fs-16 text-polished'>{e.title}</p>
                                                            {e.company && <p className='fw-500 fs-14 text-gray'>{e.company}</p>}
                                                         </div>
                                                         <div className='fw-500 fs-14'>
                                                            {e.location && <p>{e.location}</p>}
                                                            <p className='fst-italic'>
                                                               {e.startDate || e.endDate ? (
                                                                  <>
                                                                     {e.startDate ? (
                                                                        <span>
                                                                           {new Date(e.startDate).toLocaleString('default', {
                                                                              month: 'short',
                                                                           })}{' '}
                                                                           {new Date(e.startDate).getFullYear()}
                                                                        </span>
                                                                     ) : (
                                                                        <></>
                                                                     )}
                                                                     {e.endDate && !e.currentlyWorking ? (
                                                                        <span className='barline'>
                                                                           {new Date(e.endDate).toLocaleString('default', {
                                                                              month: 'short',
                                                                           })}{' '}
                                                                           {new Date(e.endDate).getFullYear()}
                                                                        </span>
                                                                     ) : (
                                                                        <></>
                                                                     )}

                                                                     {e.currentlyWorking ? (
                                                                        <em className='barline'>{t('present')} </em>
                                                                     ) : (
                                                                        <></>
                                                                     )}
                                                                  </>
                                                               ) : (
                                                                  <></>
                                                               )}
                                                            </p>
                                                         </div>
                                                      </div>
                                                      <div className=''>
                                                         {e.description && (
                                                            <p
                                                               className='sec-value my-2 editor-list-margin-left template-prague-desc'
                                                               dangerouslySetInnerHTML={{ __html: e.description }}
                                                            ></p>
                                                         )}
                                                      </div>
                                                   </div>
                                                </div>
                                             ))}
                                          </div>
                                       </div>
                                    </section>
                                 ) : (
                                    <></>
                                 )}

                                 {stepsInformation.findIndex((x) => x.title == 'Projects') > -1 && projects.length > 0 && (
                                    <section
                                       className='Project-sec'
                                       onClick={(_) => SelectableSectionClicked('projects', navigate, onNavigatedToSection)}
                                    >
                                       <div className='mt-3'>
                                          <p className='text-uppercase polished-title fw-600 mb-16'>{t('Projects')}</p>
                                          <div className='selectable-section'>
                                             {projects.map((v, i) => (
                                                <p
                                                   className='sec-value my-2 editor-list-margin-left'
                                                   key={'projects__' + i}
                                                   dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                                ></p>
                                             ))}
                                          </div>
                                       </div>
                                    </section>
                                 )}

                                 {stepsInformation.findIndex((x) => x.title == 'Publications') > -1 && publications.length > 0 && (
                                    <section
                                       className='Publication-sec'
                                       onClick={(_) => SelectableSectionClicked('publications', navigate, onNavigatedToSection)}
                                    >
                                       <div className='mt-3'>
                                          <p className='text-uppercase polished-title fw-600 mb-16'>{t('Publications')}</p>
                                          <div className='selectable-section'>
                                             {publications.map((v, i) => (
                                                <p
                                                   className='sec-value my-2 editor-list-margin-left'
                                                   key={'publication__' + i}
                                                   dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                                ></p>
                                             ))}
                                          </div>
                                       </div>
                                    </section>
                                 )}

                                 {stepsInformation.findIndex((x) => x.title == 'Achievements') > -1 && achievements.length > 0 && (
                                    <section
                                       className='Achievements-sec'
                                       onClick={(_) => SelectableSectionClicked('achievements', navigate, onNavigatedToSection)}
                                    >
                                       <div className='mt-3'>
                                          <p className='text-uppercase polished-title fw-600 mb-16'>{t('Achievements')}</p>
                                          <div className='row'>
                                             <div className='col-12'>
                                                <div className='selectable-section'>
                                                   {achievements.map((v, i) => (
                                                      <div
                                                         className='d-flex align-items-start justify-content-start mb-2'
                                                         key={'achievements__' + i}
                                                      >
                                                         <span className='diamond-icon-polished me-2 d-flex align-items-center justify-content-center'>
                                                            <IoDiamondOutline className='text-dark' size={16} />
                                                         </span>
                                                         <div className='detail-achivements'>
                                                            <p
                                                               className='sec-value editor-list-margin-left'
                                                               dangerouslySetInnerHTML={{ __html: v.content || '' }}
                                                            ></p>
                                                         </div>
                                                      </div>
                                                   ))}
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </section>
                                 )}

                                 {stepsInformation.findIndex((x) => x.title == 'References') > -1 && references.length > 0 && (
                                    <section
                                       className='References-sec'
                                       onClick={(_) => SelectableSectionClicked('references', navigate, onNavigatedToSection)}
                                    >
                                       <div className='mt-3'>
                                          <p className='text-uppercase polished-title fw-600 mb-16'> {t('References')}</p>
                                          <div className='selectable-section'>
                                             <div className='d-grid display-grid-custom'>
                                                {references.map((v, i) => (
                                                   <div className='' key={'custom_' + i}>
                                                      <div className='lang-card fs-12 mb-2'>
                                                         <p className='mb-1 fs-15 text-polished fw-600'>{v.name}</p>
                                                         <p className='mb-1 fw-500'>{v.city}</p>
                                                         <p className='mb-1 fw-500'>{v.tel}</p>
                                                      </div>
                                                   </div>
                                                ))}
                                             </div>
                                          </div>
                                       </div>
                                    </section>
                                 )}
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </WrapperDiv>
      </>
   )
}

const WrapperDiv = styled.div`
   & .sec-value,
   .sec-value * {
      line-height: ${(props: { additionalSettings: AdditionalSettings }) =>
      !!props?.additionalSettings?.lineHeight && props?.additionalSettings?.lineHeight};
      font-size: ${(props: { additionalSettings: AdditionalSettings }) =>
      !!props?.additionalSettings?.fontSize && props?.additionalSettings?.fontSize + ' !important'};
   }

   & .sidebar-wrap-polish {
      background-color: ${(props: { additionalSettings: AdditionalSettings }) =>
      !!props?.additionalSettings?.color && props.additionalSettings.color} !important;
   }

   & .text-polished {
      color: ${(props: { additionalSettings: AdditionalSettings }) =>
      !!props?.additionalSettings?.color && props.additionalSettings.color} !important;
   }

   & * {
      font-family: ${(props: { additionalSettings: AdditionalSettings }) =>
      !!props?.additionalSettings?.fontFamily && props?.additionalSettings?.fontFamily === 'Classic'
         ? 'Noto Serif, serif;'
         : props?.additionalSettings?.fontFamily === 'Modern'
            ? 'Rubik, sans-serif;'
            : props?.additionalSettings?.fontFamily === 'Elegant'
               ? 'Libre Baskerville, serif;'
               : props?.additionalSettings?.fontFamily === 'Tech'
                  ? 'Roboto Mono, monospace;'
                  : props?.additionalSettings?.fontFamily === 'Professional'
                     ? 'Bitter, serif;'
                     : 'default;'};
   }
`
