import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { config } from "../../../../../../Environments";
import { GetAxios, reorder } from "../../../../../../Shared";
import { setGlobalLoading } from "../../../../../../Store";
import { setResumeActive, setResumeAddRecord, setResumeRemoveRecord, setResumeReordered } from "../../../../../../Store/Slices/ResumeSlice";
import { AddAnother } from "../../../../Components/AddAnother";
import { BottomButtons } from "../../BottomButtons";
import { FaPencilAlt, FaRegTrashAlt, FaArrowsAlt } from "react-icons/fa";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip, { TooltipProps } from 'react-bootstrap/Tooltip';
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from 'react-i18next';

export const EducationList = () => {

    const { t } = useTranslation('resume');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { resumeId } = useParams();

    const educations = useSelector((state: AppStore) => state.resume.educations);

    const addEducations = () => {
        dispatch(setGlobalLoading(true));
        GetAxios(navigate, dispatch)
            .post(config.apiBase + '/api/Resume/SaveEducation', { resumeId: resumeId })
            .then(s => {
                dispatch(setResumeAddRecord({
                    educations: {
                        id: s.data.data,
                        resumeId: Number(resumeId)
                    }
                }));
                dispatch(setResumeActive({}));
                navigate('edit/' + s.data.data);
                dispatch(setGlobalLoading(false));
            });
    };

    const Delete = (s: ResumeEducation) => {
        confirmAlert({
            title: t('newResumeCard.deleteResumeMsg.title').toString(),
            message: t('newResumeCard.deleteResumeMsg.description').toString(),
            closeOnClickOutside: false,
            closeOnEscape: false,
            buttons: [
                {
                    label: t('yes').toString(),
                    className: 'bg-danger',
                    onClick: _ => {
                        GetAxios(navigate, dispatch).delete(config.apiBase + '/api/Resume/DeleteRecord', {
                            params: {
                                id: s.id,
                                resumeId,
                                type: 'Education'
                            }
                        }).then(res => {
                            dispatch(setResumeRemoveRecord({ educations: s }));
                        })
                    }
                },
                {
                    label: t('no').toString(),
                    onClick: _ => {
                        return
                    }
                }
            ]
        })
    }

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="subsection-container">
                        <div className="mb-3">
                            <div className="dZcjzV sections-header">
                                <div className="feVKyO"> {t('educationStep.educationTitle')}?
                                </div>
                            </div>
                            <div className="gJaquO"> {t('educationStep.educationDesc')}.
                            </div>
                        </div>
                        <div className="gQiRPX">
                            <div className="fFIUJl sections-header">
                                <div className="dJqmzX">
                                    <div className="hfzZao">
                                        <div className="czFa-Df"> {t('educationStep.education')} </div>
                                    </div>
                                    <svg className="hMpiDu" focusable="false" viewBox="0 0 24 24" aria-hidden="true" >
                                        <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path>
                                    </svg>
                                </div>
                            </div>
                            <div className="gBqHRE"></div>
                        </div>
                        <div className="cards-main">
                            <div className="sections-cards">
                                <DragDropContext onDragEnd={e => {
                                    if (!e.destination) {
                                        return;
                                    }

                                    const items = reorder(educations, e.source.index, e.destination.index);

                                    dispatch(setResumeReordered({ educations: items }));
                                    GetAxios(navigate, dispatch).patch(config.apiBase + '/api/Resume/UpdateSortOrder', items.map(s => s.id), {
                                        params: {
                                            resumeId: resumeId,
                                            type: 'Education'
                                        }
                                    });
                                }}>
                                    <Droppable droppableId="droppable">
                                        {
                                            (provider, _) => (
                                                <div {...provider.droppableProps}
                                                    ref={provider.innerRef}>
                                                    {
                                                        educations.map((s, i) =>
                                                            <Draggable key={"i_" + s.id} draggableId={s.id + '1'} index={i}>
                                                                {(provided, _) => (
                                                                    <div className="cards-list" ref={provided.innerRef} {...provided.draggableProps}>
                                                                        <div className="sections-cards-Card">
                                                                            <div className="card-wrapper">
                                                                                <div className="section-card-content">
                                                                                    <div className="MuiCardHeader-root" >
                                                                                        <div className="MuiCardHeader-content">
                                                                                            <h5 className="title-exper text-capitalize mb-0">{s.degree}</h5>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="MuiCardContent-root" style={{ maxLines: 1 }}>{s.institute} .{s.location}
                                                                                        {/* {s.startDate && <span>
                                                                                            {new Date(s.startDate).getMonth()} {new Date(s.startDate).getFullYear()}
                                                                                        </span>
                                                                                        }
                                                                                        {s.completeDate && <span>
                                                                                            {new Date(s.completeDate).getMonth()} {new Date(s.completeDate).getFullYear()}
                                                                                        </span>
                                                                                        } */}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="edit-icon d-flex align-items-center">
                                                                                    <Link to={''} onClick={() => Delete(s)}>
                                                                                        <span className="">
                                                                                            <FaRegTrashAlt className="icon-gray" size={17} />
                                                                                        </span>
                                                                                    </Link>
                                                                                    <Link to={'edit/' + s.id}>
                                                                                        <span className="mx-4">
                                                                                            <FaPencilAlt className="icon-gray" size={18} />
                                                                                        </span>
                                                                                    </Link>
                                                                                    <div {...provided.dragHandleProps}>
                                                                                        <span>
                                                                                            <OverlayTrigger
                                                                                                overlay={<Tooltip>{t('dragDropRecord')}</Tooltip>}
                                                                                                placement="top">
                                                                                                {({ ref, ...triggerHandler }) => (
                                                                                                    <span className="cursor-pointer" {...triggerHandler} ref={ref}><FaArrowsAlt className="icon-gray" size={18} /></span>
                                                                                                )}
                                                                                            </OverlayTrigger>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        )
                                                    }
                                                </div>
                                            )
                                        }
                                    </Droppable>
                                </DragDropContext>
                                <AddAnother action={addEducations} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <BottomButtons onSubmit={e => e()} />
        </>
    );
};
