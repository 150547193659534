import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GiStarsStack } from "react-icons/gi";
import { BiPrinter } from "react-icons/bi";
import { TbSettingsStar } from "react-icons/tb";
import { LiaEdit } from "react-icons/lia";
import { GoDotFill } from "react-icons/go";
import { GetAxios } from "../../../Shared";
import { config } from "../../../Environments";

export const ResumePlan = () => {

    const { t } = useTranslation('plan');
    const navigate = useNavigate();
    const [numberOfPeople, setNumberOfPeople] = useState(1497);
    const [selectedOption, setSelectedOption] = useState(3);
    const [searchParams] = useSearchParams();

    // Function to increase the number each day
    // const increaseNumber = () => { setNumberOfPeople(prevNumber => prevNumber + 3); };
    React.useEffect(() => {
        // Calculating the time difference
        let Difference_In_Time = new Date().getTime() - new Date("05/04/2024").getTime();
        let Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
        setNumberOfPeople(prevNumber => prevNumber + 3 * Difference_In_Days);
        //increaseNumber();
        // Set up an interval to run the function once per day
        //const intervalId = setInterval(increaseNumber, 24 * 60 * 60 * 1000); // 24 hours in milliseconds

        // Clear the interval when the component unmounts
        // return () => clearInterval(intervalId);
    }, []); // Empty dependency array to run the effect only once
 
    const [currency, setCurrency]=useState<string>("USD");
    const [exchangeRate, setExchangeRate]=useState<number>(1);
    React.useEffect(() => {
        //get user currency if is not usd then find exchange rate
        GetAxios().get(config.apiBase + '/api/General/GetCurrency')
            .then(res => {
                setCurrency(res.data.data);
                if (res.data.data != "USD") {
                    GetAxios().get(config.apiBase + '/api/General/GetExchangeRate?baseCurrency=USD&targetCurrency=DOP')
                        .then(res => {
                            if (res.data.success) {
                                setExchangeRate(res.data.data); 
                            }
                        })
                }
            })
    }, []);
    const FormatCurrency=(price:number, currency:string)=> {
        let neg = false;
         if (price < 0) {
             neg = true;
             price = Math.abs(price);
         }
         return  currency+ price.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString();
     }
    return (
        <>
            <div className="my-4 mx-auto">
                <div className="container pb-5 resume-plan-sec-sec">
                    <div className="card">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="my-4 text-center mx-2">
                                    <h2 className="fw-700">{t("resume.planHeading")}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row py-3 px-lg-5 px-3 g-4 g-lg-4 g-md-4">
                            <div className="col-md-6 col-12">
                                <Card>
                                    <ListGroup variant="flush" defaultActiveKey="#link1" className="list-group-border-none">
                                        <ListGroup.Item action href="#link1" className="px-4 py-3 fw-600 d-flex align-items-center gap-2 fs-18">
                                            {numberOfPeople}  {t("resume.peopleJoin")}
                                            <GiStarsStack size={22} />
                                        </ListGroup.Item>
                                        <ListGroup.Item className="py-2">
                                            <div className="d-flex align-items-center feedback-web-checkbox gap-3 gap-lg-5 justify-content-center justify-content-lg-between">
                                                <div className="d-flex align-items-center">
                                                    <p className="form-check-label fs-20 fw-700">
                                                        14 {t("resume.silverAccess")}
                                                    </p>
                                                    <p className="plan-label-radio mb-0 ms-2">{currency=="USD"?"$2.95":FormatCurrency(2.95 * exchangeRate,"RD$")}</p>
                                                </div>
                                                <div>
                                                    <input className="form-check-input feedback-input cursor-pointer" type="checkbox" id="gold" checked={selectedOption == 3} name="SelectedPlan" value={3}
                                                        onChange={(e: any) => { setSelectedOption(e.target.value); }}></input>
                                                </div>
                                            </div>
                                        </ListGroup.Item>
                                        <div className="bor-bottom-plan-account"></div>
                                        <div className="px-xl-3 px-lg-3 px-md-3 pb-32px bg-plan-card pt-3 rounded-2">
                                            <ListGroup.Item className="bg-plan-card mb-1">
                                                <div className="d-flex align-items-start gap-3">
                                                    <BiPrinter size={20} className="plan-icon-color" />
                                                    <div>
                                                        <p className="mb-0 fw-600 text-body-secondary">
                                                            {t("resume.download")} (PDF  {t("resume.and")} <br />
                                                            DOCX)
                                                        </p>
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="bg-plan-card mb-1">
                                                <div className="d-flex align-items-start gap-3">
                                                    <TbSettingsStar size={20} className="plan-icon-color" />
                                                    <div>
                                                        <p className="mb-0 fw-600 text-body-secondary">
                                                            {t("resume.unlimitedCustom")}
                                                        </p>
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="bg-plan-card mb-1">
                                                <div className="d-flex align-items-start gap-3">
                                                    <LiaEdit size={20} className="plan-icon-color" />
                                                    <div>
                                                        <p className="mb-0 fw-600 text-body-secondary">
                                                            {t("resume.unlimitedCVCreate")}
                                                        </p>
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                        </div>
                                    </ListGroup>
                                </Card>
                            </div>
                            <div className="col-md-6 col-12">
                                <Card>
                                    <ListGroup variant="flush" className="list-group-border-none">
                                        <ListGroup.Item className="py-2">
                                            <div className="d-flex align-items-center feedback-web-checkbox gap-3 gap-lg-5 justify-content-center justify-content-lg-between">
                                                <div className="d-flex align-items-center">
                                                    <p className="form-check-label fw-700 fs-20">
                                                        {t("resume.goldAccess")}
                                                    </p>
                                                    <p className="plan-label-radio mb-0 ms-2">{currency=="USD"?"$14.95": FormatCurrency(14.95 * exchangeRate,"RD$")}</p>
                                                </div>
                                                <div>
                                                    <input className="form-check-input feedback-input cursor-pointer" type="checkbox" id="gold" checked={selectedOption == 2} name="SelectedPlan" value={2}
                                                        onChange={(e: any) => { setSelectedOption(e.target.value); }}></input>
                                                </div>
                                            </div>
                                        </ListGroup.Item>
                                        <div className="bor-bottom-plan-account"></div>
                                        <div className="px-xl-3 px-lg-3 px-md-3 pb-32px pt-3 bg-plan-card rounded-2">
                                            <ListGroup.Item className="bg-plan-card mb-2">
                                                <div className="d-flex align-items-center gap-3">
                                                    <GoDotFill size={14} className="text-black-50" />
                                                    <div>
                                                        <p className="mb-0 fw-600 text-body-secondary lh-sm">
                                                            {t("resume.unlimitedAcess")} <br /> {t("resume.futureUpdate")}
                                                        </p>
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="bg-plan-card mb-2">
                                                <div className="d-flex align-items-center gap-3">
                                                    <GoDotFill size={14} className="text-black-50" />
                                                    <div>
                                                        <p className="mb-0 fw-600 text-body-secondary">
                                                            45   {t("resume.fullAccess")}
                                                        </p>
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="bg-plan-card mb-2">
                                                <div className="d-flex align-items-center gap-3">
                                                    <GoDotFill size={14} className="text-black-50" />
                                                    <div>
                                                        <p className="mb-0 fw-600 text-body-secondary">
                                                            {t("resume.print")}
                                                        </p>
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item className="bg-plan-card mb-2">
                                                <div className="d-flex align-items-center gap-3">
                                                    <GoDotFill size={14} className="text-black-50" />
                                                    <div>
                                                        <p className="mb-0 fw-600 text-body-secondary">
                                                            {t("resume.editReturn")}
                                                        </p>
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                        </div>
                                    </ListGroup>
                                </Card>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="my-5 mx-auto text-center">
                                    <button type="submit" onClick={() => {
                                        navigate(
                                            "/employee-account/resume-plan-checkout?rId=" + selectedOption+"&t="+searchParams.get('t')+"&r="+searchParams.get('r')
                                        );
                                    }} className="border-0 rounded-3 btn btn-danger contine-btn-padding text-white fw-500">
                                        {t("resume.continue")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
