import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

const WrapperDiv = styled.div`
display: flex;
-webkit-box-pack: center;
justify-content: center;
-webkit-box-align: center;
align-items: center;
flex-direction: column;
padding-top: 2rem;
`
const Title = styled.div`
color: rgb(30, 32, 33);
font-size: 32px;
line-height: 40px;
font-weight: 500;
margin: 0px 16px;
`

const SubTitle = styled.div`
color: rgb(150, 155, 158);
font-size: 16px;
line-height: 24px;
font-weight: 500;
text-align: center;
margin: 8px 16px 0px;
`

const StickyHeaderWrapper = styled.div`

`
const AdjustInCenter = styled.div`
// height: 70px;
margin: 0 auto 0;
flex-grow: 1;
background-color: #fff;
`
const TabButtons = styled.div`
background-color:transparent;
padding:0px 20px;
outline:none !important;
font-size:20px;
color: ${(props: { active?: boolean }) => props.active ? "#1e2021" : "#969b9e"};
min-height: 48px;
display: flex;
align-items: center;
${(props) => props.active && `border-bottom : 4px solid #0083e4`};
cursor:pointer;
@media only screen and (max-width:473px) {
  padding:0px 10px;
}
@media only screen and (max-width:390px) {
  font-size:16px;
}
`
const ShowTemplateListWrapper = styled.div`
background-color: rgb(248, 249, 250);
`

const TemplateHTMLHeader = styled.div`
display: flex;
    margin-bottom: 15px;
    -webkit-box-align: center;
    align-items: center;
    min-height: 26px;
`
const TemplateHTMLHeaderTitle = styled.div`
color: rgb(90, 94, 97);
    font-family: Roboto, sans-serif;
    letter-spacing: 1.5px;
    line-height: 1.67;
    font-size: 12px;
    text-transform: uppercase;
`
const TemplateHTMLHeaderBadge = styled.div`
    font-family: Roboto, sans-serif;
    font-size: 11px;
    line-height: 1.82;
    letter-spacing: 1.38px;
    text-align: center;
    color: rgb(0, 144, 242);
    margin: 0px 0px 0px 12px;
    padding: 2px 12px;
    border-radius: 24px;
    border: 1px solid rgb(183, 220, 250);
    background-color: rgb(225, 241, 253);
`
const RESUMEBGIMAGE = styled.div`
position: relative;
// min-width: 328px;
min-height: 450px;
height: auto;
border-radius: 4px;
background-image:url(${(props: { bgImage?: string }) => props?.bgImage});
background-repeat: no-repeat;
background-size: 100%;
box-shadow: rgb(181 186 189) 0px 1px 4px 0px, rgb(231 235 238) 0px 3px 8px 0px;
margin-bottom: 16px;
cursor: pointer;
display: flex;
-webkit-box-pack: center;
justify-content: center;
@media only screen and (max-width: 313px) and (min-width: 280px) {
  min-height: 300px;
}
@media only screen and (max-width: 339px) and (min-width: 314px) {
  min-height: 320px;
}
@media only screen and (max-width: 359px) and (min-width: 340px) {
  min-height: 350px;
}
@media only screen and (max-width: 379px) and (min-width: 360px) {
  min-height: 390px;
}
@media only screen and (max-width: 399px) and (min-width: 380px) {
  min-height: 400px;
}
@media only screen and (max-width: 439px) and (min-width: 400px) {
  min-height: 405px;
}
@media only screen and (max-width: 500px) and (min-width: 440px) {
  min-height: 500px;
}
  @media only screen and (min-width:550px) {
    min-height: 605px;
  }
  @media only screen and (min-width:600px) {
    min-height: 610px;
  }
  @media only screen and (min-width:650px) {
    min-height: 625px;
  }
  @media only screen and (max-width: 767px) and (min-width: 651px) {
    min-height: 610px;
  }
  @media only screen and (max-width: 992px) and (min-width: 768px) {
    min-height: 410px;
  }
  @media only screen and (max-width: 1180px) and (min-width: 993px) {
    min-height: 380px;
  }
  @media only screen and (max-width: 1280px) and (min-width: 1181px) {
    min-height: 440px;
  }
  @media only screen and (max-width: 1380px) and (min-width: 1281px) {
    min-height: 448px;
  }
  @media only screen and (max-width: 1500px) and (min-width: 1381px) {
    min-height: 450px;
  }
  @media only screen and (max-width: 1700px) and (min-width: 1501px) {
    min-height: 450px;
  }
  @media only screen and (max-width: 1900px) and (min-width: 1701px) {
    min-height: 450px;
  }
  @media only screen and (max-width: 2100px) and (min-width: 1901px) {
    min-height: 450px;
  }
  @media only screen and (max-width: 2400px) and (min-width: 2101px) {
    min-height: 450px;
  }
  @media only screen and (max-width: 2700px) and (min-width: 2401px) {
    min-height: 450px;
  }
`

const HoverOverlay = styled.div`
&:hover .showOverlayButton{
    opacity:1;
}
`
const OverlayButtonWrapper = styled.div`
width: 100%;
min-height: calc(100% - 0px);
background-color: rgba(255, 255, 255, 0.6);
-webkit-box-pack: center;
justify-content: space-around;
-webkit-box-align: center;
align-items: center;
transition: all 0.3s ease 0s;
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
opacity:0;
display: flex;
align-items: center;
cursor:pointer;
flex-direction: column;
`
const UseThisTemplateButton = styled(Link)`
padding: 6px 12px 6px 6px;
background-color: #0c87d4 !important;
border-radius: 50px;
color: rgb(255, 255, 255);
font-family: Roboto, sans-serif;
font-size: 14px;
font-weight: 500;
text-transform: uppercase;
letter-spacing: 1.25px;
display: inline-flex;
-webkit-box-align: center;
align-items: center;
text-decoration: none;
&:hover {
    background-color: #032558 !important;
    color: rgb(247 246 246);
  }
`

const ButtonICON = styled.div`
width: 24px;
height: 24px;
background-color: rgb(83 167 229);
border-radius: 50px;
margin-right: 8px;
display: flex;
align-items: center;
justify-content: center;

`
const AvailableColorTitle = styled.div`
padding-left: 5px;
font-size: 12px;
color: rgb(90, 94, 97);
`

const ColorWrapper = styled.div`
height: 28px;
width: 28px;
border-radius: 50px;
display: flex;
-webkit-box-align: center;
align-items: center;
-webkit-box-pack: center;
justify-content: center;
`

const ColorCircle = styled.div`
flex: 0 0 auto;
box-sizing: border-box;
height: 20px;
width: 20px;
border: 1px solid ${(props: { obj: any }) => props?.obj?.borderColor};
border-radius: 50px;
background-color: ${(props: { obj: any }) => props?.obj?.color};
display: inline-flex;
-webkit-box-pack: center;
justify-content: center;
-webkit-box-align: center;
align-items: center;
overflow: hidden;
`
const CustomDateSelectControl = React.forwardRef<
  any,
  {
    value?: any,
    onClick?: any,
    onChange?: any,
    label?: string,
    helper?: string,
    placeHolder?: string,
    disabled?: boolean,
    className?: string
  }
>(({ value, onClick, onChange, helper, label, placeHolder, disabled, className }, ref) => (
  <div className='form-float'>
    <div className="form-floating mb-3 rounded fw-500">
      <input type="text" value={value || ''} onChange={onChange} className={"form-control rounded bg-field " + className} onClick={onClick} ref={ref} placeholder={placeHolder} disabled={!!disabled} />
      {
        label &&
        <label className='label-float'>{label}</label>
      }
      {
        helper &&
        <span className='helper-text ms-3'>{helper}</span>
      }
    </div>
  </div>
));
export {
  WrapperDiv, Title, CustomDateSelectControl,
  SubTitle, StickyHeaderWrapper,
  AdjustInCenter, TabButtons,
  ShowTemplateListWrapper, TemplateHTMLHeader, TemplateHTMLHeaderTitle,
  TemplateHTMLHeaderBadge, RESUMEBGIMAGE, HoverOverlay, OverlayButtonWrapper,
  UseThisTemplateButton, ButtonICON, AvailableColorTitle, ColorWrapper, ColorCircle
};