import React, { useState } from 'react';
import moment from 'moment';
import { DefaultProfileImage, SelectableSectionClicked } from '../SharedData';
import styled from "styled-components";
import { useTranslation } from 'react-i18next';

export const Zurich = (props: TemplateComponentProps) => {
    const { t } = useTranslation('resume');

    const {
        navigate,
        contactInformation,
        image,
        imageData,
        objectives,
        experiences,
        educations,
        languages,
        skills,
        achievements,
        certificates,
        customSections,
        honours,
        projects,
        publications,
        references,
        additionalSettings,
        stepsInformation,
        onNavigatedToSection
    } = props;


    return (
        <>
            <WrapperDiv additionalSettings={additionalSettings}>
                <div className='template'>
                    <div className='preview-template'>
                        <div id="template-full">
                            <div className='template-main'>
                                <div className='template-zurich w-100'>
                                    <div className='header-zurich'>
                                    </div>
                                    <div className='row header-zurich-column'>
                                        <div className='col-7'>
                                            {
                                                (contactInformation.firstName || contactInformation.lastName || contactInformation.occupation) &&
                                                <section className='head-two text-start selectable-section' onClick={_ => SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)}>
                                                    <div className='template-name-zurich mb-2 font-change'>
                                                        <span className='mb-0 text-uppercase firstName text-wrap me-2'>{contactInformation.firstName}</span>
                                                        <span className='mb-0 text-uppercase lastName text-wrap'>{contactInformation.lastName}</span>
                                                    </div>
                                                    <div className='zurich-occaption'>
                                                        <p className='mb-0 text-uppercase'>{contactInformation.occupation}</p>
                                                    </div>
                                                </section>
                                            }
                                        </div>
                                        <div className='col-5'>
                                            {
                                                Object.values(contactInformation).filter(s => s).length > 0 &&
                                                <section className='selectable-section' onClick={_ => SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)}>
                                                    <div className='d-block justify-content-end'>
                                                        <ul className='list-unstyled fw-600'>
                                                            {
                                                                contactInformation.email &&
                                                                <li className='text-end'><p className='header-info-zurich'>{contactInformation.email}</p></li>
                                                            }
                                                            {
                                                                contactInformation.address &&
                                                                <li className='text-end'><p className='header-info-zurich'> {contactInformation.address} </p></li>
                                                            }
                                                            {
                                                                contactInformation.address2 &&
                                                                <li className='text-end'><p className='header-info-zurich'> {contactInformation.address2} </p></li>
                                                            }
                                                            {
                                                                contactInformation.city &&
                                                                <li className='text-end'><p className='header-info-zurich'> {contactInformation.city} </p></li>
                                                            }
                                                            {
                                                                contactInformation.phone &&
                                                                <li className='text-end'><p className='header-info-zurich'> {contactInformation.phone}</p></li>
                                                            }
                                                            {
                                                                contactInformation.dob &&
                                                                <li className='text-end'>
                                                                    <p className='header-info-zurich d-flex align-items-center justify-content-end'>
                                                                        {moment(contactInformation.dob).format('MM/DD/YYYY')}
                                                                        {
                                                                            contactInformation.nationality &&
                                                                            <span className='barline text-nowrap'> {contactInformation.nationality} </span>
                                                                        }
                                                                    </p>
                                                                </li>
                                                            }
                                                            {
                                                                contactInformation.linkedInLink &&
                                                                <li className='text-end'><p className='header-info-zurich'>{contactInformation.linkedInLink}</p></li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </section>
                                            }
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            {
                                                Object.values(contactInformation).filter(s => s).length > 0 &&
                                                <section className='selectable-section'>
                                                    {
                                                        image &&
                                                        <div className='img-sec description mt-2 mb-4 d-flex justify-content-center' onClick={_ => SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)} >
                                                            <img src={image}
                                                                className={imageData.isRounded ? "rounded-img d-block header-photo-zurich img-fluid" : 'd-block header-photo-zurich img-fluid'}
                                                                alt="zurich-profile-photo"
                                                                onError={e => {
                                                                    if (e.target instanceof HTMLImageElement) {
                                                                        e.target.src = DefaultProfileImage;
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                </section>
                                            }
                                            {
                                                stepsInformation.findIndex(x => x.title == 'Objective') > -1 && objectives &&
                                                <section className='objective-sec my-3' onClick={_ => SelectableSectionClicked('objective', navigate, onNavigatedToSection)}>
                                                    <div className=''>
                                                        <div className='d-flex align-items-center temp-zurich-title mb-2 font-change'>
                                                            <span>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11"><g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1" opacity="1"><g transform="translate(-72 -772)"><g transform="translate(72 772)"><circle cx="5.522" cy="5.5" r="2.063" className='svg-fill'></circle><circle cx="5.5" cy="5.5" r="4.822" className='svg-stroke' strokeWidth="1.356"></circle></g></g></g></svg>
                                                            </span>
                                                            <span className='text-uppercase fw-700 fs-11 ms-2'> {t('Objective')} </span>
                                                        </div>
                                                        <div className='template-head obj-list-margin-left selectable-section'>
                                                            <p className='sec-value my-2' dangerouslySetInnerHTML={{ __html: objectives }}></p>
                                                        </div>
                                                    </div>
                                                </section>
                                            }
                                            {
                                                stepsInformation.findIndex(x => x.title == 'Skills') > -1 &&
                                                <div className='both-sec'>
                                                    <div className='skill-sec my-3' onClick={_ => SelectableSectionClicked('skills', navigate, onNavigatedToSection)}>
                                                        <div className='d-flex align-items-center temp-zurich-title mb-2 font-change'>
                                                            <span>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11"><g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1" opacity="1"><g className='svg-fill' fillRule="nonzero" transform="translate(-72 -795)"><g transform="translate(72 795)"><path d="M10.592 4.583l-1.362-.15a3.871 3.871 0 00-.34-.82l.857-1.07a.458.458 0 00-.034-.61l-.648-.65a.458.458 0 00-.61-.034l-1.071.86a3.871 3.871 0 00-.819-.34L6.417.408A.458.458 0 005.958 0h-.916a.458.458 0 00-.459.408L4.435 1.77c-.285.081-.56.195-.819.34l-1.07-.857a.458.458 0 00-.61.034l-.653.648a.458.458 0 00-.034.61l.86 1.071a3.871 3.871 0 00-.34.819l-1.361.148A.458.458 0 000 5.042v.916a.458.458 0 00.408.459l1.362.15c.081.286.195.56.34.82l-.857 1.067a.458.458 0 00.034.61l.648.649c.165.165.428.18.61.034l1.071-.855c.26.144.534.257.819.339l.148 1.361c.026.233.224.41.459.408h.916a.458.458 0 00.459-.408l.15-1.362c.286-.081.56-.195.82-.34l1.07.857a.458.458 0 00.61-.034l.649-.648a.458.458 0 00.034-.61l-.858-1.071c.144-.26.257-.534.339-.819l1.361-.148A.458.458 0 0011 5.958v-.916a.458.458 0 00-.408-.459zM5.5 7.333a1.833 1.833 0 110-3.666 1.833 1.833 0 010 3.666z"></path></g></g></g></svg>
                                                            </span>
                                                            <span className='text-uppercase fw-700 fs-11 ms-2 font-change'> {t('Skills')} </span>
                                                        </div>
                                                        <div className='template-head selectable-section'>
                                                            <ul className='list-unstyled sec-value mb-7'>
                                                                {
                                                                    skills.length > 0 &&
                                                                    skills.map((s, i) =>
                                                                        <li className='mb-1' key={'skill_' + s.name + s.id + i}>
                                                                            <p className='fw-500'>{s.name}</p>
                                                                        </li>
                                                                    )
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='language-sec' onClick={_ => SelectableSectionClicked('languages', navigate, onNavigatedToSection)}>
                                                        <div className=''>
                                                            <div className='d-flex align-items-center temp-zurich-title mb-2'>
                                                                <span>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11"><g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1" opacity="1"><g className='svg-fill' fillRule="nonzero" transform="translate(-72 -818)"><g transform="translate(72 818)"><path d="M5.5 0a5.5 5.5 0 110 11 5.5 5.5 0 010-11zM3.948 9.323l-.076-.122c-.359-.602-.61-1.41-.736-2.326H1.609a4.138 4.138 0 002.34 2.448zm5.443-2.449l-1.527.001c-.134.98-.413 1.835-.812 2.448a4.135 4.135 0 002.339-2.449zm-2.922.001H4.531c.202 1.228.643 2.063.969 2.063.326 0 .767-.835.97-2.063zm.583-5.198l.076.122c.53.89.827 2.228.827 3.701h1.67c0-1.73-1.064-3.21-2.573-3.823zm-3.104 0A4.13 4.13 0 001.38 5.294l-.005.206h1.67c0-1.542.326-2.936.903-3.823zm1.552.385c-.437 0-1.08 1.5-1.08 3.438h2.16c0-1.937-.643-3.438-1.08-3.438z"></path></g></g></g></svg>
                                                                </span>
                                                                <p className='text-uppercase fw-700 fs-11 ms-2 font-change'> {t('Languages')} </p>
                                                            </div>
                                                            <div className='template-head selectable-section'>
                                                                <ul className='list-unstyled sec-value mb-7'>
                                                                    {
                                                                        languages.length > 0 &&
                                                                        languages.map((s, i) =>
                                                                            <li className='mb-2' key={'languages_' + s.name + s.id + i}>
                                                                                <div className='d-flex align-items-center stockholm-text-title'>
                                                                                    {
                                                                                        s.name &&
                                                                                        <p className='fw-600'>{s.name}</p>
                                                                                    }
                                                                                    {
                                                                                        s.level &&
                                                                                        <p className='fw-600 vertical-linebar'>{s.level}</p>
                                                                                    }
                                                                                </div>
                                                                                {
                                                                                    s.course &&
                                                                                    <p className='fw-500 stockholm-text-subTitle'>{s.course}</p>
                                                                                }
                                                                            </li>
                                                                        )
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className='col-md-8 ps-4'>
                                            {
                                                stepsInformation.findIndex(x => x.title == 'Experiences') > -1 && experiences.length > 0 ?
                                                    <section className='experience-sec' onClick={_ => SelectableSectionClicked('work-experience', navigate, onNavigatedToSection)}>
                                                        <div className=''>
                                                            <div className='d-flex align-items-center temp-zurich-title mb-2 font-change'>
                                                                <span>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11"><g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1" opacity="1"><g transform="translate(-72 -840)"><g transform="translate(72 840)"><path stroke="#dc7f38" className='svg-stroke' strokeWidth="1.356" d="M6.644.678a.676.676 0 01.678.678v1.288a.676.676 0 01-.678.678H3.678V1.356a.676.676 0 01.678-.678z"></path><rect width="9.644" height="6.644" x="0.678" y="3.678" className='svg-stroke' strokeWidth="1.356" rx="1.356"></rect><path className='svg-fill' d="M0 7H11V8H0z"></path></g></g></g></svg>
                                                                </span>
                                                                <span className='text-uppercase fw-700 fs-11 ms-2 font-change'> {t('Experiences')} </span>
                                                            </div>
                                                            <div className='selectable-section'>
                                                                <div className='row'>
                                                                    {
                                                                        experiences.map((e, i) =>
                                                                            <div className='col-12 mb-16' key={'exp_' + i}>
                                                                                <div className='d-flex align-items-start justify-content-between'>
                                                                                    <div>
                                                                                        {
                                                                                            e.title &&
                                                                                            <p className='text-capitalize fw-600 fs-12 text-dark mb-1'>{e.title}</p>
                                                                                        }
                                                                                        <div className='d-flex align-items-center text-dark text-nowrap'>
                                                                                            {
                                                                                                e.company &&
                                                                                                <p className='text-capitalize fw-500 fs-11 mb-0'>{e.company}</p>
                                                                                            }
                                                                                            {
                                                                                                e.location ?
                                                                                                    <p className='text-capitalize fw-500 fs-11 barline'>{e.location}</p> : <></>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    {
                                                                                        (e.startDate || e.endDate) ?
                                                                                            <p className='text-capitalize fw-500 fs-11 text-dark'>
                                                                                                {
                                                                                                    e.startDate ?
                                                                                                        <span>{new Date(e.startDate).toLocaleString('default', { month: 'short' })} {new Date(e.startDate).getFullYear()}</span> : <></>
                                                                                                }
                                                                                                {
                                                                                                    (e.endDate && !e.currentlyWorking) ?
                                                                                                        <span className='barline'> {new Date(e.endDate).toLocaleString('default', { month: 'short' })} {new Date(e.endDate).getFullYear()}</span> : <></>
                                                                                                }
                                                                                                {
                                                                                                    e.currentlyWorking ?
                                                                                                        <em className='barline'> {t('present')} </em> : <></>
                                                                                                }
                                                                                            </p> : <></>
                                                                                    }
                                                                                </div>
                                                                                {
                                                                                    e.description &&
                                                                                    <div className=''>
                                                                                        <p className='sec-value my-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: e.description }}></p>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section> : <></>
                                            }
                                            {
                                                stepsInformation.findIndex(x => x.title == 'Education') > -1 && educations.length > 0 &&
                                                <section className='education-sec' onClick={_ => SelectableSectionClicked('education', navigate, onNavigatedToSection)}>
                                                    <div className='d-flex align-items-center temp-zurich-title mb-2 font-change'>
                                                        <span>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11"><g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1" opacity="1"><g className='svg-fill' fillRule="nonzero" transform="translate(-72 -864)"><g transform="translate(72 864)"><path d="M8.938 11H.688C.275 11 0 10.725 0 10.312V.688C0 .275.275 0 .688 0h8.25v1.375H1.375v8.25H8.25v-.688h1.375v1.376c0 .412-.275.687-.688.687z"></path><path d="M11 4.125c0-1.169-.894-2.063-2.063-2.063-1.168 0-2.062.894-2.062 2.063 0 .619.275 1.169.688 1.513V8.25l1.375-1.375 1.374 1.375V5.638A1.97 1.97 0 0011 4.125z"></path><path d="M2.75 3.438H5.5V4.813000000000001H2.75z"></path><path d="M2.75 6.188H6.188000000000001V7.563H2.75z"></path></g></g></g></svg>
                                                        </span>
                                                        <span className='text-uppercase fw-700 fs-11 ms-2'> {t('Education')} </span>
                                                    </div>
                                                    <div className='row selectable-section'>
                                                        {
                                                            educations.map((s, i) =>
                                                                <div className='col-12 mb-16' key={'edu_' + i}>
                                                                    <div className='d-flex align-items-start justify-content-between'>
                                                                        <div>
                                                                            {
                                                                                s.degree &&
                                                                                <p className='text-capitalize fw-600 fs-12 text-dark mb-1'>{s.degree}</p>
                                                                            }
                                                                            <div className='d-flex align-items-center text-dark text-nowrap'>
                                                                                {
                                                                                    s.institute &&
                                                                                    <p className='text-capitalize fw-500 fs-11 mb-0'>{s.institute}</p>
                                                                                }
                                                                                {
                                                                                    s.location &&
                                                                                    <p className='text-capitalize fw-500 fs-11 barline'>{s.location}</p>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            (s.startDate || s.completeDate) &&
                                                                            <p className='text-capitalize fw-500 fs-11 text-dark'>
                                                                                {
                                                                                    s.startDate &&
                                                                                    <span>{new Date(s.startDate).toLocaleString('default', { month: 'short' })} {new Date(s.startDate).getFullYear()}</span>
                                                                                }
                                                                                {
                                                                                    (s.completeDate && !s.currentlyStudying) &&
                                                                                    <span className='barline'> {new Date(s.completeDate).toLocaleString('default', { month: 'short' })} {new Date(s.completeDate).getFullYear()}</span>
                                                                                }
                                                                                {
                                                                                    (s.currentlyStudying) &&
                                                                                    <span className='barline'> {t('present')}</span>
                                                                                }
                                                                            </p>
                                                                        }
                                                                    </div>
                                                                    <div className=''>
                                                                        {
                                                                            s.description &&
                                                                            <p className='sec-value my-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: s.description }}></p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </section>
                                            }
                                            {
                                                stepsInformation.findIndex(x => x.title == 'Projects') > -1 && projects.length > 0 &&
                                                <section className='projects-sec' onClick={_ => SelectableSectionClicked('projects', navigate, onNavigatedToSection)}>
                                                    <div className='d-flex align-items-center temp-zurich-title mb-2 font-change'>
                                                        <span>
                                                            <svg width="10" height="11" viewBox="0 0 10 11" xmlns="http://www.w3.org/2000/svg"><path d="M3.53 0c.196 0 .38.108.489.288L5.02 1.941h4.392c.295 0 .54.24.582.552l.006.095v6.47C10 10.132 9.21 11 8.235 11h-6.47C.79 11 0 10.131 0 9.059V.647C0 .29.263 0 .588 0zm5.293 5.47H1.176v3.589c0 .289.172.533.41.616l.092.024.087.007h6.47c.325 0 .589-.29.589-.647L8.823 5.47zM3.214 1.293H1.176l-.001 2.883h7.648v-.941H4.706a.566.566 0 0 1-.438-.215l-.052-.073-1.002-1.654z" className='svg-fill' fillRule="nonzero" opacity="1"></path></svg>
                                                        </span>
                                                        <span className='text-uppercase fw-700 fs-11 ms-2'> {t('Projects')} </span>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-12 mb-16 selectable-section'>
                                                            {
                                                                projects.map((v, i) =>
                                                                    <div key={'projects__' + i}>
                                                                        <p className='sec-value my-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </section>
                                            }
                                            {
                                                stepsInformation.findIndex(x => x.title == 'Achievements') > -1 && achievements.length > 0 &&
                                                <section className='Achievements-sec' onClick={_ => SelectableSectionClicked('achievements', navigate, onNavigatedToSection)}>
                                                    <div className='d-flex align-items-center temp-zurich-title mb-2 font-change'>
                                                        <span>
                                                            <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M2.535.035A.577.577 0 0 1 2.59.018l.038-.009a.603.603 0 0 1 .05-.007l.021-.001h6.603-.028c.034 0 .067.002.1.008l.038.009a.59.59 0 0 1 .155.067l.058.044.025.021.01.01 2.181 2.182a.545.545 0 0 1 .072.682L9.525 6.71a3.818 3.818 0 1 1-7.05 0L.088 3.024a.545.545 0 0 1 .072-.682L2.342.16a.545.545 0 0 1 .083-.068l.01-.007.015-.01.028-.014.016-.009.041-.017zM6 5.455a2.727 2.727 0 1 0 0 5.454 2.727 2.727 0 0 0 0-5.454zM2.631 1.41l-1.388 1.39L3.11 5.685c.415-.48.948-.856 1.553-1.082L2.631 1.41zm6.736 0L7.335 4.604a3.825 3.825 0 0 1 1.554 1.082L10.756 2.8 9.367 1.411zM6.889 3.272H5.11l.697 1.096L6 4.364c.065 0 .129.001.192.004l.697-1.096zM8.278 1.09H3.721l.694 1.091h3.169l.694-1.091z" className='svg-fill' fillRule="evenodd" opacity="1"></path></svg>
                                                        </span>
                                                        <span className='text-uppercase fw-700 fs-11 ms-2'> {t('Achievements')} </span>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-12 mb-16 selectable-section'>
                                                            {
                                                                achievements.map((v, i) =>
                                                                    <div key={'achievements__' + i}>
                                                                        <p className='sec-value my-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </section>
                                            }
                                            {
                                                stepsInformation.findIndex(x => x.title == 'Certifications & Courses') > -1 && certificates.length > 0 &&
                                                <section className='certificates-courses-sec' onClick={_ => SelectableSectionClicked('certifications-courses', navigate, onNavigatedToSection)}>
                                                    <div className='d-flex align-items-center temp-zurich-title mb-2 font-change'>
                                                        <span>
                                                            <svg width="11" height="11" viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg"><path d="m6.332.626 2.858 2.54H11a.5.5 0 0 1 .492.41l.008.09V11a.5.5 0 0 1-.5.5H1a.5.5 0 0 1-.5-.5V3.667a.5.5 0 0 1 .5-.5l1.809-.001L5.668.626a.5.5 0 0 1 .664 0zm-3.336 3.54H1.5V10.5h9V4.166H2.996zm5.337 3.667a.5.5 0 1 1 0 1H3.667a.5.5 0 1 1 0-1h4.666zm0-2a.5.5 0 1 1 0 1H3.667a.5.5 0 1 1 0-1h4.666zM6 1.67 4.315 3.166h3.369L6 1.669z" className='svg-fill' fillRule="nonzero" opacity="1"></path></svg>
                                                        </span>
                                                        <span className='text-uppercase fw-700 fs-11 ms-2'> {t('Certifications & Courses')} </span>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-12 mb-16 selectable-section'>
                                                            {
                                                                certificates.map((v, i) =>
                                                                    <div key={'certificates__' + i}>
                                                                        <p className='sec-value my-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </section>
                                            }
                                            {
                                                stepsInformation.findIndex(x => x.title == 'Honors & Awards') > -1 && honours.length > 0 &&
                                                <section className='honours-awards-sec' onClick={_ => SelectableSectionClicked('honors-awards', navigate, onNavigatedToSection)}>
                                                    <div className='d-flex align-items-center temp-zurich-title mb-2 font-change'>
                                                        <span>
                                                            <svg width="11" height="11" viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg"><path d="M8.857.545c.251 0 .455.204.455.455v.272H11c.251 0 .455.204.455.455V3.91c0 1.37-1.03 2.498-2.35 2.625a3.33 3.33 0 0 1-2.65 2.16v.396h.974c.923 0 1.697.676 1.855 1.594l.02.155.007.14a.455.455 0 0 1-.454.476H3.143A.455.455 0 0 1 2.688 11c0-1.053.842-1.91 1.883-1.91h.974v-.394a3.327 3.327 0 0 1-2.651-2.163C1.575 6.407.545 5.279.545 3.909V1.727c0-.25.204-.454.455-.454h1.687L2.688 1c0-.251.204-.455.455-.455h5.714zM7.43 10H4.57a.968.968 0 0 0-.821.463l-.046.082h4.59l-.038-.072a.974.974 0 0 0-.713-.466L7.429 10zm.973-8.546H3.597v3.91c0 1.303.993 2.366 2.233 2.45l.157.004.154-.004c1.265-.075 2.262-1.147 2.262-2.45l-.001-3.91zm2.143.727H9.311v3.183c0 .07-.001.14-.006.21a1.72 1.72 0 0 0 1.24-1.665V2.181zm-9.091 0v1.728c0 .797.527 1.465 1.24 1.666v-.027l-.006-.184V2.18H1.453z" className='svg-fill' fillRule="evenodd" opacity="1"></path></svg>
                                                        </span>
                                                        <span className='text-uppercase fw-700 fs-11 ms-2'> {t('Honors & Awards')} </span>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-12 mb-16 selectable-section'>
                                                            {
                                                                honours.map((v, i) =>
                                                                    <div key={'honour__' + i}>
                                                                        <p className='sec-value my-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </section>
                                            }
                                            {
                                                stepsInformation.findIndex(x => x.title == 'Publications') > -1 && publications.length > 0 &&
                                                <section className='publication-sec' onClick={_ => SelectableSectionClicked('publications', navigate, onNavigatedToSection)}>
                                                    <div className='d-flex align-items-center temp-zurich-title mb-2 font-change'>
                                                        <span>
                                                            <svg width="10" height="13" viewBox="0 0 10 13" xmlns="http://www.w3.org/2000/svg"><path d="M7.393 2.355c.307 0 .556.274.556.613v9.419c0 .338-.249.613-.556.613H.556C.249 13 0 12.725 0 12.387v-9.42c0-.338.249-.612.556-.612zM6.837 3.58H1.111v8.193h5.726V3.58zM9.444 0c.307 0 .556.275.556.613v10.204c0 .339-.249.613-.556.613-.306 0-.555-.274-.555-.613l-.001-9.59H1.923c-.279 0-.51-.227-.55-.523l-.005-.09c0-.34.248-.614.555-.614h7.521zM5.342 9.542c.245 0 .444.223.444.5 0 .276-.199.5-.444.5H2.607c-.246 0-.445-.224-.445-.5 0-.277.2-.5.445-.5h2.735zm0-2.355c.245 0 .444.224.444.5s-.199.5-.444.5H2.607c-.246 0-.445-.224-.445-.5s.2-.5.445-.5h2.735zm0-2.355c.245 0 .444.224.444.5s-.199.5-.444.5H2.607c-.246 0-.445-.224-.445-.5s.2-.5.445-.5h2.735z" className='svg-fill' fillRule="nonzero" opacity="1"></path></svg>
                                                        </span>
                                                        <span className='text-uppercase fw-700 fs-11 ms-2'> {t('Publications')} </span>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-12 mb-16 selectable-section'>
                                                            {
                                                                publications.map((v, i) =>
                                                                    <div key={'publication__' + i}>
                                                                        <p className='sec-value my-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </section>
                                            }
                                            {
                                                stepsInformation.findIndex(x => x.title == 'References') > -1 && references.length > 0 &&
                                                <section className='reference-sec' onClick={_ => SelectableSectionClicked('references', navigate, onNavigatedToSection)}>
                                                    <div className='d-flex align-items-center temp-zurich-title mb-2 font-change'>
                                                        <span>
                                                            <svg width="13" height="13" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M10.9 3.433a1.6 1.6 0 0 1 1.6 1.6V8.7a1.6 1.6 0 0 1-1.6 1.6h-.6V12a.5.5 0 0 1-.788.41l-.066-.056L7.393 10.3H5.4a.5.5 0 0 1-.492-.41L4.9 9.8a.5.5 0 0 1 .5-.5h2.2a.5.5 0 0 1 .354.146L9.3 10.793V9.8a.5.5 0 0 1 .41-.492L9.8 9.3h1.1a.6.6 0 0 0 .6-.6V5.033a.6.6 0 0 0-.6-.6h-.367a.5.5 0 0 1 0-1h.367zM7.967.5a1.6 1.6 0 0 1 1.6 1.6v3.667a1.6 1.6 0 0 1-1.6 1.6l-2.36-.001L3.554 9.42a.5.5 0 0 1-.847-.267L2.7 9.067V7.366h-.6A1.6 1.6 0 0 1 .507 5.913L.5 5.767V2.1A1.6 1.6 0 0 1 2.1.5zm0 1H2.1a.6.6 0 0 0-.6.6v3.667a.6.6 0 0 0 .6.6h1.1a.5.5 0 0 1 .5.5v.992l1.346-1.346a.5.5 0 0 1 .275-.14l.079-.006h2.567a.6.6 0 0 0 .6-.6V2.1a.6.6 0 0 0-.6-.6z" className='svg-fill' fillRule="nonzero" opacity="1">
                                                                </path>
                                                            </svg>
                                                        </span>
                                                        <span className='text-uppercase fw-700 fs-11 ms-2'> {t('References')} </span>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-12 mb-16 selectable-section'>
                                                            {
                                                                references.map((v, i) =>
                                                                    <div key={'refer__' + i}>

                                                                        <p className='sec-value my-2 editor-list-margin-left'>{v.name}</p>
                                                                        <p className='sec-value my-2 editor-list-margin-left'>{v.city} {(v.city != '' && v.tel != '') ? "|" : ""} {v.tel}</p>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </section>
                                            }
                                            {
                                                stepsInformation.findIndex(x => x.title == 'Custom sections') > -1 && customSections.map((v, i) =>
                                                    <section key={'custom__' + i} className='custom-sec' onClick={_ => SelectableSectionClicked('custom-sections', navigate, onNavigatedToSection)}>
                                                        <div className='d-flex align-items-center temp-zurich-title mb-2 font-change'>
                                                            <span className='text-uppercase fw-700 fs-11'> {v.title} </span>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-12 mb-16 selectable-section'>
                                                                <div className=''>
                                                                    <p className='sec-value my-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </WrapperDiv>
        </>
    )
}

const WrapperDiv = styled.div`
& .header-zurich{
    background:${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.color && props?.additionalSettings?.color};
}
& .header-info-zurich{
    color:${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.color && props?.additionalSettings?.color};
}
& .temp-zurich-title{
    border-color:${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.color && props?.additionalSettings?.color};
}
& .svg-fill{
    fill:${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.color && props?.additionalSettings?.color};
}
& .svg-stroke{
    stroke:${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.color && props?.additionalSettings?.color};
}
& .sec-value , .sec-value *{
    line-height:${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.lineHeight && props?.additionalSettings?.lineHeight};
    font-size:${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.fontSize && props?.additionalSettings?.fontSize + " !important"};
}

& .font-change *{
    font-family:${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.fontFamily
        &&
        props?.additionalSettings?.fontFamily === "Classic" ? "Noto Serif, serif;" :
        props?.additionalSettings?.fontFamily === "Modern" ? "Rubik, sans-serif;" :
            props?.additionalSettings?.fontFamily === "Elegant" ? "Libre Baskerville, serif;" :
                props?.additionalSettings?.fontFamily === "Tech" ? "Roboto Mono, monospace;" :
                    props?.additionalSettings?.fontFamily === "Professional" ? "Bitter, serif;" : "default;"}
}
`