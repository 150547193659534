import axios from "axios";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
  FaEnvelope,
  FaEye,
  FaEyeSlash,
  FaLock,
  FaQrcode,
  FaUserAlt,
} from "react-icons/fa";
import { RotatingLines } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { CountrySelect, DatePickSelect } from "../../../Components";
import { config } from "../../../Environments";
import {
  GetAxios,
  IdentityResponse,
  KeyPairValue,KeyPairNValue,
  LabelValuePair,
} from "../../../Shared";
import countryList from "react-select-country-list";
import { useTranslation } from "react-i18next";
import { TbBuildingEstate } from "react-icons/tb";
// const years = range(1960, (new Date().getFullYear()) + 1);
// const months = moment.months();

const CandidateRegister = () => {
  const { t: ct } = useTranslation("common");
  const { t: at } = useTranslation("auth");
  const { i18n } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [departments, setDepartments] = useState<LabelValuePair[]>([]);
  const [states, setStates] = useState<KeyPairNValue[]>([]);
  var navigate = useNavigate();
  const { t: ts } = useTranslation("alert");

  const SignupSchema = Yup.object().shape({
    email: Yup.string()
      .email(ts("signIn.inValidEmail") || "Invalid email address")
      .required(ts("account.required") || "Required")
      .test(
        "not-yopmail",
        ts("account.yopmail") || "not allowed",
        (value) => !value || !value.endsWith("@yopmail.com")
      ),
    password: Yup.string()
      .required(ts("account.required") || "Required")
      .min(6, ts("account.tooShort") || "Password too short")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/,
        ts("account.passwordPattern") ||
        "1 Upper, Lowercase, 1 Number and 1 Special Character"
      ),
    cpassword: Yup.string()
      .required(ts("account.required") || "Required")
      .oneOf(
        [Yup.ref("password"), null],
        ts("account.passwordMatch") || "Passwords must match"
      ),
    firstName: Yup.string().required(ts("account.required") || "Required"),
    lastName: Yup.string().required(ts("account.required") || "Required"),
    gender: Yup.string().required(ts("account.required") || "Required"),
    country: Yup.string().required(ts("account.required") || "Required"),

    // department: Yup.string().when("department", {
    //     is: "" || 0,
    //     then: Yup.string().required(ts('account.required') || 'Required'),
    //     otherwise: Yup.string().notRequired(),

    // }),

    department: Yup.number().when("country", {
      is: "DO",
      then: Yup.number().min(1, ts("account.required") || "Required"),
      otherwise: Yup.number().notRequired(),
    }),
    zipCode: Yup.string().when("country", {
      is: "US",
      then: Yup.string().required(ts('account.required') || 'Required'),
      otherwise: Yup.string().notRequired(),
    }),
    state: Yup.number().when("country", {
      is: "US",
      then: Yup.number().min(1, ts("account.required") || "Required"),
      otherwise: Yup.number().notRequired(),
    }),
    dateOfBirth: Yup.string().required(ts("account.required") || "Required"),
    lang: Yup.string().required(ts("account.required") || "Required"),
    //agree: Yup.boolean().required('Required.'),
  });
  const [showStateZip, setShowStateZip] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      gender: "Male",
      country: "DO",
      dateOfBirth: "",
      zipCode: "",
      state: 0,
      department: 0,
      cpassword: "",
      agree: false,
      newsLetter: false,
      lang: "",
    },
    validationSchema: SignupSchema,
    onSubmit: async (values) => {
      if (!values.agree) {
        toast(ts("employee.termsAndConditions"), { type: "warning" });
        return;
      }
      SignupSchema.validate(values)
        .then(async (v) => {
          if (!SignupSchema.isValid(v)) {
            toast(ts("account.fillForm"), {
              position: "top-left",
              type: "warning",
            });
            return;
          }
          setSubmitting(true);
          try {
            const response = await GetAxios().post<IdentityResponse>(
              `${config.apiBase}/api/Employee/Register`,
              { ...v, dateOfBirth: new Date(v.dateOfBirth).toISOString() }
            );
            if (response.data.success) {
              navigate("/require-confirm");
            } else {

              var message = response.data.message;
              toast(message, { type: "error" });
              //  alert(response.data.errors.map((v) => v.description));

            }
            setSubmitting(false);
          } catch (error: any) {
            console.error(error?.response);
            console.error(error);
            toast(error.message, { type: "error" });
            setSubmitting(false);
          }
        })
        .catch((err) => {
          toast(err.name, { type: "error" });
        });
      // alert(JSON.stringify(values, null, 2));
    },
  });

  useEffect(() => {
    formik.setFieldValue("lang", i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    axios.get(config.apiBase + "/api/General/Info").then((res) => {
      if (!formik.values.country) {
        //  formik.setFieldValue("country", res?.data?.country || "DO");
        formik.setFieldValue("country", "DO");
      }
    });
  }, []);

  useEffect(() => {
    GetAxios()
      .get(config.apiBase + "/api/General/GetDepartments")
      .then((res) => {
        setDepartments(res.data);
      });
    GetAxios()
      .get(config.apiBase + "/api/General/GetListBySystemType?lang=" + i18n.language + "&type=USStates")
      .then((res) => {
        setStates(res.data);
      });

  }, []);
  return (
    <>
      <div className="candidate-register-bg">
        <div className="container">
          <div className="row form-m-center">
            <div className="form-section candidate-register-form-section">
              <div className="padding-step1">
                <h3 className="text-center step1-heading">
                  {" "}
                  {at("candidateRegister.title")}{" "}
                </h3>
                <form onSubmit={formik.handleSubmit}>
                  <div className="row mb-12">
                    <div className="col-md-12 mb-12">
                      <div className="input-group">
                        <span
                          className="input-group-text group-round"
                          id="Email"
                        >
                          <FaEnvelope />
                        </span>
                        <input
                          type="text"
                          className="form-control key-weight"
                          name="email"
                          onChange={formik.handleChange}
                          value={formik.values.email}
                          placeholder={ct("email").toString()}
                          aria-describedby="Email"
                        />
                      </div>
                      {formik.errors.email && formik.touched.email ? (
                        <small className="text-danger d-block">
                          {formik.errors.email}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-md-12 mb-12">
                      <div className="input-group">
                        <span
                          className="input-group-text group-round"
                          id="Password"
                        >
                          <FaLock />
                        </span>
                        <input
                          type={showPassword !== false ? "text" : "password"}
                          className="form-control key-weight"
                          name="password"
                          onChange={formik.handleChange}
                          value={formik.values.password}
                          placeholder={ct("password").toString()}
                          aria-describedby="Password"
                        />
                        <span
                          className="input-group-text group-round cursor-pointer"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </span>
                      </div>
                      {formik.errors.password && formik.touched.password ? (
                        <small className="text-danger d-block">
                          {formik.errors.password}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-md-12 mb-12">
                      <div className="input-group">
                        <span
                          className="input-group-text group-round"
                          id="basic-addon1"
                        >
                          <FaLock />
                        </span>
                        <input
                          onChange={formik.handleChange}
                          value={formik.values.cpassword}
                          name="cpassword"
                          type="password"
                          className="form-control key-weight"
                          placeholder={ct("ReTypePassword").toString()}
                          aria-describedby="basic-addon1"
                        />
                      </div>
                      {formik.errors.cpassword && formik.touched.cpassword ? (
                        <small className="text-danger d-block">
                          {formik.errors.cpassword}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-md-6 col-12 mb-12">
                      <div className="">
                        <div className="input-group">
                          <span
                            className="input-group-text group-round"
                            id="basic-addon2"
                          >
                            <FaUserAlt />
                          </span>
                          <input
                            type="text"
                            className="form-control key-weight"
                            name="firstName"
                            onChange={formik.handleChange}
                            value={formik.values.firstName}
                            placeholder={ct("firstName").toString()}
                            aria-describedby="basic-addon2"
                          />
                        </div>
                        {formik.errors.firstName && formik.touched.firstName ? (
                          <small className="text-danger d-block">
                            {formik.errors.firstName}
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-12 mb-12">
                      <div className="">
                        <div className="input-group">
                          <span
                            className="input-group-text group-round"
                            id="last-name"
                          >
                            <FaUserAlt />
                          </span>
                          <input
                            type="text"
                            className="form-control key-weight"
                            name="lastName"
                            onChange={formik.handleChange}
                            value={formik.values.lastName}
                            placeholder={ct("lastName").toString()}
                            aria-describedby="last-name"
                          />
                        </div>
                        {formik.errors.lastName && formik.touched.lastName ? (
                          <small className="text-danger d-block">
                            {formik.errors.lastName}
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-12 mb-12">
                      <div>
                        <DatePickSelect
                          yearFrom={1960}
                          yearTo={new Date().getFullYear()}
                          maxDate={new Date()}
                          minDate={new Date(1960, 1, 1)}
                          placeHolder={ct("dateOfBirth").toString()}
                          selected={formik.values.dateOfBirth}
                          onChange={(date) =>
                            formik.setFieldValue("dateOfBirth", date)
                          }
                        />
                        {formik.errors.dateOfBirth &&
                          formik.touched.dateOfBirth ? (
                          <small className="text-danger d-block">
                            {formik.errors.dateOfBirth}
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-12 mb-12">
                      <div className="d-flex align-items-center mt-1">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            checked={
                              formik.values.gender === "Male" ? true : false
                            }
                            type="radio"
                            onChange={formik.handleChange}
                            name="gender"
                            id="inlineCheckbox1"
                            value="Male"
                          />
                          <label
                            className="form-check-label key-weight"
                            htmlFor="inlineCheckbox1"
                          >
                            {" "}
                            {ct("male").toString()}{" "}
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            checked={
                              formik.values.gender === "Female" ? true : false
                            }
                            onChange={formik.handleChange}
                            type="radio"
                            name="gender"
                            id="inlineCheckbox2"
                            value="Female"
                          />
                          <label
                            className="form-check-label key-weight"
                            htmlFor="inlineCheckbox2"
                          >
                            {" "}
                            {ct("female").toString()}{" "}
                          </label>
                        </div>
                        {formik.errors.gender && formik.touched.gender ? (
                          <small className="text-danger d-block">
                            {formik.errors.gender}
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 mb-12">
                      <div className="">
                        <CountrySelect
                          value={formik.values.country}
                          onChange={(code) => {
                            formik.setFieldValue("country", code);

                            //formik.setFieldValue("zipCode", code + "-" + formik.values.zipCode.replace(/[^0-9\.]+/g, ""));
                          }}
                        />
                        {formik.errors.country && formik.touched.country ? (
                          <small className="text-danger d-block">
                            {formik.errors.country}
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {formik.values.country === 'DO' &&
                      <>
                        <div className="col-md-6 mb-12">
                          <select
                            onChange={(e) =>
                              formik.setFieldValue(
                                "department",
                                Number(e.target.value)
                              )
                            }
                            value={formik.values.department}
                            className="form-select form-control "
                          >
                            <option value="0">{ct("selectDepartment")}</option>
                            {departments.map((v, i) => (
                              <option key={"%__" + i} value={v.value}>
                                {v.label}
                              </option>
                            ))}
                          </select>
                          {/* <input type="text" name='state' onChange={formik.handleChange} value={formik.values.state} className="form-control gray-form" id="state" /> */}
                          {
                            formik.values.country === 'DO' && formik.errors.department && formik.touched.department ? (
                              <small className="text-danger">
                                {formik.errors.department}
                              </small>
                            ) : (
                              ""
                            )}

                          {/* <div className="">
                                                <input type="text" name='zipCode' maxLength={9} placeholder={ct('zipCode').toString()} onChange={formik.handleChange} value={formik.values.zipCode} className="form-control" id="zipcode" />
                                                {formik.errors.zipCode && formik.touched.zipCode ? <small className='text-danger'>{formik.errors.zipCode}</small> : ""}
                                            </div> */}
                        </div>
                      </>}
                    {formik.values.country === 'US' &&
                      <>
                        <div className="col-md-6 col-12 mb-12">
                          <div className="">
                            <div className="input-group">
                              <span
                                className="input-group-text group-round"
                                id="state-name"
                              >
                                <TbBuildingEstate />
                              </span>
                              {/* <input
                                type="text"
                                className="form-control key-weight"
                                name="state"
                                onChange={formik.handleChange}
                                value={formik.values.state}
                                placeholder={ct("state").toString()}
                                aria-describedby="state-name"
                              /> */}

                              <select
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    "state",
                                    Number(e.target.value)
                                  )
                                }
                                value={formik.values.state}
                                className="form-select form-control "
                              >
                                <option value="0">{ct("selectState")}</option>
                                {states.map((v, i) => (
                                  <option key={"%__" + i} value={v.key}>
                                    {v.value}
                                  </option>
                                ))}
                              </select>
                            </div>

                            {formik.errors.state && formik.touched.state ? (
                              <small className="text-danger d-block">
                                {formik.errors.state}
                              </small>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 col-12 mb-12">
                          <div className="">
                            <div className="input-group">
                              <span
                                className="input-group-text group-round"
                                id="zipCode-name"
                              >
                                <FaQrcode />
                              </span>
                              <input
                                type="text"
                                className="form-control key-weight"
                                name="zipCode"
                                onChange={formik.handleChange}
                                value={formik.values.zipCode}
                                placeholder={ct("zipCode").toString()}
                                aria-describedby="zipCode-name"
                              />
                            </div>
                            {formik.errors.zipCode && formik.touched.zipCode ? (
                              <small className="text-danger d-block">
                                {formik.errors.zipCode}
                              </small>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </>}
                    <div className="mb-12">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onChange={(e) => {
                            formik.setFieldValue("agree", e.target.checked);
                            formik.handleChange(e);
                          }}
                          checked={formik.values.agree}
                          id="defaultCheck1"
                        />
                        <label
                          className="form-check-label key-weight"
                          htmlFor="defaultCheck1"
                        >
                          {at("candidateRegister.iAgreeWith")}{" "}
                          <Link
                            to="/terms-of-use"
                            className="text-decoration-none"
                          >
                            {" "}
                            {at("candidateRegister.termsAndConditions")}{" "}
                          </Link>
                        </label>
                        {formik.errors.agree && formik.touched.agree ? (
                          <small className="text-danger d-block">
                            {formik.errors.agree}
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onChange={(e) => {
                            formik.setFieldValue(
                              "newsLetter",
                              e.target.checked
                            );
                            formik.handleChange(e);
                          }}
                          checked={formik.values.newsLetter}
                          id="defaultCheck2"
                        />
                        <label
                          className="form-check-label key-weight"
                          htmlFor="defaultCheck2"
                        >
                          {at("candidateRegister.wantReceiveNewsletter")}
                        </label>
                      </div>
                    </div>
                    <div className="text-center">
                      <button
                        type="submit"
                        className="border-0  p-btn btn-contine text-white fw-600"
                        disabled={submitting || !formik.values.agree}
                      >
                        {submitting ? (
                          <RotatingLines
                            strokeColor="white"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="18"
                            visible={true}
                          />
                        ) : (
                          <div className="text-uppercase">{ct("register")}</div>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CandidateRegister;
