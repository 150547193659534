import moment from 'moment';
import { DefaultProfileImage, SelectableSectionClicked } from '../SharedData';
import styled from "styled-components";
import { useTranslation } from 'react-i18next';

export const MonteVideo = (props: TemplateComponentProps) => {

    const { t } = useTranslation('resume');
    const { t: ct } = useTranslation('common');

    const {
        navigate,
        contactInformation,
        image,
        imageData,
        objectives,
        experiences,
        educations,
        languages,
        skills,
        achievements,
        certificates,
        customSections,
        honours,
        projects,
        publications,
        references,
        additionalSettings,
        stepsInformation,
        onNavigatedToSection
    } = props;

    return (
        <>
            <WrapperDiv additionalSettings={additionalSettings}>
                <div className={navigate ? ' p-0' : ''}>
                    <div className='template'>
                        <div className='preview-template'>
                            <div id="template-full">
                                <div className='template-main zoom-mobile-resume'>
                                    <div className='template_contentss d-flex'>
                                        <div className='sidebar-template'>
                                            {
                                                Object.values(contactInformation).filter(s => s).length > 0 &&
                                                <div>
                                                    {/* <div className='selectable-section' onClick={_ => SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)}>
                                                        {
                                                        image &&
                                                           <div className='template-header-logo'>
                                                                <img src={image}
                                                                    className={imageData.isRounded ? "rounded-img mx-auto amsterdam-img" : 'simple-img mx-auto amsterdam-img'}
                                                                    alt="menteVideo profile image"
                                                                    onError={e => {
                                                                        if (e.target instanceof HTMLImageElement) {
                                                                            e.target.src = DefaultProfileImage;
                                                                        }
                                                                    }} />
                                                            </div>
                                                        }
                                                </div> */}
                                                    <section className='contact-sec contact-information selectable-section' onClick={_ => SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)}>
                                                        <div className='template-head mt-4'>
                                                            {contactInformation.email &&
                                                                <div className='mb-7'>
                                                                    <p className='fw-600'> {t('email')}: </p>
                                                                    <p className='fw-500'>{contactInformation.email}</p>
                                                                </div>
                                                            }
                                                            {contactInformation.address &&
                                                                <div className='mb-7'>
                                                                    <p className='fw-600'> {t('address')} 1: </p>
                                                                    <p className='fw-500'>{contactInformation.address}</p>
                                                                </div>
                                                            }
                                                            {contactInformation.address2 &&
                                                                <div className='mb-7'>
                                                                    <p className='fw-600'> {t('address')} 2: </p>
                                                                    <p className='fw-500'>{contactInformation.address2}</p>
                                                                </div>
                                                            }
                                                            {contactInformation.city &&
                                                                <div className='mb-7'>
                                                                    <p className='fw-600'> {ct('city')}: </p>
                                                                    <p className='fw-500'>{contactInformation.city}</p>
                                                                </div>
                                                            }
                                                            {contactInformation.phone &&
                                                                <div className='mb-7'>
                                                                    <p className='fw-600'> {t('phone')}: </p>
                                                                    <p className='fw-500'>{contactInformation.phone}</p>
                                                                </div>
                                                            }
                                                            {contactInformation.dob &&
                                                                <div className='mb-7'>
                                                                    <p className='fw-600'> {t('resDateBirth')}: </p>
                                                                    <p className='fw-500'>{moment(contactInformation.dob).format('MM/DD/YYYY')}</p>
                                                                </div>
                                                            }
                                                            {
                                                                contactInformation.nationality &&
                                                                <div className='mb-7'>
                                                                    <p className='fw-600'> {t('nationality')}: </p>
                                                                    <p className='fw-500'>{contactInformation.nationality}</p>
                                                                </div>
                                                            }
                                                            {
                                                                contactInformation.linkedInLink &&
                                                                <div className='mb-7'>
                                                                    <p className='fw-600'> {t('link')}: </p>
                                                                    <p className='fw-500'>{contactInformation.linkedInLink}</p>
                                                                </div>
                                                            }
                                                            <div className='border-circle position-relative'></div>
                                                        </div>
                                                    </section>
                                                </div>
                                            }
                                            {
                                                stepsInformation.findIndex(x => x.title == 'Skills') > -1 &&
                                                <div>
                                                    <div className='skill-sec selectable-section' onClick={_ => SelectableSectionClicked('skills', navigate, onNavigatedToSection)}>
                                                        <p className='text-uppercase title-objective mb-16 fw-600 text-white pt-3'> {t('Skills')} </p>
                                                        <div className='template-head'>
                                                            <ul className='list-unstyled mb-7'>
                                                                {
                                                                    skills.length > 0 &&
                                                                    skills.map((s, i) =>
                                                                        <li key={'skill_' + s.name + s.id + i}>
                                                                            <p className='sec-value fw-500 mb-1 text-white'>{s.name}</p>
                                                                        </li>
                                                                    )
                                                                }
                                                            </ul>
                                                            <div className='border-circle position-relative'></div>
                                                        </div>
                                                    </div>
                                                    <div className='language-sec selectable-section' onClick={_ => SelectableSectionClicked('languages', navigate, onNavigatedToSection)}>
                                                        <p className='text-uppercase title-objective mb-16 fw-600 text-white pt-3'> {t('Languages')} </p>
                                                        <div className='template-head'>
                                                            {
                                                                languages.length > 0 &&
                                                                languages.map((s, i) =>
                                                                    <div className='mb-7' key={'languages_' + s.name + s.id + i}>
                                                                        {
                                                                            s.name &&
                                                                            <p className='fw-600'>{s.name}</p>
                                                                        }
                                                                        {
                                                                            s.level &&
                                                                            <p className='m-right text-white'>{s.level}</p>
                                                                        }
                                                                        {
                                                                            s.course &&
                                                                            <p className='m-right text-white'>{s.course}</p>
                                                                        }
                                                                    </div>
                                                                )}
                                                            <div className='border-circle position-relative'></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className='main-content-template' style={{ width: '700px' }}>

                                            <div className='template-classic-header'>
                                                {
                                                    (contactInformation.firstName || contactInformation.lastName || contactInformation.occupation) &&
                                                    <section className='head-two selectable-section' onClick={_ => SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)}>
                                                        <div className='fs-'>
                                                            <span className='mb-0'>{contactInformation.firstName} {contactInformation.lastName}</span>
                                                        </div>
                                                        <div className='template_subtitle'>
                                                            <p className='mb-2'>{contactInformation.occupation}</p>
                                                        </div>
                                                    </section>
                                                }
                                                {
                                                    stepsInformation.findIndex(x => x.title == 'Objective') > -1 && objectives &&
                                                    <section className='description selectable-section obj-list-margin-left' onClick={_ => SelectableSectionClicked('objective', navigate, onNavigatedToSection)}>
                                                        <p className='sec-value mb-2' dangerouslySetInnerHTML={{ __html: objectives }}></p>
                                                    </section>
                                                }
                                            </div>

                                            {
                                                stepsInformation.findIndex(x => x.title == 'Experiences') > -1 && experiences.length > 0 ?
                                                    <section className='experience-sec padding-side selectable-section' onClick={_ => SelectableSectionClicked('work-experience', navigate, onNavigatedToSection)}>
                                                        <div className='mt-3 mb-16'>
                                                            <p className='text-capitalize fs-14 mb-16 fw-600'> {t('Experiences')} </p>

                                                            {
                                                                experiences.map((e, i) =>
                                                                    <div className='contact-information d-flex position-relative ps-3 mb-16' key={'exp_' + i}>
                                                                        <div className='dot-icon'>
                                                                            {
                                                                                e.title &&
                                                                                <p className='mentovideo-subtitle font-w700'>{e.title}</p>
                                                                            }
                                                                            <div className='d-flex'>
                                                                                <div>
                                                                                    {
                                                                                        e.location ?
                                                                                            <p className='fw-600 sec-subtitle'>{e.location}</p> : <></>
                                                                                    }
                                                                                </div>
                                                                                <div className='ms-2'>
                                                                                    {
                                                                                        e.company &&
                                                                                        <p className=''>{e.company}</p>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                (e.startDate || e.endDate) ?
                                                                                    <p className=''>
                                                                                        {
                                                                                            e.startDate ?
                                                                                                <span>{new Date(e.startDate).toLocaleString('default', { month: 'short' })} {new Date(e.startDate).getFullYear()}</span> : <></>
                                                                                        }
                                                                                        {
                                                                                            (e.endDate && !e.currentlyWorking) ?
                                                                                                <span> - {new Date(e.endDate).toLocaleString('default', { month: 'short' })} {new Date(e.endDate).getFullYear()}</span> : <></>
                                                                                        }
                                                                                        {
                                                                                            e.currentlyWorking ?
                                                                                                <em> - {t('present')} </em> : <></>
                                                                                        }
                                                                                    </p> : <></>
                                                                            }
                                                                            {
                                                                                e.description &&
                                                                                <p className='mt-1 sec-value editor-list-margin-left' dangerouslySetInnerHTML={{ __html: e.description }}></p>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                )}

                                                        </div>
                                                    </section> : <></>
                                            }
                                            {
                                                stepsInformation.findIndex(x => x.title == 'Education') > -1 && educations.length > 0 &&
                                                <section className='education-sec padding-side selectable-section' onClick={_ => SelectableSectionClicked('education', navigate, onNavigatedToSection)}>
                                                    <div className='mt-3 mb-16'>
                                                        <p className='text-capitalize fs-14 mb-16 fw-600'> {t('Education')} </p>
                                                        {
                                                            educations.map((s, i) =>
                                                                <div className='contact-information d-flex position-relative ps-3 mb-16' key={"edu__" + i}>
                                                                    <div className='dot-icon' key={'edu_' + i}>

                                                                        {
                                                                            s.degree &&
                                                                            <span className='font-w700 mentovideo-subtitle'>{s.degree}</span>
                                                                        }
                                                                        <div className='d-flex'>
                                                                            {
                                                                                s.institute &&
                                                                                <span className=' me-2'>{s.institute}</span>
                                                                            }
                                                                            {
                                                                                s.location &&
                                                                                <p className='fst-italic'>{s.location}</p>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            (s.startDate || s.completeDate) &&
                                                                            <p className=''>
                                                                                {
                                                                                    s.startDate &&
                                                                                    <span>{new Date(s.startDate).toLocaleString('default', { month: 'short' })} {new Date(s.startDate).getFullYear()}</span>
                                                                                }

                                                                                {
                                                                                    (s.completeDate && !s.currentlyStudying) &&
                                                                                    <span> - {new Date(s.completeDate).toLocaleString('default', { month: 'short' })} {new Date(s.completeDate).getFullYear()}</span>
                                                                                }
                                                                                {
                                                                                    (s.currentlyStudying) &&
                                                                                    <span> - {t('present')} </span>
                                                                                }
                                                                            </p>
                                                                        }
                                                                        {
                                                                            s.description &&
                                                                            <div className='mt-2'>
                                                                                <p className='sec-value editor-list-margin-left' dangerouslySetInnerHTML={{ __html: s.description }}></p>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )}
                                                    </div>
                                                </section>
                                            }
                                            {
                                                stepsInformation.findIndex(x => x.title == 'Projects') > -1 && projects.length > 0 &&
                                                <section className='projects-sec padding-side selectable-section' onClick={_ => SelectableSectionClicked('projects', navigate, onNavigatedToSection)}>
                                                    <div className='mt-3 mb-16'>
                                                        <p className='text-capitalize fs-14 mb-16 fw-600'> {t('Projects')} </p>
                                                        <div className='mt-2 ps-3'>
                                                            {
                                                                projects.map((v, i) =>
                                                                    <div key={'projects__' + i}>
                                                                        <p className='sec-value mb-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </section>
                                            }
                                            {
                                                stepsInformation.findIndex(x => x.title == 'Achievements') > -1 && achievements.length > 0 &&
                                                <section className='Achievements-sec padding-side selectable-section' onClick={_ => SelectableSectionClicked('achievements', navigate, onNavigatedToSection)}>
                                                    <div className='mt-3 mb-16'>
                                                        <p className='text-capitalize fs-14 mb-16 fw-600'> {t('Achievements')} </p>
                                                        <div className='mt-2 ps-3'>
                                                            {
                                                                achievements.map((v, i) =>
                                                                    <div key={'achievements__' + i}>
                                                                        <p className='sec-value mb-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </section>
                                            }
                                            {
                                                stepsInformation.findIndex(x => x.title == 'Certifications & Courses') > -1 && certificates.length > 0 &&
                                                <section className='certificates-courses-sec padding-side selectable-section' onClick={_ => SelectableSectionClicked('certifications-courses', navigate, onNavigatedToSection)}>
                                                    <div className='mt-3 mb-16'>
                                                        <p className='text-capitalize fs-14 mb-16 fw-600'> {t('Certifications & Courses')} </p>
                                                        <div className='mt-2 ps-3'>
                                                            {
                                                                certificates.map((v, i) =>
                                                                    <div key={'certificates__' + i}>
                                                                        <p className='sec-value mb-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </section>
                                            }
                                            {
                                                stepsInformation.findIndex(x => x.title == 'Honors & Awards') > -1 && honours.length > 0 &&
                                                <section className='honours-awards-sec padding-side selectable-section' onClick={_ => SelectableSectionClicked('honors-awards', navigate, onNavigatedToSection)}>
                                                    <div className='mt-3 mb-16'>
                                                        <p className='text-capitalize fs-14 mb-16 fw-600'> {t('Honors & Awards')} </p>
                                                        <div className='mt-2 ps-3'>
                                                            {
                                                                honours.map((v, i) =>
                                                                    <div key={'honour__' + i}>
                                                                        <p className='sec-value mb-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </section>
                                            }
                                            {
                                                stepsInformation.findIndex(x => x.title == 'Publications') > -1 && publications.length > 0 &&
                                                <section className='publication-sec padding-side selectable-section' onClick={_ => SelectableSectionClicked('publications', navigate, onNavigatedToSection)}>
                                                    <div className='mt-3 mb-16'>
                                                        <p className='text-capitalize fs-14 mb-16 fw-600'> {t('Publications')} </p>
                                                        <div className='mt-2 ps-3'>
                                                            {
                                                                publications.map((v, i) =>
                                                                    <div key={'publication__' + i}>
                                                                        <p className='sec-value mb-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </section>
                                            }
                                            {
                                                stepsInformation.findIndex(x => x.title == 'References') > -1 && references.length > 0 &&
                                                <section className='reference-sec padding-side selectable-section' onClick={_ => SelectableSectionClicked('references', navigate, onNavigatedToSection)}>
                                                    <div className='mt-3 mb-16'>
                                                        <p className='text-capitalize fs-14 mb-16 fw-600'> {t('References')} </p>
                                                        <div className='mt-2 ps-3'>
                                                            {
                                                                references.map((v, i) =>
                                                                    <div key={'reference__' + i}>
                                                                        <p className='sec-value mb-2 editor-list-margin-left'>{v.name}</p>
                                                                        <p className='sec-value mb-2 editor-list-margin-left'>{v.city} {(v.city != '' && v.tel != '') ? "|" : ""} {v.tel}</p>
                                                                        {/* <p className='sec-value mb-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p> */}
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </section>
                                            }
                                            {
                                                stepsInformation.findIndex(x => x.title == 'Custom sections') > -1 && customSections.map((v, i) =>
                                                    <section className='driving-sec padding-side selectable-section' key={'customSec__' + i} onClick={_ => SelectableSectionClicked('custom-sections', navigate, onNavigatedToSection)}>
                                                        <div className='mt-3 mb-16'>
                                                            <p className='text-capitalize fs-14 mb-16 fw-600'>{v.title}</p>
                                                            <div className='mt-2 ps-3'>
                                                                <p className='sec-value mb-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                                            </div>
                                                        </div>
                                                    </section>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </WrapperDiv>
        </>
    )
}

const WrapperDiv = styled.div`
& .sidebar-template{
    background-color:${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.color && props?.additionalSettings?.color};
}
& .mentovideo-subtitle{
    color:${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.color && props?.additionalSettings?.color};
}
& .sec-value , .sec-value *{
    line-height:${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.lineHeight && props?.additionalSettings?.lineHeight};
    font-size:${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.fontSize && props?.additionalSettings?.fontSize + " !important"};
   
}

& * {
    font-family:${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.fontFamily
        &&
        props?.additionalSettings?.fontFamily === "Classic" ? "Noto Serif, serif;" :
        props?.additionalSettings?.fontFamily === "Modern" ? "Rubik, sans-serif;" :
            props?.additionalSettings?.fontFamily === "Elegant" ? "Libre Baskerville, serif;" :
                props?.additionalSettings?.fontFamily === "Tech" ? "Roboto Mono, monospace;" :
                    props?.additionalSettings?.fontFamily === "Professional" ? "Bitter, serif;" : "default;"}
}
`
