import React, { useEffect, useState } from "react";
import RangeSlider from 'react-bootstrap-range-slider';
import Select from 'react-select';
import { FieldArray, FormikProvider } from 'formik';
import { RotatingLines } from "react-loader-spinner";
import { GetAxios } from "../../../Shared";
import { config } from "../../../Environments";
import { Link } from "react-router-dom";
import { FaLinkedin } from "react-icons/fa";
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const MainContent = (props) => {

  const { t } = useTranslation('candidate');
  const { t: ct } = useTranslation('common');

  const formik = props.formik;
  const setEditState = props?.setEditState;
  const editorState = props?.editorState;
  const [skills, setSkills] = useState([]);
  const { i18n } = useTranslation();

  const options = [
    { value: 'EN', label: 'EN' },
    { value: 'SP', label: 'SP' },
    { value: 'IT', label: 'IT' },
    { value: 'DE', label: 'DE' }
  ]
  useEffect(() => {
    formik.setFieldValue('lang', i18n.language);
  }, [i18n.language]);
  useEffect(() => {

    GetAxios().get(config.apiBase + '/api/General/GetListBySystemType?lang=' + i18n.language + '&type=Skills').then(res => {
      setSkills(res.data)
    });


  }, [i18n.language]);




  return (
    <>
      <div className="">
        <div className="client-description mt-mob">
          <div className="card border-0 rounded-0">
            <div className="row p-4 ">
              <div className="col-md-12 card-body p-0">
                <div className="candidate-sec">
                  <div className="container">
                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                      <div className="d-flex align-items-center mb-mob-pre">
                        <div className="">
                          <Link to="/candidate/candidateprofile" className="d-inline button-default border-0 text-decoration-none btn-preview me-1"> {ct('preview')} </Link>
                          <form action={config.apiBase + '/api/Auth/ImportLinkedInProfile'} method='post'>
                            {/* <button className="d-inline button-default border-0 text-decoration-none text-nowrap" type="submit">
                              <FaLinkedin className='me-1 fa-lg' />
                              Import Data From Linkedin
                            </button> */}
                          </form>
                        </div>
                      </div>
                      {/* <div className="d-flex align-items-center language-select">
                        <Select
                          onChange={(v) => {
                            formik.setFieldValue('language', v);
                            formik.handleChange();
                          }}
                          value={formik.values.language}
                          className="basic-multi-select key-weight w-select float-end border border-1 no-border me-1"
                          isMulti
                          defaultValue={options[1]}
                          options={options}
                          classNamePrefix="select"
                        />
                      </div> */}
                    </div>
                  </div>
                  <div className="candidate-title mb-3 mt-4">
                    <div className="row border-bottom border-3 border-light border-top">
                      <div className="col-md-4 col-12 p-2 border-end border-3 border-light ps-3">
                        <div>
                          <label
                            htmlFor=""
                            className="col-form-label fs-14 fw-600  text-capitalize"
                          >
                            {t('editCandProfile.aboutYou')}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-8 col-12 p-2 pe-3 ps-3 mt-lg-2 mt-md-2">
                        <div>
                          <div className="">
                            {/* <textarea
                              name="aboutJob"
                              onChange={formik.handleChange}
                              value={formik.values.aboutJob}
                              className="form-control key-weight"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              required=""
                            ></textarea> */}

                            <Editor
                              editorState={editorState}
                              editorClassName="editor-height"
                              wrapperClassName='bg-white'
                              stripPastedStyles={true}
                              onEditorStateChange={e => {
                                setEditState(e);
                                formik.setFieldValue("aboutJob", draftToHtml(convertToRaw(e.getCurrentContent())));

                                // setValue({ content: draftToHtml(convertToRaw(e.getCurrentContent())) });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row border-bottom border-3 border-light">
                      <div className="col-md-4 col-12 p-2 border-end border-3 border-light ps-3">
                        <div>
                          <label
                            htmlFor=""
                            className="col-form-label fs-14 fw-600  text-capitalize"
                          >
                            {ct('skills')}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-8 col-12 p-2 pe-3 ps-3 mt-2 SkillRepeater">
                        <div className=" mt-4 mb-4">
                          <FormikProvider value={formik}>
                            <FieldArray
                              name="skills"
                              render={arrayHelpers => (
                                <div>
                                  {formik.values.skills.map((item, index) => (
                                    <div key={'sskkkl__+' + index}>
                                      <div className="d-flex justify-content-between">
                                        <div className="d-block repeat-btns">
                                          <a className="d-block btn delete-btn-color btn-sm rounded-0 text-decoration-none text-white text-nowrap" onClick={() => arrayHelpers.remove(index)}> {ct('delete')} </a>
                                        </div>
                                        <div className="d-flex ps-3">
                                          <div className="">
                                            <div className="mb-2">
                                              <select id="inputState" name={`skills.${index}.skill`} value={item.skill} onChange={formik.handleChange} className="form-select form-control text-muted input-text" required>
                                                <option value=""> {ct('selectYouSkill')} </option>
                                                {
                                                  skills.map((v, index) => (
                                                    <option key={'cr_skl_' + index} value={v.key}>{v.value}</option>
                                                  ))
                                                }
                                              </select>
                                              {formik.errors &&
                                                formik.errors.skills &&
                                                formik.errors.skills[index] &&
                                                formik.errors.skills[index].skill &&
                                                formik.touched &&
                                                formik.touched.skills &&
                                                formik.touched.skills[index] &&
                                                formik.touched.skills[index].skill && <small className="text-danger">{formik.errors.skills[index]?.skill}</small>}

                                            </div>
                                            <div className="">
                                              <textarea rows={3} cols={50} name={`skills.${index}.description`} value={item.description} onChange={formik.handleChange} placeholder={ct('description').toString()} className="form-control mt-3 input-text"></textarea>
                                              {formik.errors &&
                                                formik.errors.skills &&
                                                formik.errors.skills[index] &&
                                                formik.errors.skills[index].description &&
                                                formik.touched &&
                                                formik.touched.skills &&
                                                formik.touched.skills[index] &&
                                                formik.touched.skills[index].description && <small className="text-danger">{formik.errors.skills[index].description}</small>}

                                            </div>
                                            <div className="mt-4 mb-3 skill-progress-color border-bottom border-1">
                                              <RangeSlider
                                                value={item.range}
                                                name={`skills.${index}.range`}
                                                onChange={e => formik.setFieldValue(`skills.${index}.range`, e.target.value)}
                                                tooltipLabel={currentValue => `${currentValue}%`}
                                                min={0}
                                                max={100}
                                                tooltipPlacement='top'
                                                tooltip='on'
                                                type="range"
                                                variant='primary'
                                                bsPrefix='range-slider'
                                              />
                                              {formik.errors &&
                                                formik.errors.skills &&
                                                formik.errors.skills[index] &&
                                                formik.errors.skills[index].range &&
                                                formik.touched &&
                                                formik.touched.skills &&
                                                formik.touched.skills[index] &&
                                                formik.touched.skills[index].range && <small className="text-danger">{formik.errors.skills[index].range}</small>}

                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                  <div className="mb-3 text-end">
                                    <button type="button" onClick={() => formik.values.skills?.length < 5 && arrayHelpers.push({ skill: "", description: "", range: "50" })} className="button-default mt-3 border-0 text-decoration-none"> {ct('addASkill')} </button>
                                  </div>

                                </div>
                              )}
                            />
                          </FormikProvider>


                        </div>
                      </div>
                    </div>
                    <div className="row border-bottom border-3 border-light mt-2 mb-2">
                      <div className="col-md-4 col-12 p-2 border-end border-3 border-light ps-3">
                        <div>
                          <label
                            htmlFor=""
                            className="col-form-label fs-14 fw-600  text-capitalize text-nowrap"
                          >
                            {ct('additionalSkill')}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-8 col-12 p-2 pe-3 ps-3 mt-lg-2 mt-md-2">
                        <div className="add-skills-field">
                          <input
                            className="form-control fsize-13"
                            id="txtAdditionalSkillTitle"
                            type="text"
                            name="additionalSkills"
                            onChange={formik.handleChange}
                            value={formik.values.additionalSkills}
                            placeholder={ct('addSkillSeparatedComma').toString()}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="gap-2 d-md-block mt-3 ps-2">
                      <button
                        type="submit"
                        className="button-default  mt-1 border-0 me-1"
                        onClick={props.confirmSubmit}
                        disabled={props.submitting}>{
                          props.submitting ? <RotatingLines visible width="20" /> : <>{ct('save')}</>
                        }
                      </button>
                      <button className="button-default mt-1 border-0 cancel-btn">
                        {ct('cancel')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MainContent;


